import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { Tooltip, IconButton } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import SpeedometerStat from "../SpeedometerStat";

const SupplierPerformanceBox = ({
  score,
  title,
  tooltipText,
  tooltipKey,
  openTooltips,
  handleTooltipOpen,
  context,
}) => (
  <Grid item xs={12} sm={4}>
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      ml={2}
      mb={3}
    >
      <div className="speedometer">
        <SpeedometerStat value={score} />
      </div>
      <strong style={{ textAlign: "center" }}>
        • {context.t(title)}
        <Tooltip
          title={context.t(tooltipText)}
          open={openTooltips[tooltipKey]}
          onOpen={() => handleTooltipOpen(tooltipKey)}
          placement="right"
        >
          <IconButton
            onMouseEnter={() => handleTooltipOpen(tooltipKey)}
            size="small"
            sx={{ marginLeft: "8px", padding: "0" }}
          >
            <InfoIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </strong>
    </Box>
  </Grid>
);

export default SupplierPerformanceBox;
