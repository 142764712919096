import React from "react";
import PropTypes from "prop-types";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Utils from "../../../services/utils.js";

const CustomToggleButtonGroup = (
  { name, id, value, onChange, complete_color_teal = false },
  context
) => {
  const handleToggleChange = (e, updatedValue) => {
    onChange(updatedValue);
  };

  const colors = complete_color_teal
    ? {
        grey: Utils.colorScheme.NOT_YET,
        yellow: Utils.colorScheme.PROGRESS,
        teal: Utils.colorScheme.COMPLETE,
      }
    : {
        grey: "grey",
        yellow: "yellow",
        teal: "green",
      };

  const matchingValues = ["not yet", "partial progress", "completed"];
  const matchedValue = matchingValues.includes(value);

  const getButtonStyle = (buttonValue) => {
    if (!matchedValue) {
      return {};
    }

    if (!complete_color_teal) {
      return {
        backgroundColor: "",
        color: "black",
      };
    }

    const currentMatch = buttonValue === value;
    const colorIndex = currentMatch
      ? Object.keys(colors)[matchingValues.indexOf(buttonValue)]
      : "white";
    const backColor = colors[colorIndex];

    const styles = {
      backgroundColor: backColor,
    };

    if (complete_color_teal) {
      styles["padding"] = "4px 8px";
      styles["fontSize"] = "11px";
    }

    if (!currentMatch) return styles;

    const textColor =
      currentMatch && value === matchingValues[1] ? "black" : "white";

    styles["color"] = textColor;

    return styles;
  };

  return (
    <ToggleButtonGroup
      id={id}
      name={name}
      color="primary"
      value={value}
      exclusive
      onChange={handleToggleChange}
      style={{ height: matchedValue ? "unset" : "30px" }}
    >
      <ToggleButton
        value={matchingValues[0]}
        style={getButtonStyle(matchingValues[0])}
      >
        <strong>{context.t("Not yet initiated")}</strong>
      </ToggleButton>
      <ToggleButton
        value={matchingValues[1]}
        style={getButtonStyle(matchingValues[1])}
      >
        <strong>{context.t("Partial Progress")}</strong>
      </ToggleButton>
      <ToggleButton
        value={matchingValues[2]}
        style={getButtonStyle(matchingValues[2])}
      >
        <strong>{context.t("Completed / Ongoing")}</strong>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default CustomToggleButtonGroup;

CustomToggleButtonGroup.contextTypes = {
  t: PropTypes.func.isRequired,
};
