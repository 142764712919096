// 1 of 5. SP Progress Intro Form
import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import "./css/style1.css";
import moment from "moment";
import Container from "@material-ui/core/Container";
import { createWorkPlan } from "redux/actions/SpWorkplanFormAction.js";
import { createWorkPlanDocOne } from "redux/actions/SpWorkplanFormAction.js";
import { createWorkPlanDocTwo } from "redux/actions/SpWorkplanFormAction.js";
import { createWorkPlanDocThree } from "redux/actions/SpWorkplanFormAction.js";
import { createWorkPlanDocFour } from "redux/actions/SpWorkplanFormAction.js";
import { createWorkPlanDocFive } from "redux/actions/SpWorkplanFormAction.js";
import { createWorkPlanDocSix } from "redux/actions/SpWorkplanFormAction.js";
import { createWorkPlanDocSeven } from "redux/actions/SpWorkplanFormAction.js";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { ToastContainer } from "react-toastify";
import TextField from "@material-ui/core/TextField";
import InfoIcon from "@material-ui/icons/Info";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import loginStore from "redux/stores/LoginStore";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import HttpService from "../../../services/HttpService";
import Datetime from "react-datetime";
import swal from "@sweetalert/with-react";
import LinearProgress from "@material-ui/core/LinearProgress";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { refreshWorkPlanData } from "redux/actions/SpWorkplanFormAction";

let name = "";
let gl_sdate = "";
let gl_dontShow = false;

const BottomBtn = {
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: "20px",
};

export function DatePicker(props) {
  const [selectedDate, setSelectedDate] = useState(
    props.savedSuggestDate
      ? props.savedSuggestDate
      : new Date().toLocaleDateString()
  );
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  let suggest_date = convert(selectedDate);

  useEffect(() => {
    gl_sdate = suggest_date;
  });
  // disable past dates
  const yesterday = moment().subtract(1, "day");
  const disablePastDt = (current) => {
    return current.isAfter(yesterday);
  };
  return (
    <Datetime
      timeFormat={false}
      isValidDate={disablePastDt}
      value={selectedDate}
      onChange={handleDateChange}
      inputProps={{
        placeholder: "Click here to open calendar",
        readOnly: true,
      }}
      closeOnSelect={true}
    />
  );
}

function SPProgressIntroForm(props, context) {
  const dispatch = useDispatch();
  const current_date = new Date().toLocaleDateString();
  const [fpname, setFpName] = useState("");
  const classes = props.useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [workplanID, setWorkplanID] = useState();
  const [title, setTitle] = useState([]);

  const fileInputOne = useRef(null);
  const fileInputTwo = useRef(null);
  const fileInputThree = useRef(null);
  const fileInputFour = useRef(null);
  const fileInputFive = useRef(null);
  const fileInputSix = useRef(null);
  const fileInputSeven = useRef(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  function fpNameHandler(e) {
    setFpName(e.target.value);
    document.getElementById("outlined-basic").style.border = "none";
    document.getElementById("error1").style.visibility = "hidden";
  }

  const workplanresponse = useSelector((state) => state.workplanReducer.items);
  const workplanedocone = useSelector(
    (state) => state.workplanDocReducer.items
  );
  const workplanedoctwo = useSelector(
    (state) => state.workplanDocTwoReducer.items
  );
  const workplanedocthree = useSelector(
    (state) => state.workplanDocThreeReducer.items
  );
  const workplanedocfour = useSelector(
    (state) => state.workplanDocFourReducer.items
  );
  const workplanedocfive = useSelector(
    (state) => state.workplanDocFiveReducer.items
  );
  const workplanedocsix = useSelector(
    (state) => state.workplanDocSixReducer.items
  );
  const workplanedocseven = useSelector(
    (state) => state.workplanDocSevenReducer.items
  );

  const [spworkplan, setSPWorkplan] = useState([]);
  const [strategicPartner, setStrategicPartner] = useState();
  const [workplanedoconedata, setWorkplaneDocOneData] = useState([]);
  const [workplanedoctwodata, setWorkplaneDoctwoData] = useState([]);
  const [workplanedocthreedata, setWorkplaneDocthreeData] = useState([]);
  const [workplanedocfourdata, setWorkplaneDocfourData] = useState([]);
  const [workplanedocfivedata, setWorkplaneDocFiveData] = useState([]);
  const [workplanedocsixdata, setWorkplaneDocsixData] = useState([]);
  const [workplanedocsevendata, setWorkplaneDocsevenData] = useState([]);
  const [workplaneAllDocs, setWorkplaneAllDocs] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [fatchdata, setFatchdata] = useState([]);
  const [savedSuggestDate, setSavedSuggestDate] = useState(new Date());
  let _user = loginStore.getLoginUser();

  const callApi = () => {
    setStrategicPartner(_user.partner);

    HttpService.get(
      `${process.env.REACT_APP_API_URL}/sp_work_plan/`,
      (response) => {
        setSavedSuggestDate(response.suggested_date);
        setFatchdata(response);
        setStrategicPartner(response.strategic_partner);
        setWorkplanID(response.id);
        if (response.id || localStorage.getItem("workplan_id")) {
          localStorage.setItem("strategicPartner", strategicPartner);
        }
        setFpName(response.fp_name);
        setTitle(response.fp_title);
        setWorkplaneAllDocs(response.documents);
        setSPWorkplan(response);
      },
      (err) => {
        console.log(err.message);
      }
    );
  };

  const workplanresponseLoading = useSelector(
    (state) => state.isLoadingReducer.isLoading
  );

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (workplanresponseLoading === true) {
        setLoading(true);
      } else {
        setLoading(false);
      }
      if (workplanresponseLoading === true) {
        props.next();
      }
      callApi();
    }

    return () => {
      isMounted = false;
    };
  }, [
    workplanedocone,
    workplanedoctwo,
    workplanedocthree,
    workplanedocfour,
    workplanedocfive,
    workplanedocsix,
    workplanedocseven,
    workplanresponseLoading,
    Loading,
  ]);
  const handleSave = async () => {
    callApi();
    if (fpname == "") {
      document.getElementById("error1").style.visibility = "visible";
    } else {
      document.getElementById("outlined-basic").style.border = "none";
      document.getElementById("error1").style.visibility = "hidden";
      dispatch(
        createWorkPlan({
          strategic_partner: _user.partner,
          fp_name: fpname,
          fp_title: "mr",
          suggested_date: gl_sdate,
        })
      );
      await callApi();
    }
    dispatch(refreshWorkPlanData());
  };
  // PDF file upload event
  const setPdfHandlerSCinfo = (e) => {
    if (workplanID == undefined) {
      setWorkplanID(workplanresponse.id);
    } else {
      dispatch(
        createWorkPlanDocOne({
          workplan: workplanID,
          document_type: "supply_chain",
          document: e.target.files[0],
        })
      );
    }
  };
  const setPdfHandlerPolicyHR = (e) => {
    if (workplanID == undefined) {
      setWorkplanID(workplanresponse.id);
    } else {
      dispatch(
        createWorkPlanDocTwo({
          workplan: workplanID,
          document_type: "human_rights",
          document: e.target.files[0],
        })
      );
    }
  };
  const setPdfHandlerPolicyMW = (e) => {
    if (workplanID == undefined) {
      setWorkplanID(workplanresponse.id);
    } else {
      dispatch(
        createWorkPlanDocThree({
          workplan: workplanID,
          document_type: "migrant_worker_policy",
          document: e.target.files[0],
        })
      );
    }
  };
  const setPdfHandlerPolicyER = (e) => {
    if (workplanID == undefined) {
      setWorkplanID(workplanresponse.id);
    } else {
      dispatch(
        createWorkPlanDocFour({
          workplan: workplanID,
          document_type: "ethical_recruitment",
          document: e.target.files[0],
        })
      );
    }
  };
  const setPdfHandlerEProtocol = (e) => {
    if (workplanID == undefined) {
      setWorkplanID(workplanresponse.id);
    } else {
      dispatch(
        createWorkPlanDocFive({
          workplan: workplanID,
          document_type: "worker_voice",
          document: e.target.files[0],
        })
      );
    }
  };
  const setPdfHandlerAuditReports = (e) => {
    if (workplanID == undefined) {
      setWorkplanID(workplanresponse.id);
    } else {
      dispatch(
        createWorkPlanDocSix({
          workplan: workplanID,
          document_type: "audit_reports",
          document: e.target.files[0],
        })
      );
    }
  };
  const setPdfHandlerOtherKey = (e) => {
    if (workplanID == undefined) {
      setWorkplanID(workplanresponse.id);
    } else {
      dispatch(
        createWorkPlanDocSeven({
          workplan: workplanID,
          document_type: "others",
          document: e.target.files[0],
        })
      );
    }
  };
  const reviewFile = (primaryDoc, secondaryDoc) => {
    const filePath = primaryDoc?.length
      ? primaryDoc.document?.file_path
      : secondaryDoc?.document?.file_path;

    if (filePath) {
      window.open(filePath);
    } else {
      console.error("File path is unavailable or invalid.");
    }
  };

  const reviewFileOne = () => reviewFile(workplanedocone, workplanedoconedata);
  const reviewFileTwo = () => reviewFile(workplanedoctwo, workplanedoctwodata);
  const reviewFileThree = () =>
    reviewFile(workplanedocthree, workplanedocthreedata);
  const reviewFileFour = () =>
    reviewFile(workplanedocfour, workplanedocfourdata);
  const reviewFileFive = () =>
    reviewFile(workplanedocfive, workplanedocfivedata);
  const reviewFileSix = () => reviewFile(workplanedocsix, workplanedocsixdata);
  const reviewFileSeven = () =>
    reviewFile(workplanedocseven, workplanedocsevendata);

  const delDocOne = () => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (workplanedoconedata.document) {
          HttpService.delete(
            `${process.env.REACT_APP_API_URL}/sp-workplan-docs/${workplanedoconedata.id}/`,
            (response) => {
              swal({
                text: "Document removed successfully!",
                icon: "success",
                buttons: false,
                timer: 2000,
              });
              for (const key in workplanedoconedata) {
                delete workplanedoconedata[key];
              }
              if (fileInputOne.current) {
                fileInputOne.current.value = "";
              }
              callApi();
            },
            (err) => {
              console.log(err.message);
            }
          );
        }
      }
    });
  };
  const delDocTwo = () => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (workplanedoctwodata.document) {
          HttpService.delete(
            `${process.env.REACT_APP_API_URL}/sp-workplan-docs/${workplanedoctwodata.id}/`,
            (response) => {
              swal({
                text: "Document removed successfully!",
                icon: "success",
                buttons: false,
                timer: 2000,
              });
              for (const key in workplanedoctwodata) {
                delete workplanedoctwodata[key];
              }
              if (fileInputTwo.current) {
                fileInputTwo.current.value = "";
              }
              callApi();
            },
            (err) => {
              console.log(err.message);
            }
          );
        }
      }
    });
  };
  const delDocThree = () => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (workplanedocthreedata.document) {
          HttpService.delete(
            `${process.env.REACT_APP_API_URL}/sp-workplan-docs/${workplanedocthreedata.id}/`,
            (response) => {
              swal({
                text: "Document removed successfully!",
                icon: "success",
                buttons: false,
                timer: 2000,
              });
              for (const key in workplanedocthreedata) {
                delete workplanedocthreedata[key];
              }
              if (fileInputThree.current) {
                fileInputThree.current.value = "";
              }
              callApi();
            },
            (err) => {
              console.log(err.message);
            }
          );
        }
      }
    });
  };
  const delDocFour = () => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (workplanedocfourdata.document) {
          HttpService.delete(
            `${process.env.REACT_APP_API_URL}/sp-workplan-docs/${workplanedocfourdata.id}/`,
            (response) => {
              swal({
                text: "Document removed successfully!",
                icon: "success",
                buttons: false,
                timer: 2000,
              });
              for (const key in workplanedocfourdata) {
                delete workplanedocfourdata[key];
              }
              if (fileInputFour.current) {
                fileInputFour.current.value = "";
              }
              callApi();
            },
            (err) => {
              console.log(err.message);
            }
          );
        }
      }
    });
  };
  const delDocFive = () => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (workplanedocfivedata.document) {
          HttpService.delete(
            `${process.env.REACT_APP_API_URL}/sp-workplan-docs/${workplanedocfivedata.id}/`,
            (response) => {
              swal({
                text: "Document removed successfully!",
                icon: "success",
                buttons: false,
                timer: 2000,
              });
              for (const key in workplanedocfivedata) {
                delete workplanedocfivedata[key];
              }
              if (fileInputFive.current) {
                fileInputFive.current.value = "";
              }
              callApi();
            },
            (err) => {
              console.log(err.message);
            }
          );
        }
      }
    });
  };
  const delDocSix = () => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (workplanedocsixdata.document) {
          HttpService.delete(
            `${process.env.REACT_APP_API_URL}/sp-workplan-docs/${workplanedocsixdata.id}/`,
            (response) => {
              swal({
                text: "Document removed successfully!",
                icon: "success",
                buttons: false,
                timer: 2000,
              });
              for (const key in workplanedocsixdata) {
                delete workplanedocsixdata[key];
              }
              if (fileInputSix.current) {
                fileInputSix.current.value = "";
              }
              callApi();
            },
            (err) => {
              console.log(err.message);
            }
          );
        }
      }
    });
  };
  const delDocSeven = () => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (workplanedocsevendata.document) {
          HttpService.delete(
            `${process.env.REACT_APP_API_URL}/sp-workplan-docs/${workplanedocsevendata.id}/`,
            (response) => {
              swal({
                text: "Document removed successfully!",
                icon: "success",
                buttons: false,
                timer: 2000,
              });
              for (const key in workplanedocsevendata) {
                delete workplanedocsevendata[key];
              }
              if (fileInputSeven.current) {
                fileInputSeven.current.value = "";
              }
              callApi();
            },
            (err) => {
              console.log(err.message);
            }
          );
        }
      }
    });
  };

  const [dontShow, setDontShow] = useState(gl_dontShow);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      gl_dontShow = dontShow;
      spworkplan.suggested_date = gl_sdate;
    }

    return () => {
      isMounted = false;
    };
  }, [dontShow, gl_sdate]);

  const NextHandler = () => {
    if (gl_dontShow == false) {
      if (
        spworkplan.fp_name !== fpname ||
        spworkplan.suggested_date !== gl_sdate
      ) {
        swal({
          text: "Changes have not been saved.",
          icon: "warning",
          buttons: true,
          dangerMode: true,
          buttons: {
            close: "Cancel",
            Proceed: "Proceed Anyway",
            save: "Save",
          },
          closeOnClickOutside: false,
          content: (
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    setDontShow(e.target.checked);
                  }}
                  color="black"
                />
              }
              label={
                <span style={{ fontSize: "14px" }}>
                  Do not show this again.
                </span>
              }
            />
          ),
        }).then((willSave) => {
          switch (willSave) {
            case "Proceed":
              props.next();
              break;
            case "save":
              handleSave();
              break;
          }
        });
      } else {
        props.next();
      }
    } else {
      props.next();
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      name = fpname;
      if (
        workplaneAllDocs &&
        Array.isArray(workplaneAllDocs) &&
        workplaneAllDocs.length > 0
      ) {
        workplaneAllDocs.forEach((workplanDoc) => {
          if (workplanDoc.document_type === "supply_chain") {
            setWorkplaneDocOneData(workplanDoc);
          }
          if (workplanDoc.document_type === "human_rights") {
            setWorkplaneDoctwoData(workplanDoc);
          }
          if (workplanDoc.document_type === "migrant_worker_policy") {
            setWorkplaneDocthreeData(workplanDoc);
          }
          if (workplanDoc.document_type === "ethical_recruitment") {
            setWorkplaneDocfourData(workplanDoc);
          }
          if (workplanDoc.document_type === "worker_voice") {
            setWorkplaneDocFiveData(workplanDoc);
          }
          if (workplanDoc.document_type === "audit_reports") {
            setWorkplaneDocsixData(workplanDoc);
          }
          if (workplanDoc.document_type === "others") {
            setWorkplaneDocsevenData(workplanDoc);
          }
        });
      }
    }

    return () => {
      isMounted = false;
    };
  }, [
    strategicPartner,
    delDocOne,
    delDocTwo,
    delDocThree,
    delDocFour,
    delDocFive,
    delDocSix,
    delDocSeven,
  ]);

  return (
    <Container>
      {fatchdata.length === 0 ? (
        <LinearProgress />
      ) : (
        <div className="workplan-box">
          <h3>1 of 5. Intro</h3>
          <p>
            This annual workplanning form aims to help your company identify and
            maintain focus on key business priorities with regard to ethical
            sourcing and ethical recruitment across your supply chain. The
            target progress indicators that you indicate here will display on
            your SP Progress Metrics Dashboard. At any time, you can visit the
            SP Progress Metrics to see your progress to date against each target
            as well as update this Workplanning Form if priorities change.
            <br />
            <br />
            The SP Workplanning has 4 sections: 1. Intro, 2. Main Goals and
            Priority Partnership Areas, 3. Progress Indicators, and 4. Priority
            Workplan Activities.
          </p>
          <br /> <br />
          {/* Toast alert for removing document */}
          <ToastContainer
            position="bottom-right"
            autoClose={2000}
            hideProgressBar={true}
            icon={true}
          />
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              className="first-input-box"
            >
              <form noValidate autoComplete="off">
                <TextField
                  id="outlined-basic"
                  label="Name of SP focal point"
                  variant="outlined"
                  value={fpname}
                  onChange={fpNameHandler}
                />
                <p
                  id="error1"
                  style={{
                    visibility: "hidden",
                    color: "red",
                    margin: "0 0 -2px 0",
                  }}
                >
                  {context.t("Please enter FP name")}
                </p>
              </form>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <form id="current-date" noValidate autoComplete="off">
                <TextField
                  disabled
                  id="filled-disabled"
                  label="Today's date"
                  defaultValue={current_date}
                  variant="filled"
                />
              </form>
            </Grid>
          </Grid>
          <Grid container style={{ margin: "0 0 7px 0" }}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <span style={{ fontSize: "11px" }} className={classes.container}>
                Suggested date for mid-year check-in
              </span>
              <DatePicker savedSuggestDate={savedSuggestDate} />
            </Grid>
          </Grid>
          <br /> <br />
          <p>
            Please check that all foundational documents and materials that have
            been shared with Issara. If not, you can upload them here .
          </p>
          <Grid style={{ marginBottom: "16px" }}>
            <div>
              <label htmlFor="SC-information">
                <input
                  ref={fileInputOne}
                  accept="application/pdf"
                  className={classes.input}
                  id="SC-information"
                  type="file"
                  onChange={setPdfHandlerSCinfo}
                  style={{ display: "none" }}
                />
                <Button
                  variant="contained"
                  component="span"
                  style={{ marginRight: "16px" }}
                >
                  {workplanedoconedata.document
                    ? context.t("Change")
                    : context.t("Upload")}
                </Button>
                <Button
                  onClick={reviewFileOne}
                  variant="contained"
                  style={{ marginRight: "16px" }}
                >
                  {context.t("Review")}
                </Button>
              </label>
              <span>{context.t("Updated supply chain information")}</span>
              <InfoIcon
                className="info-icon"
                aria-owns={open ? "mouse-over-popover" : undefined}
                style={{ marginBottom: "-5px" }}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              />
              <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                  paper: classes.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Typography bgcolor="text.secondary">
                  <ul style={{ paddingRight: "15px" }}>
                    Helpful information to include:
                    <li>Supplier name</li>
                    <li>Supplier address</li>
                    <li>Product sourced</li>
                    <li>
                      Supplier key focal point name, title, and contact
                      information
                    </li>
                    <li>Length of sourcing relationship</li>
                    <li>
                      Number and breakdown of workers by nationality and gender
                    </li>
                  </ul>
                </Typography>
              </Popover>
              &nbsp;&nbsp;&nbsp;
              <span
                style={{
                  color: "#148696",
                  cursor: "pointer",
                  cursor: "pointer",
                }}
                onClick={reviewFileOne}
              >
                {workplanedoconedata.document
                  ? " // " + workplanedoconedata.document.name
                  : ""}
              </span>
              &nbsp;&nbsp;&nbsp;
              <DeleteForeverIcon
                style={{ marginBottom: "-5px" }}
                onClick={delDocOne}
                className={
                  workplanedoconedata.document
                    ? "trash-btn-show"
                    : "trash-btn-hide"
                }
              />
            </div>
          </Grid>
          <Grid style={{ marginBottom: "16px" }}>
            <label htmlFor="company-policy-human-rights">
              <input
                ref={fileInputTwo}
                accept="application/pdf"
                className={classes.input}
                id="company-policy-human-rights"
                type="file"
                onChange={setPdfHandlerPolicyHR}
                style={{ display: "none" }}
              />
              <Button
                variant="contained"
                component="span"
                style={{ marginRight: "16px" }}
              >
                {workplanedoctwodata.document
                  ? context.t("Change")
                  : context.t("Upload")}
              </Button>
              <Button
                onClick={reviewFileTwo}
                variant="contained"
                style={{ marginRight: "16px" }}
              >
                Review
              </Button>
            </label>
            <span>Company policy: human rights</span>
            &nbsp;&nbsp;&nbsp;
            <span
              style={{ color: "#148696", cursor: "pointer" }}
              onClick={reviewFileTwo}
            >
              {workplanedoctwodata.document
                ? " //  " + workplanedoctwodata.document.name
                : ""}
            </span>
            &nbsp;&nbsp;&nbsp;
            <DeleteForeverIcon
              style={{ marginBottom: "-5px" }}
              onClick={delDocTwo}
              className={
                workplanedoctwodata.document
                  ? "trash-btn-show"
                  : "trash-btn-hide"
              }
            />
          </Grid>
          <Grid style={{ marginBottom: "16px" }}>
            <label htmlFor="company-policy-migrant-worker">
              <input
                ref={fileInputThree}
                accept="application/pdf"
                className={classes.input}
                id="company-policy-migrant-worker"
                type="file"
                onChange={setPdfHandlerPolicyMW}
                style={{ display: "none" }}
              />
              <Button
                variant="contained"
                component="span"
                style={{ marginRight: "16px" }}
              >
                {workplanedocthreedata.document
                  ? context.t("Change")
                  : context.t("Upload")}
              </Button>
              <Button
                onClick={reviewFileThree}
                variant="contained"
                style={{ marginRight: "16px" }}
              >
                Review
              </Button>
            </label>
            <span>Company policy: migrant worker</span>
            &nbsp;&nbsp;&nbsp;
            <span
              style={{ color: "#148696", cursor: "pointer" }}
              onClick={reviewFileThree}
            >
              {workplanedocthreedata.document
                ? " // " + workplanedocthreedata.document.name
                : ""}
            </span>
            &nbsp;&nbsp;&nbsp;
            <DeleteForeverIcon
              style={{ marginBottom: "-5px" }}
              onClick={delDocThree}
              className={
                workplanedocthreedata.document
                  ? "trash-btn-show"
                  : "trash-btn-hide"
              }
            />
          </Grid>
          <Grid style={{ marginBottom: "16px" }}>
            <label htmlFor="company-policy-ethical-recruitment">
              <input
                ref={fileInputFour}
                accept="application/pdf"
                className={classes.input}
                id="company-policy-ethical-recruitment"
                type="file"
                onChange={setPdfHandlerPolicyER}
                style={{ display: "none" }}
              />
              <Button
                variant="contained"
                component="span"
                style={{ marginRight: "16px" }}
              >
                {workplanedocfourdata.document
                  ? context.t("Change")
                  : context.t("Upload")}
              </Button>
              <Button
                onClick={reviewFileFour}
                variant="contained"
                style={{ marginRight: "16px" }}
              >
                Review
              </Button>
            </label>
            <span>Company policy: ethical recruitment</span>
            &nbsp;&nbsp;&nbsp;
            <span
              style={{ color: "#148696", cursor: "pointer" }}
              onClick={reviewFileFour}
            >
              {workplanedocfourdata.document
                ? " // " + workplanedocfourdata.document.name
                : ""}
            </span>
            &nbsp;&nbsp;&nbsp;
            <DeleteForeverIcon
              style={{ marginBottom: "-5px" }}
              onClick={delDocFour}
              className={
                workplanedocfourdata.document
                  ? "trash-btn-show"
                  : "trash-btn-hide"
              }
            />
          </Grid>
          <Grid style={{ marginBottom: "16px" }}>
            <label htmlFor="escalation-protocol">
              <input
                ref={fileInputFive}
                accept="application/pdf"
                className={classes.input}
                id="escalation-protocol"
                type="file"
                onChange={setPdfHandlerEProtocol}
                style={{ display: "none" }}
              />
              <Button
                variant="contained"
                component="span"
                style={{ marginRight: "16px" }}
              >
                {workplanedocfivedata.document
                  ? context.t("Change")
                  : context.t("Upload")}
              </Button>
              <Button
                onClick={reviewFileFive}
                variant="contained"
                style={{ marginRight: "16px" }}
              >
                {context.t("Review")}
              </Button>
            </label>
            <span>
              {context.t(
                "Escalation protocol for worker-reported issues and worker voice"
              )}
            </span>
            &nbsp;&nbsp;&nbsp;
            <span
              style={{ color: "#148696", cursor: "pointer" }}
              onClick={reviewFileFive}
            >
              {workplanedocfivedata.document
                ? " // " + workplanedocfivedata.document.name
                : ""}
            </span>
            &nbsp;&nbsp;&nbsp;
            <DeleteForeverIcon
              style={{ marginBottom: "-5px" }}
              onClick={delDocFive}
              className={
                workplanedocfivedata.document
                  ? "trash-btn-show"
                  : "trash-btn-hide"
              }
            />
          </Grid>
          <Grid style={{ marginBottom: "16px" }}>
            <label htmlFor="audit-reports">
              <input
                ref={fileInputSix}
                accept="application/pdf"
                className={classes.input}
                id="audit-reports"
                type="file"
                onChange={setPdfHandlerAuditReports}
                style={{ display: "none" }}
              />
              <Button
                variant="contained"
                component="span"
                style={{ marginRight: "16px" }}
              >
                {workplanedocsixdata.document
                  ? context.t("Change")
                  : context.t("Upload")}
              </Button>
              <Button
                onClick={reviewFileSix}
                variant="contained"
                style={{ marginRight: "16px" }}
              >
                {context.t("Review")}
              </Button>
            </label>
            <span>{context.t("Audit reports for suppliers")}</span>
            &nbsp;&nbsp;&nbsp;
            <span
              style={{ color: "#148696", cursor: "pointer" }}
              onClick={reviewFileSix}
            >
              {workplanedocsixdata.document
                ? " // " + workplanedocsixdata.document.name
                : ""}
            </span>
            &nbsp;&nbsp;&nbsp;
            <DeleteForeverIcon
              style={{ marginBottom: "-5px" }}
              onClick={delDocSix}
              className={
                workplanedocsixdata.document
                  ? "trash-btn-show"
                  : "trash-btn-hide"
              }
            />
          </Grid>
          <Grid style={{ marginBottom: "16px" }}>
            <label htmlFor="other-key-policy">
              <input
                ref={fileInputSeven}
                accept="application/pdf"
                className={classes.input}
                id="other-key-policy"
                type="file"
                onChange={setPdfHandlerOtherKey}
                style={{ display: "none" }}
              />
              <Button
                variant="contained"
                component="span"
                style={{ marginRight: "16px" }}
              >
                {workplanedocsevendata.document
                  ? context.t("Change")
                  : context.t("Upload")}
              </Button>
              <Button
                onClick={reviewFileSeven}
                variant="contained"
                style={{ marginRight: "16px" }}
              >
                {context.t("Review")}
              </Button>
            </label>
            <span>{context.t("Other key policy or background documents")}</span>
            &nbsp;&nbsp;&nbsp;
            <span
              style={{ color: "#148696", cursor: "pointer" }}
              onClick={reviewFileSeven}
            >
              {workplanedocsevendata.document
                ? " // " + workplanedocsevendata.document.name
                : ""}
            </span>
            &nbsp;&nbsp;&nbsp;
            <DeleteForeverIcon
              style={{ marginBottom: "-5px" }}
              onClick={delDocSeven}
              className={
                workplanedocsevendata.document
                  ? "trash-btn-show"
                  : "trash-btn-hide"
              }
            />
          </Grid>
          <Grid component="span" style={BottomBtn}>
            <Button
              onClick={handleSave}
              variant="contained"
              style={{ marginRight: "15px", backgroundColor: "#03A9F4" }}
            >
              {context.t("Save")}
            </Button>
            <Button
              onClick={NextHandler}
              variant="contained"
              width="200px"
              style={{ marginRight: "15px" }}
            >
              {context.t("Next")}
            </Button>
          </Grid>
        </div>
      )}
    </Container>
  );
}

export default SPProgressIntroForm;

SPProgressIntroForm.contextTypes = {
  t: PropTypes.func.isRequired,
};
