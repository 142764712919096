import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";

// core components
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger";
import Tooltip from "@material-ui/core/Tooltip";

import { CircularProgress, Grid } from "@material-ui/core";
import Datetime from "react-datetime";
import PropTypes from "prop-types";

import FieldworkTypeDropdown from "../../components/ilmdb/FieldworkTypeDropdown.js";
import SuppliersDropdown from "components/ilmdb/SuppliersDropdown.js";
import RecruitersDropdown from "components/ilmdb/RecruitersDropdown.js";
import CountryProvincesDistrictsDropdown from "../../components/ilmdb/CountryProvincesDistrictsDropdown.js";

import { fetchFieldworkTypes } from "../../redux/actions/TeamActivityActions";
import OutreachFields from "./ActivityExtraFields/OutreachFields";
import OtherFields from "./ActivityExtraFields/OtherFields";

import { fetchSuppliers } from "redux/actions/SupplierActions.js";
import {
  fetchFieldworkActivitiy,
  createFieldworkActivity,
  updateFieldworkActivity,
} from "../../redux/actions/TeamActivityActions.js";

import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import utils from "../../services/utils.js";

import loginStore from "../../redux/stores/LoginStore";
import { getMomentLocale } from "languages/i18n/utilities/utilities.js";

const customStyles = {
  ...sweetAlertStyles,
  label: {
    color: "black",
    fontSize: "16px",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 400,
    lineHeight: 1.42857,
  },
};

const useStyles = makeStyles(customStyles);

export default function FieldworkActivityForm(props, context) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const id = props.location
    ? new URLSearchParams(props.location.search).get("id")
    : props.supplierId
    ? props.supplierId
    : null; // id from query string of edited strategic partner

  const fieldworkActivities = useSelector(
    (state) => state.teamActivityReducer.fieldworkActivities
  );

  const lang = useSelector((state) => state.i18nState.lang);
  // const fetchingFieldworkActivities = useSelector(
  //   (state) => state.teamActivityReducer.fetchingFieldworkActivities
  // );

  const fieldworkTypes = useSelector(
    (state) => state.teamActivityReducer.fieldworkTypes
  );
  // const fetchingFieldworkTypes = useSelector(
  //   (state) => state.teamActivityReducer.fetchingFieldworkTypes
  // );

  const suppliers = useSelector((state) => state.suppliersReducer.items);
  const IssaraAcademyTraining = "Issara Academy training (in-person or online)";

  /*********************************************/
  /***************** START STATE ***************/
  /*********************************************/
  const [payload, setPayload] = useState({
    fieldwork_date: new Date(),
    links: [""],
  });
  const [outreachPayload, setOutreachPayload] = useState({
    total_workers_reached: 0,
    female_workers_reached: 0,
    male_workers_reached: 0,
    myanmar_workers_reached: 0,
    cambodian_workers_reached: 0,
    thai_workers_reached: 0,
    lao_workers_reached: 0,
    nepalese_workers_reached: 0,
    bangladeshi_workers_reached: 0,
    indonesian_workers_reached: 0,
    malaysian_workers_reached: 0,
    indian_workers_reached: 0,
    other_workers_reached: 0,
  });
  const [otherPayload, setOtherPayload] = useState({
    strategic_partners: [],
    total_people_reached: 0,
    male_reached: 0,
    female_reached: 0,
    primary_focus: null,
    primary_focus_other_description: null,
  });
  const [submitted, setSubmitted] = useState(false);

  // Not related to payload
  const [alert, setAlert] = useState(null);

  /*********************************************/
  /****************** END STATE ****************/
  /*********************************************/

  const sumOfNationalities =
    outreachPayload.myanmar_workers_reached +
    outreachPayload.cambodian_workers_reached +
    outreachPayload.thai_workers_reached +
    outreachPayload.lao_workers_reached +
    outreachPayload.nepalese_workers_reached +
    outreachPayload.bangladeshi_workers_reached +
    outreachPayload.indonesian_workers_reached +
    outreachPayload.malaysian_workers_reached +
    outreachPayload.indian_workers_reached +
    outreachPayload.other_workers_reached;

  const handleConfirmSuccessAlert = () => {
    props.history.push("/admin/issara-dashboard");
  };

  const successAlert = () => {
    setAlert(
      <SweetAlert
        success
        onConfirm={handleConfirmSuccessAlert}
        confirmBtnCssClass={classes.button + " " + classes.success}
        title={context.t("Well done!")}
      >
        {context.t("New fieldwork activity has been successfully created")}
      </SweetAlert>
    );
  };

  const errorAlert = (error) => {
    setAlert(
      <SweetAlert
        danger
        onConfirm={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        title={context.t("Error")}
      >
        {error.message}
      </SweetAlert>
    );
  };

  const validated = () => {
    if (!payload.fieldwork_date) {
      return false;
    }

    if (payload.fieldWorkType) {
      if (payload.fieldWorkType.category == 1) {
        if (
          outreachPayload.total_workers_reached <
            outreachPayload.female_workers_reached ||
          outreachPayload.total_workers_reached <
            outreachPayload.male_workers_reached ||
          outreachPayload.total_workers_reached != sumOfNationalities
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        if (
          otherPayload.total_people_reached < otherPayload.female_reached ||
          otherPayload.total_people_reached < otherPayload.male_reached
        ) {
          return false;
        } else {
          return true;
        }
      }
    }
    return false;
  };

  const onSubmit = () => {
    setSubmitted(true);
    if (validated()) {
      const extraPayload =
        payload.fieldWorkType.category == 1 ? outreachPayload : otherPayload;
      payload.fieldwork_date = utils.turnDateIntoString(payload.fieldwork_date);
      payload.fieldwork_type = payload.fieldWorkType.id;
      payload.links =
        payload.links.length > 1
          ? payload.links.join(",")
          : payload.links.toString();

      if (!id) {
        dispatch(
          createFieldworkActivity(
            { ...payload, ...extraPayload },
            successAlert,
            errorAlert
          )
        );
      } else {
        dispatch(
          updateFieldworkActivity(
            id,
            { ...payload, ...extraPayload },
            successAlert,
            errorAlert
          )
        );
      }
    }
  };

  const addLink = () => {
    let copiedPayload = { ...payload };
    let copiedLinks = [...payload.links];
    copiedLinks.push("");
    copiedPayload.links = copiedLinks;
    setPayload(copiedPayload);
  };

  const removeLink = (e, index) => {
    // this is long but safe or imutable method
    let copiedPayload = { ...payload };
    let copiedLinks = [...payload.links];
    copiedLinks.splice(index, 1);
    copiedPayload.links = copiedLinks;
    setPayload(copiedPayload);
  };

  const extraFields = (category) => {
    if (category === 1) {
      return (
        <OutreachFields
          state={outreachPayload}
          onChange={(values) =>
            setOutreachPayload({ ...outreachPayload, ...values })
          }
        />
      );
    } else if ([2, 3, 4].includes(category)) {
      return (
        <OtherFields
          state={otherPayload}
          onChange={(values) => setOtherPayload({ ...otherPayload, ...values })}
        />
      );
    } else {
      return <p> {context.t("Please contact a developer")} </p>;
    }
  };

  // handl link input change
  const handleLinkInputChange = (e, index) => {
    // updating payoad imutably
    let copiedLinks = [...payload.links];
    let copiedPayload = { ...payload };

    copiedLinks[index] = e.target.value;
    copiedPayload.links = copiedLinks;

    setPayload(copiedPayload);
  };

  useEffect(() => {
    dispatch(fetchFieldworkTypes());

    if (suppliers === null || suppliers === undefined || suppliers.length < 1) {
      dispatch(fetchSuppliers());
    }

    if (
      id !== null &&
      id !== "" &&
      id !== undefined &&
      Object.keys(fieldworkActivities).length < 1
    ) {
      dispatch(fetchFieldworkActivitiy(id));
    }
  }, []);

  useEffect(() => {
    if (
      Object.keys(fieldworkActivities).length > 0 &&
      Object.keys(fieldworkTypes).length > 0 &&
      id !== null &&
      id !== "" &&
      id !== undefined
    ) {
      const fieldworkActivity = fieldworkActivities[id];
      fieldworkActivity.fieldWorkType = (() => {
        let found = null;
        Object.keys(fieldworkTypes).map((key) => {
          let comparedTypes = fieldworkTypes[key];
          comparedTypes.types.forEach((item) => {
            if (item && item.id === fieldworkActivity.fieldwork_type) {
              found = item;
            }
          });
        });
        return found;
      })();
      let thePayload = {
        ...fieldworkActivity,
        fieldwork_date: fieldworkActivity.fieldwork_date
          ? new Date(fieldworkActivity.fieldwork_date)
          : new Date(),
      };
      thePayload.links = thePayload.links.split(",");
      setPayload(thePayload);
      setOutreachPayload({
        total_workers_reached: thePayload.total_workers_reached,
        female_workers_reached: thePayload.female_workers_reached,
        male_workers_reached: thePayload.male_workers_reached,
        myanmar_workers_reached: thePayload.myanmar_workers_reached,
        cambodian_workers_reached: thePayload.cambodian_workers_reached,
        thai_workers_reached: thePayload.thai_workers_reached,
        lao_workers_reached: thePayload.lao_workers_reached,
        nepalese_workers_reached: thePayload.nepalese_workers_reached,
        bangladeshi_workers_reached: thePayload.bangladeshi_workers_reached,
        indonesian_workers_reached: thePayload.indonesian_workers_reached,
        malaysian_workers_reached: thePayload.malaysian_workers_reached,
        indian_workers_reached: thePayload.indian_workers_reached,
        other_workers_reached: thePayload.other_workers_reached,
      });

      setOtherPayload({
        strategic_partners: thePayload.strategic_partners
          ? thePayload.strategic_partners
          : [],
        total_people_reached: thePayload.total_people_reached,
        male_reached: thePayload.male_reached,
        female_reached: thePayload.female_reached,
        primary_focus: thePayload.primary_focus
          ? thePayload.primary_focus
          : null,
        primary_focus_other_description:
          thePayload.primary_focus_other_description,
      });
    }
  }, [fieldworkActivities, fieldworkTypes]);

  const commonForm = (extraFields) => (
    <GridItem xs={12}>
      <div className="mt-1">
        <p className={classes.label}> {context.t("Suppliers")} </p>
        <SuppliersDropdown
          label={context.t("Suppliers")}
          multipleselect={true}
          value={payload.suppliers}
          onSelect={(suppliers) =>
            setPayload({ ...payload, suppliers: suppliers })
          }
        />
      </div>

      <div className="mt-1">
        <p className={classes.label}> {context.t("Recruiters")} </p>
        <RecruitersDropdown
          label={context.t("Recruiters")}
          multipleselect={true}
          value={payload.recruiters}
          onSelect={(recruiters) =>
            setPayload({ ...payload, recruiters: recruiters })
          }
        />
      </div>

      {extraFields}

      <div className="mt-1">
        <p className={classes.label} style={{ marginBottom: "-16px" }}>
          {context.t("Notes")}
        </p>
        <CustomInput
          isTextArea={true}
          formControlProps={{ fullWidth: true }}
          inputProps={{
            onChange: (e) => setPayload({ ...payload, notes: e.target.value }),
          }}
          value={payload.notes}
        />
      </div>

      {payload.links &&
        Array.isArray(payload.links) &&
        payload.links.length > 0 &&
        payload.links.map((link, index) => (
          <div className="mt-1" key={index}>
            <p className={classes.label} style={{ marginBottom: "-16px" }}>
              {context.t("Link")} {payload.links.length > 1 && index + 1}
            </p>
            <CustomInput
              labelText={context.t("Enter link here")}
              isTextArea={false}
              formControlProps={{ fullWidth: true }}
              inputProps={{
                onChange: (e) => handleLinkInputChange(e, index),
              }}
              value={payload.links[index]}
              endicon={<DeleteForeverIcon />}
            >
              {payload.links.length > 1 && (
                <Tooltip
                  title={context.t("Remove this link")}
                  aria-label={context.t("Remove this link")}
                >
                  <IconButton
                    color="primary"
                    onClick={(e) => removeLink(e, index)}
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                </Tooltip>
              )}
            </CustomInput>
          </div>
        ))}
      <Button color="primary" onClick={addLink}>
        {context.t("Add More Link")}
      </Button>
    </GridItem>
  );

  if (
    !suppliers ||
    suppliers.length < 1 ||
    (id !== null &&
      id !== undefined &&
      id !== "" &&
      Object.keys(fieldworkActivities).length < 1) ||
    Object.keys(fieldworkTypes).length < 1
  ) {
    return <CircularProgress />;
  }

  return (
    <GridItem xs={12} sm={12} lg={12}>
      <Card style={{ marginTop: 0 }}>
        {alert}
        <CardBody>
          <GridContainer>
            <GridItem xs={12}>
              <p className={classes.label} style={{ marginBottom: 0 }}>
                {context.t("Date of fieldwork")}
              </p>
              <Datetime
                locale={getMomentLocale(lang)}
                timeFormat={false}
                inputProps={{
                  placeholder: context.t("Click here to open calendar"),
                }}
                onChange={(date) =>
                  setPayload({ ...payload, fieldwork_date: date._d })
                }
                value={payload.fieldwork_date}
                closeOnSelect={true}
              />
              {submitted && !payload.fieldwork_date && (
                <Danger>
                  <p style={{ textAlign: "right" }}>
                    {" "}
                    {context.t("Please choose the date")}{" "}
                  </p>
                </Danger>
              )}
            </GridItem>

            <GridItem xs={12}>
              <CountryProvincesDistrictsDropdown
                key={utils.giveMeGuid()}
                country_values={{ id: payload.country }}
                province_values={{ id: payload.province }}
                onSelect={(data) => {
                  setPayload({
                    ...payload,
                    country: data.country_id,
                    province: data.province_id,
                  });
                }}
                hideDistrict
              />
            </GridItem>

            <GridItem xs={12}>
              <FieldworkTypeDropdown
                value={payload.fieldWorkType}
                onSelect={(fieldworkType) => {
                  console.log(fieldworkType);
                  setPayload({ ...payload, fieldWorkType: fieldworkType });
                }}
              />
            </GridItem>

            {payload.fieldWorkType?.name === IssaraAcademyTraining && (
              <GridItem xs={12}>
                <p className={classes.label} style={{ marginBottom: "-16px" }}>
                  {context.t("Type training name")}
                </p>
                <CustomInput
                  isTextArea={false}
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    onChange: (e) =>
                      setPayload({
                        ...payload,
                        training_name: e.target.value,
                      }),
                  }}
                  value={payload.training_name}
                />
              </GridItem>
            )}

            {payload.fieldWorkType &&
              commonForm(extraFields(payload.fieldWorkType.category))}
          </GridContainer>

          {submitted && !payload.fieldWorkType && (
            <Danger>
              <p> {context.t("Please choose a fieldwork type")} </p>
            </Danger>
          )}

          {submitted && !validated() && (
            <Danger>
              <p style={{ textAlign: "right" }}>
                {context.t("Please fix the errors above")}
              </p>
            </Danger>
          )}

          <GridContainer justify="flex-start">
            <GridItem>
              {(() => {
                let isManagement = loginStore.isIssaraManagement();
                let isActivityUser = loginStore.isActivityUser();
                let isStaff = loginStore.isIssaraStaff();
                let isId = id !== null && id !== undefined && id !== "";
                let isCSO = loginStore.isCSO();
                let isDisabled =
                  (isId && !isManagement) ||
                  (!isId && !isManagement && !isActivityUser && !isStaff);

                return (
                  <div>
                    {isCSO ? (
                      <div>
                        <Button color="success" onClick={onSubmit}>
                          {context.t("Save")}
                        </Button>
                      </div>
                    ) : (
                      <div>
                        {(isManagement === true ||
                          isActivityUser === true ||
                          isStaff === true) && (
                          <h4>
                            {context.t(
                              "*Please Note: To Edit you must have 'Issara Management' Group added to your account and to add new Items you must have 'Activity User' Group added to your account ."
                            )}
                          </h4>
                        )}
                        <Button
                          disabled={isDisabled}
                          color="success"
                          onClick={onSubmit}
                        >
                          {context.t("Save")}
                        </Button>
                      </div>
                    )}
                  </div>
                );
              })()}
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </GridItem>
  );
}

FieldworkActivityForm.contextTypes = {
  t: PropTypes.func.isRequired,
};
