import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { fetchChannels } from "../../redux/actions/CallActions";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import utils from "../../services/utils.js";

const customStyles = {
  ...styles,
};
const useStyles = makeStyles(customStyles);

export default function ChannelsDropdown(props, context) {
  const propsOnSelect = props.onSelect ? props.onSelect : (e) => {};
  const valueEmpty = "";
  const [channelId, setChannelId] = useState(
    props.value ? props.value : valueEmpty
  );
  const channelState = useSelector((state) => state.callChannels);
  const channels = useSelector((state) => state.callChannels.items);
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    !channelState.fetched && dispatch(fetchChannels());
  }, []);

  const handleChannelChange = (e) => {
    setChannelId(e.target.value);
    propsOnSelect({
      ...e,
      list: channels,
    });
  };

  return (
    <FormControl fullWidth className={classes.selectFormControl}>
      <InputLabel htmlFor="channel-select" className={classes.selectLabel}>
        {context.t("Worker Voice Channel")}
      </InputLabel>
      <Select
        key={utils.giveMeGuid()}
        value={channelId}
        onChange={handleChannelChange}
        inputProps={{
          name: "channelSelect",
          id: "channel-select",
        }}
      >
        <MenuItem value="" key={utils.giveMeGuid()} disabled={true}>
          {context.t("Worker Voice Channel")}
        </MenuItem>
        {channels &&
          Object.keys(channels).map((obj, i) => {
            return (
              <MenuItem value={channels[obj].id} key={utils.giveMeGuid()}>
                {channels[obj].name}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
}

ChannelsDropdown.contextTypes = {
  t: PropTypes.func.isRequired,
};
