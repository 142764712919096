// SP Work Plan Main Form
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./css/style1.css";
import { makeStyles } from "@material-ui/core/styles";
import Datetime from "react-datetime";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import LinearProgress from "@material-ui/core/LinearProgress";
import { fetchWorkPlanData } from "redux/actions/SpWorkplanFormAction";
import SPProgressIntroForm from "./SPProgressIntroForm";
import SPMainGoalsForm from "./SPMainGoalsForm";
import SPProgressIndicatorsForm from "./SPProgressIndicatorsForm";
import SPPriorityWorkplanActivitiesForm from "./SPPriorityWorkplanActivitiesForm";
import SPWorkPlanningFormReview from "./SPWorkPlanningFormReview";

let gl_workersInSC = 0;
let gl_tieronesuppliers = 0;
let gl_upstreamsuppliers = 0;
let gl_sdate;

export function DatePicker(props) {
  const [selectedDate, setSelectedDate] = useState(
    props.savedSuggestDate
      ? props.savedSuggestDate
      : new Date().toLocaleDateString()
  );
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  let suggest_date = convert(selectedDate);

  useEffect(() => {
    gl_sdate = suggest_date;
  });
  // disable past dates
  const yesterday = moment().subtract(1, "day");
  const disablePastDt = (current) => {
    return current.isAfter(yesterday);
  };
  return (
    <Datetime
      timeFormat={false}
      isValidDate={disablePastDt}
      value={selectedDate}
      onChange={handleDateChange}
      inputProps={{
        placeholder: "Click here to open calendar",
        readOnly: true,
      }}
      closeOnSelect={true}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  formControl: {
    marginTop: theme.spacing(2),
    width: "full",
  },
  gridItem: {
    margin: "20px",
  },
  targetbox: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  area: {
    marginTop: 30,
  },
  indicatorpara: {
    marginTop: 20,
  },
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(-1),
  },
  popover: {
    pointerEvents: "none",
  },
  checkBox: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  area: {
    marginTop: 30,
  },
  sub_indicatorpara: {
    marginTop: 10,
    marginLeft: "1%",
    display: "flex",
    justifyContent: "space-between",
  },
  textArea: {
    width: "100%",
    border: "1px solid black",
    padding: "10px",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
}));

const SPWorkPlanningForm = () => {
  const dispatch = useDispatch();
  const workPlanItem = useSelector((state) => state.workplanReducer.item);
  const [progressIntroForm, setProgressIntroForm] = useState(true);

  const [mainGoalsForm, setMainGoalsForm] = useState(false);

  const [progressIndicatorsForm, setProgressIndicatorsForm] = useState(false);

  const [
    spPriorityWorkplanActivitiesForm,
    setSPPriorityWorkplanActivitiesForm,
  ] = useState(false);

  const [reviewWP, setReviewWP] = useState(false);

  function showSPProgressIntroForm() {
    setProgressIntroForm(true);
    setMainGoalsForm(false);
    setProgressIndicatorsForm(false);
    setSPPriorityWorkplanActivitiesForm(false);
  }
  function showSPMainGoalsForm() {
    setProgressIntroForm(false);
    setMainGoalsForm(true);
    setProgressIndicatorsForm(false);
    setSPPriorityWorkplanActivitiesForm(false);
  }
  function showSPsetProgressIndicatorsForm() {
    setProgressIntroForm(false);
    setMainGoalsForm(false);
    setProgressIndicatorsForm(true);
    setSPPriorityWorkplanActivitiesForm(false);
  }
  function showSPPriorityWorkplanActivitiesForm() {
    if (gl_tieronesuppliers > 100) {
      document
        .getElementById("tieronesuppliers")
        .classList.add("indicatorError");
    }
    if (gl_upstreamsuppliers > 100) {
      document
        .getElementById("upstreamsuppliers")
        .classList.add("indicatorError");
    }
    if (gl_workersInSC > 100) {
      document.getElementById("workersInSC").classList.add("indicatorError");
    }
    setProgressIntroForm(false);
    setMainGoalsForm(false);
    setProgressIndicatorsForm(false);
    setSPPriorityWorkplanActivitiesForm(true);
  }
  function backSPPriorityWorkplanActivitiesForm() {
    setSPPriorityWorkplanActivitiesForm(true);
    setReviewWP(false);
  }
  function revise() {
    setProgressIntroForm(true);
    setReviewWP(false);
  }
  function backProgressIndicatorsForm() {
    setMainGoalsForm(false);
    setProgressIndicatorsForm(true);
    setSPPriorityWorkplanActivitiesForm(false);
  }
  function showReviewWorkPlanForm() {
    setProgressIntroForm(false);
    setMainGoalsForm(false);
    setProgressIndicatorsForm(false);
    setSPPriorityWorkplanActivitiesForm(false);
    setReviewWP(true);
  }

  useEffect(() => {
    !workPlanItem.loading &&
      !workPlanItem.fetched &&
      dispatch(fetchWorkPlanData());
  }, []);

  return (
    <div>
      {workPlanItem.loading ? (
        <LinearProgress />
      ) : (
        <>
          {progressIntroForm ? (
            <SPProgressIntroForm
              workPlanItem={workPlanItem}
              useStyles={useStyles}
              next={showSPMainGoalsForm}
            />
          ) : null}
          {mainGoalsForm ? (
            <SPMainGoalsForm
              useStyles={useStyles}
              workPlanItem={workPlanItem}
              next={showSPsetProgressIndicatorsForm}
              back={showSPProgressIntroForm}
            />
          ) : null}
          {progressIndicatorsForm ? (
            <SPProgressIndicatorsForm
              useStyles={useStyles}
              workPlanItem={workPlanItem}
              next={showSPPriorityWorkplanActivitiesForm}
              back={showSPMainGoalsForm}
            />
          ) : null}
          {spPriorityWorkplanActivitiesForm ? (
            <SPPriorityWorkplanActivitiesForm
              useStyles={useStyles}
              workPlanItem={workPlanItem}
              back={backProgressIndicatorsForm}
              review={showReviewWorkPlanForm}
            />
          ) : null}
          {reviewWP ? (
            <SPWorkPlanningFormReview
              workPlanItem={workPlanItem}
              back={backSPPriorityWorkplanActivitiesForm}
              revise={revise}
            />
          ) : null}
        </>
      )}
    </div>
  );
};

export default SPWorkPlanningForm;

SPWorkPlanningForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

SPProgressIntroForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

SPMainGoalsForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

SPProgressIndicatorsForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

SPPriorityWorkplanActivitiesForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

SPWorkPlanningFormReview.contextTypes = {
  t: PropTypes.func.isRequired,
};
