// 5 of 5. Review and Finalize Annual Workplan
import React from "react";
import PropTypes from "prop-types";
import "./css/style1.css";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import FinalWorkplan from "./FinalWorkplan.js";
import HttpService from "../../../services/HttpService";

const BottomBtn = {
  marginTop: "20px",
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: "20px",
};
export default function SPWorkPlanningFormReview(props, context) {
  const [workplanID, setWorkplanID] = React.useState();
  React.useEffect(() => {
    HttpService.get(
      `${process.env.REACT_APP_API_URL}/sp_work_plan/`,
      (response) => {
        setWorkplanID(response.id);
      },
      (err) => {
        console.log(err.message);
      }
    );
  }, []);
  return (
    <Container>
      <Grid className="workplan-box">
        <h3>{context.t("5 of 5. Review and Finalize Annual Workplan")}</h3>
        <Grid container>
          <Grid item xs={12} sm={12} md={3} lg={3}></Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            style={{
              textAlign: "center",
              border: "2px solid black",
              borderRadius: "18px",
              margin: "50px 0px",
              padding: "20px 10px",
            }}
          >
            <h4 id="congratulations-text">
              <b>{context.t("CONGRATULATIONS!")}</b>
            </h4>
            <p style={{ fontWeight: "500" }}>
              {context.t(
                "Great work, you have finalized your Issara Annual Workplan! We will look forward to reviewing your company's progress at the mid-year review."
              )}
            </p>
            <Grid>
              <Grid container>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <a
                    href={`${process.env.REACT_APP_API_URL}/work_plan_pdf/${workplanID}`}
                    style={{ marginLeft: "16px" }}
                  >
                    {context.t("Download Workplan PDF")}
                  </a>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Link
                    to="/admin/spprogressdashboard"
                    style={{ marginRight: "16px" }}
                  >
                    {context.t("Go to SP Progress Metrics")}
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}></Grid>
        </Grid>
        <Grid component="span" style={BottomBtn}>
          <Button
            onClick={props.back}
            variant="contained"
            width="200px"
            style={{ marginRight: "15px", backgroundColor: "#03A9F4" }}
          >
            {context.t("Back")}
          </Button>
          <Button
            onClick={props.revise}
            variant="contained"
            width="200px"
            style={{ marginRight: "15px" }}
          >
            {context.t("Revise")}
          </Button>
          <Button
            variant="contained"
            width="200px"
            style={{ marginRight: "15px" }}
          >
            {context.t("FINALIZE & SAVE WORKPLAN")}
          </Button>
        </Grid>
      </Grid>
      <Grid className="workplan-box">
        <FinalWorkplan />
      </Grid>
    </Container>
  );
}

SPWorkPlanningFormReview.contextTypes = {
  t: PropTypes.func.isRequired,
};
