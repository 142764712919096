import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

export const createDataPoint = (color, value, legend) => {
  if (!color || (value !== 0 && !value) || !legend) {
    console.warn(
      "Missing data for bar chart data point.",
      color,
      value,
      legend
    );
  }

  return {
    color: color,
    value: value,
    legend: legend,
  };
};

const SingleHorizontalBar = ({ data, displayUnit = null }) => {
  const containerRef = useRef();
  const chartRef = useRef();
  useEffect(() => {
    const svg = d3.select(chartRef.current);
    const container = containerRef.current;
    const width = container.clientWidth;
    const height = 40;
    const legendHeight = 30;
    const startY = 16;

    const getTextColor = (backgroundColor) => {
      const rgb = d3.color(backgroundColor).rgb();
      const luminance = (0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b) / 255;
      return luminance > 0.5 ? "black" : "white";
    };

    const canAreaContainLabel = (value) => {
      const displayValue = displayUnit ? `${value} ${displayUnit}` : value;
      const textWidth = context.measureText(displayValue).width;

      return xScale(value) >= textWidth + 6;
    };

    svg.selectAll("*").remove();

    const filteredData = data.filter((d) => d.value > 0);

    // No data available
    if (filteredData.length === 0) {
      svg
        .attr("width", width)
        .attr("height", height + legendHeight)
        .append("text")
        .attr("x", width / 2)
        .attr("y", (height + legendHeight) / 2)
        .attr("text-anchor", "middle")
        .attr("dy", "0.35em")
        .text("No chart data available.")
        .attr("fill", "black")
        .style("font-size", "14px");
      return;
    }

    const totalValue = d3.sum(filteredData, (d) => d.value);
    const xScale = d3.scaleLinear().domain([0, totalValue]).range([0, width]);

    let accumulatedValue = 0;

    // Hidden canvas for text measurement
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = "14px sans-serif";

    // Bars
    svg
      .attr("width", width)
      .attr("height", height + legendHeight + startY)
      .append("g")
      .selectAll("rect")
      .data(filteredData)
      .enter()
      .append("rect")
      .attr("x", (d) => xScale((accumulatedValue += d.value)) - xScale(d.value))
      .attr("y", startY)
      .attr("width", (d) => xScale(d.value))
      .attr("height", height)
      .attr("fill", (d) => d.color);

    // Labels
    accumulatedValue = 0;
    svg
      .append("g")
      .selectAll("text")
      .data(filteredData)
      .enter()
      .append("text")
      .attr("x", (d) => {
        const cumulativeXScale = xScale((accumulatedValue += d.value));
        return cumulativeXScale >= width && !canAreaContainLabel(d.value)
          ? width - 1
          : cumulativeXScale - xScale(d.value) / 2;
      })
      .attr("y", (d) => {
        return canAreaContainLabel(d.value) ? height / 2 + startY : startY + 2;
      })
      .attr("text-anchor", (d) => {
        return xScale(accumulatedValue) >= width &&
          !canAreaContainLabel(d.value)
          ? "end"
          : "middle";
      })
      .attr("dy", (d) => {
        return canAreaContainLabel(d.value) ? "0.35em" : "-0.5em";
      })
      .text((d) => {
        return displayUnit ? `${d.value} ${displayUnit}` : d.value;
      })
      .attr("fill", (d) => {
        return canAreaContainLabel(d.value) ? getTextColor(d.color) : "black";
      })
      .style("font-size", "14px");

    // Legend
    const legend = svg
      .append("g")
      .attr("transform", `translate(0, ${height + startY + 10})`);

    let legendX = 0;
    let legendY = 10;
    const legendSpace = 15;
    const legendRowHeight = 20;
    let legendTotalHeight = legendY + legendRowHeight;

    data.forEach((d) => {
      const legendItem = legend
        .append("g")
        .attr("transform", `translate(${legendX}, ${legendY})`);

      // Color container
      legendItem
        .append("rect")
        .attr("width", 15)
        .attr("height", 15)
        .attr("fill", d.color)
        .attr("y", -6);

      // Text
      legendItem
        .append("text")
        .attr("x", 20)
        .attr("y", 0)
        .attr("dy", "0.35em")
        .text(d.legend)
        .style("font-size", "14px")
        .attr("fill", "black");

      const legendItemWidth = legendItem.node().getBBox().width;

      if (legendX + legendItemWidth > width) {
        legendX = 0;
        legendY += legendRowHeight + 10;
        legendTotalHeight = legendY + legendRowHeight;
        legendItem.attr("transform", `translate(${legendX}, ${legendY})`);
      }

      legendX += legendItemWidth + legendSpace;
    });

    svg.attr("height", height + startY + legendTotalHeight);
  }, [data, displayUnit]);

  return (
    <div ref={containerRef} style={{ width: "100%", height: "auto" }}>
      <svg ref={chartRef} width="100%" />
    </div>
  );
};

export default SingleHorizontalBar;
