import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import "./css/activity_summary.css";
import GridItem from "components/Grid/GridItem.js";
import Utils from "services/utils.js";
import GridContainer from "components/Grid/GridContainer";

const SupplierProgressActivitySummary = ({ supplierId, context }) => {
  const [activitySummary, setActivitySummary] = useState({});
  const [trainings, setTrainings] = useState([]);

  useEffect(() => {
    const fetchSupplierData = async () => {
      if (!supplierId) return;

      try {
        const [activityResponse, trainingResponse] = await Promise.all([
          axios.get(
            `${process.env.REACT_APP_API_URL}/supplier/${supplierId}/activity-summary`
          ),
          axios.get(
            `${process.env.REACT_APP_API_URL}/supplier/${supplierId}/trainings`
          ),
        ]);

        if (activityResponse.data) {
          setActivitySummary(activityResponse.data);
        }

        if (trainingResponse.data?.length > 0) {
          setTrainings(trainingResponse.data);
        }
      } catch (error) {
        console.log("Error fetching supplier data", error);
      }
    };

    fetchSupplierData();
  }, [supplierId]);
  return (
    <Paper className="activity_paper">
      <h4>{context.t("Supplier Activities Summary")}</h4>
      {/* Area 1 */}
      <Grid container spacing={3}>
        <span className="activity_area_text">
          {context.t("ACTIVITIES FOR AREA 1 - WORKER VOICE")}
        </span>
        <GridItem xs={12}>
          <span className="activity_summary_category">
            {context.t("Policies, Operational Systems, & Procedures")}
          </span>
        </GridItem>
        <GridItem xs={11}>
          <span className="activity_summary_label">
            {context.t(
              "1.1. Establish an Ethical Grievance Mechanisms/Worker Voice Policy"
            )}
          </span>
        </GridItem>
        <GridItem xs={1}>
          {Utils.getStatusColor(
            context,
            activitySummary.ethical_grievance_worker_voice_policy || "not yet",
            false
          )}
        </GridItem>

        <GridItem xs={11}>
          <span className="activity_summary_label">
            {context.t(
              "1.2. Systems & Procedures for Worker Voice & Ethical Grievance Mechanisms"
            )}
          </span>
        </GridItem>
        <GridItem xs={1}>
          {Utils.getStatusColor(
            context,
            activitySummary.worker_voice_grievance_systems_procedures ||
              "not yet",
            false
          )}
        </GridItem>

        <GridItem xs={12}>
          <span className="activity_summary_category">
            {context.t("Effective Communications")}
          </span>
        </GridItem>
        <GridItem xs={11}>
          <span className="activity_summary_label">
            {context.t(
              "1.3. Communications regarding Worker Voice Channels,Grievance Mechanisms & Worker Surveys"
            )}
          </span>
        </GridItem>
        <GridItem xs={1}>
          {Utils.getStatusColor(
            context,
            activitySummary.worker_voice_grievance_communication || "not yet",
            false
          )}
        </GridItem>

        <GridItem xs={11}>
          <span className="activity_summary_label">
            {context.t("1.5. Capacity building: Ethical Grievance Mechanisms")}
          </span>
        </GridItem>
        <GridItem xs={1}>
          {Utils.getStatusColor(
            context,
            activitySummary.grievance_mechanisms_capacity_building || "not yet",
            false
          )}
        </GridItem>

        <GridItem xs={12}>
          <span className="activity_summary_category">
            {context.t("System Strengthening")}
          </span>
        </GridItem>
        <GridItem xs={11}>
          <span className="activity_summary_label">
            {context.t("1.6. Education & Empowerment")}
          </span>
        </GridItem>
        <GridItem xs={1}>
          {Utils.getStatusColor(
            context,
            activitySummary.education_empowerment || "not yet",
            false
          )}
        </GridItem>
      </Grid>

      {/* Area 2 */}
      <Grid container spacing={3} style={{ marginTop: "10px" }}>
        <span className="activity_area_text">
          {context.t("ACTIVITIES FOR AREA 2 - LABOR RECRUITMENT")}
        </span>
        <GridItem xs={12}>
          <span className="activity_summary_category">
            {context.t("Policies, Operational Systems, & Procedures")}
          </span>
        </GridItem>
        <GridItem xs={11}>
          <span className="activity_summary_label">
            {context.t("2.1. Ethical/Responsible Recruitment policy")}
          </span>
        </GridItem>
        <GridItem xs={1}>
          {Utils.getStatusColor(
            context,
            activitySummary.ethical_recruitment_policy || "not yet",
            false
          )}
        </GridItem>

        <GridItem xs={11}>
          <span className="activity_summary_label">
            {context.t("2.2. System and Procedures for Ethical Recruitment")}
          </span>
        </GridItem>
        <GridItem xs={1}>
          {Utils.getStatusColor(
            context,
            activitySummary.ethical_recruitment_system_procedures || "not yet",
            false
          )}
        </GridItem>

        <GridItem xs={12}>
          <span className="activity_summary_category">
            {context.t("Effective Communications")}
          </span>
        </GridItem>
        <GridItem xs={11}>
          <span className="activity_summary_label">
            {context.t(
              "2.3. Disclosure of Recruitment Agencies & Policies to Customers"
            )}
          </span>
        </GridItem>
        <GridItem xs={1}>
          {Utils.getStatusColor(
            context,
            activitySummary.recruitment_agency_policy_disclosure || "not yet",
            false
          )}
        </GridItem>

        <GridItem xs={11}>
          <span className="activity_summary_label">
            {context.t(
              "2.4. Communication of Recruitment Policies & Requirements"
            )}
          </span>
        </GridItem>
        <GridItem xs={1}>
          {Utils.getStatusColor(
            context,
            activitySummary.recruitment_policy_communication || "not yet",
            false
          )}
        </GridItem>

        <GridItem xs={11}>
          <span className="activity_summary_label">
            {context.t(
              "2.5. Communication of Recruitment Policies to Job Candidates & Workers"
            )}
          </span>
        </GridItem>
        <GridItem xs={1}>
          {Utils.getStatusColor(
            context,
            activitySummary.candidate_worker_recruitment_communication ||
              "not yet",
            false
          )}
        </GridItem>

        <GridItem xs={12}>
          <span className="activity_summary_category">
            {context.t("System Strengthening")}
          </span>
        </GridItem>
        <GridItem xs={11}>
          <span className="activity_summary_label">
            {context.t("2.6. Recruitment Surveys")}
          </span>
        </GridItem>
        <GridItem xs={1}>
          <span className="activity_summary_date">
            {activitySummary.recruitment_survey || "-"}
          </span>
        </GridItem>

        <GridItem xs={12}>
          <span className="activity_summary_label">
            {context.t("2.7. Recruitment Fees Repayment")}
          </span>
        </GridItem>

        <GridItem xs={11}>
          <span className="activity_summary_recruitment_fees">
            {context.t("Reimbursed")}
          </span>
        </GridItem>
        <GridItem xs={1}>
          <span className="activity_summary_amount">
            {activitySummary.reimbursement || "-"}
          </span>
        </GridItem>
        <GridItem xs={11}>
          <span className="activity_summary_recruitment_fees">
            {context.t("Agreed and in process for reimbursement")}
          </span>
        </GridItem>
        <GridItem xs={1}>
          <span className="activity_summary_amount">
            {activitySummary.reimbursement_agreed_in_process || "-"}
          </span>
        </GridItem>
        <GridItem xs={11}>
          <span className="activity_summary_recruitment_fees">
            {context.t("Not agreed for reimbursement")}
          </span>
        </GridItem>
        <GridItem xs={1}>
          <span className="activity_summary_amount">
            {activitySummary.reimbursement_not_agreed || "-"}
          </span>
        </GridItem>
        <GridItem xs={11}>
          <span className="activity_summary_recruitment_fees">
            {context.t(
              "Number of eligible workers that have not been reimbursed"
            )}
          </span>
        </GridItem>
        <GridItem xs={1}>
          <span className="activity_summary_amount">
            {activitySummary.eligible_workers_not_reimbursed_count || "-"}
          </span>
        </GridItem>
        <GridItem xs={11}>
          <span className="activity_summary_recruitment_fees">
            {context.t("Number of eligible workers that have been reimbursed")}
          </span>
        </GridItem>
        <GridItem xs={1}>
          <span className="activity_summary_amount">
            {activitySummary.eligible_workers_reimbursed_count || "-"}
          </span>
        </GridItem>

        <GridItem xs={11}>
          <span className="activity_summary_label">
            {context.t("2.8. Capacity Building: Ethical Recruitment Trainings")}
          </span>
        </GridItem>
        <GridItem xs={1}>
          {Utils.getStatusColor(
            context,
            activitySummary.ethical_recruitment_training_capacity_building ||
              "not yet",
            false
          )}
        </GridItem>
        <GridItem xs={11}>
          <span className="activity_summary_label">
            {context.t("2.9. Ethical Recruitment Network (ERN) Collaboration")}
          </span>
        </GridItem>
        <GridItem xs={1}>
          {Utils.getStatusColor(
            context,
            activitySummary.ern_collaboration || "not yet",
            false
          )}
        </GridItem>
        <GridItem xs={11}>
          <span className="activity_summary_label">
            {context.t("2.10. Golden Dreams")}
          </span>
        </GridItem>
        <GridItem xs={1}>
          {Utils.getStatusColor(
            context,
            activitySummary.golden_dreams || "not yet",
            false
          )}
        </GridItem>
      </Grid>

      {/* Area 3 */}
      <Grid container spacing={3} style={{ marginTop: "10px" }}>
        <span className="activity_area_text">
          {context.t("ACTIVITIES FOR AREA 3 - WORKING CONDITIONS")}
        </span>
        <GridItem xs={12}>
          <span className="activity_summary_category">
            {context.t("Policies, Operational Systems, & Procedures")}
          </span>
        </GridItem>
        <GridItem xs={11}>
          <span className="activity_summary_label">
            {context.t("3.1. Ethical Sourcing Policy / Roadmap Plan")}
          </span>
        </GridItem>
        <GridItem xs={1}>
          {Utils.getStatusColor(
            context,
            activitySummary.ethical_sourcing_policy_roadmap || "not yet",
            false
          )}
        </GridItem>

        <GridItem xs={11}>
          <span className="activity_summary_label">
            {context.t("3.5. Worker Satisfaction Survey")}
          </span>
        </GridItem>
        <GridItem xs={1}>
          <span className="activity_summary_date">
            {activitySummary.worker_satisfaction_survey || "-"}
          </span>
        </GridItem>

        <GridItem xs={12}>
          <span className="activity_summary_category">
            {context.t("Effective Communications")}
          </span>
        </GridItem>
        <GridItem xs={11}>
          <span className="activity_summary_label">
            {context.t(
              "3.4. Strengthening Communications & Relations Responding to Workers Voice"
            )}
          </span>
        </GridItem>
        <GridItem xs={1}>
          {Utils.getStatusColor(
            context,
            activitySummary.strengthening_worker_voice_communications ||
              "not yet",
            false
          )}
        </GridItem>

        <GridItem xs={12}>
          <span className="activity_summary_category">
            {context.t("System Strengthening")}
          </span>
        </GridItem>
        <GridItem xs={11}>
          <span className="activity_summary_label">
            {context.t("3.2. Ongoing Worker Voice-Driven Remediation")}
          </span>
        </GridItem>
        <GridItem xs={1}>
          {Utils.getStatusColor(
            context,
            activitySummary.ongoing_worker_voice_remediation || "not yet",
            false
          )}
        </GridItem>

        <GridItem xs={11}>
          <span className="activity_summary_label">
            {context.t(
              "3.6. Capacity Building on Business & Human Rights/HRDD"
            )}
          </span>
        </GridItem>
        <GridItem xs={1}>
          <span className="activity_summary_date">
            {activitySummary.capacity_building_business_human_rights || "-"}
          </span>
        </GridItem>

        <GridItem xs={11}>
          <span className="activity_summary_label">
            {context.t(
              "3.7. Capacity Building: Interpreters & Line Supervisors"
            )}
          </span>
        </GridItem>
        <GridItem xs={1}>
          <span className="activity_summary_date">
            {activitySummary.capacity_building_interpreters_line_supervisors ||
              "-"}
          </span>
        </GridItem>

        <GridItem xs={11}>
          <span className="activity_summary_label">
            {context.t("3.8. Capacity Building: Management & Leadership")}
          </span>
        </GridItem>
        <GridItem xs={1}>
          <span className="activity_summary_date">
            {activitySummary.capacity_building_management_leadership || "-"}
          </span>
        </GridItem>

        <GridItem xs={11}>
          <span className="activity_summary_label">
            {context.t("3.9. Targeted, Responsive Systems Strengthening")}
          </span>
        </GridItem>
        <GridItem xs={1}>
          {Utils.getStatusColor(
            context,
            activitySummary.targeted_responsive_systems_strengthening ||
              "not yet",
            false
          )}
        </GridItem>

        {trainings?.length > 0 && (
          <GridContainer>
            <GridItem xs={12}>
              <span className="activity_summary_category">
                {context.t("List of Traingings Joined")}
              </span>
            </GridItem>
            {trainings.map((training, index) => (
              <React.Fragment key={training.id}>
                <GridItem xs={11}>
                  <span className="activity_summary_label">
                    {index + 1}. {training.name}
                    {training.training_name
                      ? ` - ${training.training_name}`
                      : ""}
                  </span>
                </GridItem>
                <GridItem xs={1}>
                  <span className="activity_summary_date">
                    {training.fieldwork_date || "-"}
                  </span>
                </GridItem>
              </React.Fragment>
            ))}
          </GridContainer>
        )}
      </Grid>
    </Paper>
  );
};

export default SupplierProgressActivitySummary;

SupplierProgressActivitySummary.contextTypes = {
  t: PropTypes.func.isRequired,
};
