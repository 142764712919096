import React from "react";
import { Grid, InputLabel } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomToggleButtonGroup from "views/Dashboard/SPWorkPlanning/CustomToggleButtonGroup";
import loginStore from "../../redux/stores/LoginStore";
import "./css/activity_summary.css";

const ActivitiesSummarySubCategory = {
  policies_operational_systems_and_procedures:
    "Policies, Operational Systems, & Procedures",
  effective_communications: "Effective Communications",
  system_strengthening: "System Strengthening",
};

const getNumberValue = (value) => {
  if (value === "0" || value.startsWith("0.")) {
    return value;
  }
  return value.replace(/^0+/, "");
};

const SupplierActivitySummary = ({ data, handleActivityChange }) => {
  return (
    <GridContainer className="activity_container">
      <GridItem xs={12}>
        <h3>ACTIVITIES SUMMARY</h3>
      </GridItem>
      <GridItem xs={12}>
        <h4 style={{ marginLeft: "5px" }}>
          {`ACTIVITIES FOR AREA 1 - WORKER VOICE`}
        </h4>
      </GridItem>
      <GridItem xs={12}>
        <InputLabel style={{ marginLeft: "50px" }}>
          {
            ActivitiesSummarySubCategory.policies_operational_systems_and_procedures
          }
        </InputLabel>
      </GridItem>
      <GridItem xs={1} />
      <GridItem xs={6}>
        <InputLabel>
          1.1. Establish an Ethical Grievance Mechanisms/Worker Voice Policy
        </InputLabel>
      </GridItem>
      <GridItem xs={5}>
        <CustomToggleButtonGroup
          value={data.ethical_grievance_worker_voice_policy || "not yet"}
          id="ethical_grievance_worker_voice_policy"
          name="ethical_grievance_worker_voice_policy"
          onChange={(value) =>
            handleActivityChange("ethical_grievance_worker_voice_policy", value)
          }
          complete_color_teal={true}
        />
      </GridItem>
      <GridItem xs={1} />
      <GridItem xs={6}>
        <InputLabel>
          1.2. Systems & Procedures for Worker Voice & Ethical Grievance
          Mechanisms
        </InputLabel>
      </GridItem>
      <GridItem xs={5}>
        <CustomToggleButtonGroup
          value={data.worker_voice_grievance_systems_procedures || "not yet"}
          id="worker_voice_grievance_systems_procedures"
          name="worker_voice_grievance_systems_procedures"
          onChange={(value) =>
            handleActivityChange(
              "worker_voice_grievance_systems_procedures",
              value
            )
          }
          complete_color_teal={true}
        />
      </GridItem>

      <GridItem xs={12}>
        <InputLabel style={{ marginLeft: "50px" }}>
          {ActivitiesSummarySubCategory.effective_communications}
        </InputLabel>
      </GridItem>
      <GridItem xs={1} />
      <GridItem xs={6}>
        <InputLabel>
          1.3. Communications regarding Worker Voice Channels, Grievance
          Mechanisms & Worker Surveys
        </InputLabel>
      </GridItem>
      <GridItem xs={5}>
        <CustomToggleButtonGroup
          value={data.worker_voice_grievance_communication || "not yet"}
          id="worker_voice_grievance_communication"
          name="worker_voice_grievance_communication"
          onChange={(value) =>
            handleActivityChange("worker_voice_grievance_communication", value)
          }
          complete_color_teal={true}
        />
      </GridItem>

      <GridItem xs={12}>
        <InputLabel style={{ marginLeft: "50px" }}>
          {ActivitiesSummarySubCategory.system_strengthening}
        </InputLabel>
      </GridItem>
      <GridItem xs={1} />
      <GridItem xs={6}>
        <InputLabel>
          1.5. Capacity building: Ethical Grievance Mechanisms
        </InputLabel>
      </GridItem>
      <GridItem xs={5}>
        <CustomToggleButtonGroup
          value={data.grievance_mechanisms_capacity_building || "not yet"}
          id="grievance_mechanisms_capacity_building"
          name="grievance_mechanisms_capacity_building"
          onChange={(value) =>
            handleActivityChange(
              "grievance_mechanisms_capacity_building",
              value
            )
          }
          complete_color_teal={true}
        />
      </GridItem>
      <GridItem xs={1} />
      <GridItem xs={6}>
        <InputLabel>1.6. Education & Empowerment</InputLabel>
      </GridItem>
      <GridItem xs={5}>
        <CustomToggleButtonGroup
          value={data.education_empowerment || "not yet"}
          id="education_empowerment"
          name="education_empowerment"
          onChange={(value) =>
            handleActivityChange("education_empowerment", value)
          }
          complete_color_teal={true}
        />
      </GridItem>

      <GridItem xs={12}>
        <h4 style={{ marginLeft: "5px" }}>
          {`ACTIVITIES FOR AREA 2 - LABOR MANAGEMENT`}
        </h4>
      </GridItem>
      <GridItem xs={12}>
        <InputLabel style={{ marginLeft: "50px" }}>
          {
            ActivitiesSummarySubCategory.policies_operational_systems_and_procedures
          }
        </InputLabel>
      </GridItem>
      <GridItem xs={1} />
      <GridItem xs={6}>
        <InputLabel>2.1. Ethical/Responsible Recruitment policy</InputLabel>
      </GridItem>
      <GridItem xs={5}>
        <CustomToggleButtonGroup
          value={data.ethical_recruitment_policy || "not yet"}
          id="ethical_recruitment_policy"
          name="ethical_recruitment_policy"
          onChange={(value) =>
            handleActivityChange("ethical_recruitment_policy", value)
          }
          complete_color_teal={true}
        />
      </GridItem>
      <GridItem xs={1} />
      <GridItem xs={6}>
        <InputLabel>
          2.2. System and Procedures for Ethical Recruitment
        </InputLabel>
      </GridItem>
      <GridItem xs={5}>
        <CustomToggleButtonGroup
          value={data.ethical_recruitment_system_procedures || "not yet"}
          id="ethical_recruitment_system_procedures"
          name="ethical_recruitment_system_procedures"
          onChange={(value) =>
            handleActivityChange("ethical_recruitment_system_procedures", value)
          }
          complete_color_teal={true}
        />
      </GridItem>

      <GridItem xs={12}>
        <InputLabel style={{ marginLeft: "50px" }}>
          {ActivitiesSummarySubCategory.effective_communications}
        </InputLabel>
      </GridItem>
      <GridItem xs={1} />
      <GridItem xs={6}>
        <InputLabel>
          2.3. Disclosure of Recruitment Agencies & Policies to Customers
        </InputLabel>
      </GridItem>
      <GridItem xs={5}>
        <CustomToggleButtonGroup
          value={data.recruitment_agency_policy_disclosure || "not yet"}
          id="recruitment_agency_policy_disclosure"
          name="recruitment_agency_policy_disclosure"
          onChange={(value) =>
            handleActivityChange("recruitment_agency_policy_disclosure", value)
          }
          complete_color_teal={true}
        />
      </GridItem>
      <GridItem xs={1} />
      <GridItem xs={6}>
        <InputLabel>
          2.4. Communication of Recruitment Policies & Requirements
        </InputLabel>
      </GridItem>
      <GridItem xs={5}>
        <CustomToggleButtonGroup
          value={data.recruitment_policy_communication || "not yet"}
          id="recruitment_policy_communication"
          name="recruitment_policy_communication"
          onChange={(value) =>
            handleActivityChange("recruitment_policy_communication", value)
          }
          complete_color_teal={true}
        />
      </GridItem>
      <GridItem xs={1} />
      <GridItem xs={6}>
        <InputLabel>
          2.5. Communication of Recruitment Policies to Job Candidates & Workers
        </InputLabel>
      </GridItem>
      <GridItem xs={5}>
        <CustomToggleButtonGroup
          value={data.candidate_worker_recruitment_communication || "not yet"}
          id="candidate_worker_recruitment_communication"
          name="candidate_worker_recruitment_communication"
          onChange={(value) =>
            handleActivityChange(
              "candidate_worker_recruitment_communication",
              value
            )
          }
          complete_color_teal={true}
        />
      </GridItem>

      <GridItem xs={12}>
        <InputLabel style={{ marginLeft: "50px" }}>
          {ActivitiesSummarySubCategory.system_strengthening}
        </InputLabel>
      </GridItem>
      <GridItem xs={1} />
      <GridItem xs={6}>
        <InputLabel>2.6. Recruitment Surveys</InputLabel>
      </GridItem>
      <GridItem xs={5}>
        <div style={{ marginTop: "-30px" }}>
          <KeyboardDatePicker
            autoOk
            disableToolbar
            variant="inline"
            format="yyyy-MM-dd"
            label="Date of most recent survey"
            value={data.recruitment_survey}
            KeyboardButtonProps={{ "aria-label": "change date" }}
            onChange={(date) =>
              handleActivityChange(
                "recruitment_survey",
                date.toISOString().split("T")[0]
              )
            }
          />
        </div>
      </GridItem>
      <GridItem xs={1} />
      <GridItem xs={11}>
        <InputLabel>2.7. Recruitment Fees Repayment</InputLabel>
      </GridItem>
      <Grid container spacing={3} style={{ marginBottom: "30px" }}>
        <GridItem xs={1} />
        <GridItem xs={3}>
          <CustomInput
            id="reimbursement"
            labelText={"Reimbursement"}
            formControlProps={{ fullWidth: true }}
            inputProps={{
              type: "number",
              onChange: (event) => {
                let value = getNumberValue(event.target.value);
                handleActivityChange("reimbursement", value);
              },
              disabled:
                !loginStore.isIssaraManagement() && !loginStore.isIssaraStaff(),
            }}
            value={data.reimbursement || 0}
          />
        </GridItem>
        <GridItem xs={3}>
          <CustomInput
            id="reimbursement_agreed_in_process"
            labelText={"Agreed and in process for reimbursement"}
            formControlProps={{ fullWidth: true }}
            inputProps={{
              type: "number",
              onChange: (event) => {
                let value = getNumberValue(event.target.value);
                handleActivityChange("reimbursement_agreed_in_process", value);
              },
              disabled:
                !loginStore.isIssaraManagement() && !loginStore.isIssaraStaff(),
            }}
            value={data.reimbursement_agreed_in_process || 0}
          />
        </GridItem>
        <GridItem xs={3}>
          <CustomInput
            id="reimbursement_not_agreed"
            labelText={"Not agreed for reimbursement"}
            formControlProps={{ fullWidth: true }}
            inputProps={{
              type: "number",
              onChange: (event) => {
                let value = getNumberValue(event.target.value);
                handleActivityChange("reimbursement_not_agreed", value);
              },
              disabled:
                !loginStore.isIssaraManagement() && !loginStore.isIssaraStaff(),
            }}
            value={data.reimbursement_not_agreed || 0}
          />
        </GridItem>
        <GridItem xs={1} />
        <GridItem xs={2} />
        <GridItem xs={4}>
          <CustomInput
            id="eligible_workers_not_reimbursed_count"
            labelText={
              "Number of eligible workers that have not been reimbursed"
            }
            formControlProps={{ fullWidth: true }}
            inputProps={{
              type: "number",
              onChange: (event) => {
                let value = getNumberValue(event.target.value);
                handleActivityChange(
                  "eligible_workers_not_reimbursed_count",
                  value
                );
              },
              disabled:
                !loginStore.isIssaraManagement() && !loginStore.isIssaraStaff(),
            }}
            value={data.eligible_workers_not_reimbursed_count || 0}
          />
        </GridItem>
        <GridItem xs={4}>
          <CustomInput
            id="eligible_workers_reimbursed_count"
            labelText={"Number of eligible workers that have been reimbursed"}
            formControlProps={{ fullWidth: true }}
            inputProps={{
              type: "number",
              onChange: (event) => {
                let value = getNumberValue(event.target.value);
                handleActivityChange(
                  "eligible_workers_reimbursed_count",
                  value
                );
              },
              disabled:
                !loginStore.isIssaraManagement() && !loginStore.isIssaraStaff(),
            }}
            value={data.eligible_workers_reimbursed_count || 0}
          />
        </GridItem>
        <GridItem xs={2} />
      </Grid>
      <GridItem xs={1} />
      <GridItem xs={6}>
        <InputLabel>
          2.8. Capacity Building: Ethical Recruitment Trainings
        </InputLabel>
      </GridItem>
      <GridItem xs={5}>
        <div style={{ marginTop: "-30px" }}>
          <KeyboardDatePicker
            autoOk
            disableToolbar
            variant="inline"
            format="yyyy-MM-dd"
            label="Date of most recent training joined"
            value={data.ethical_recruitment_training_capacity_building}
            KeyboardButtonProps={{ "aria-label": "change date" }}
            onChange={(date) =>
              handleActivityChange(
                "ethical_recruitment_training_capacity_building",
                date.toISOString().split("T")[0]
              )
            }
          />
        </div>
      </GridItem>
      <GridItem xs={1} />
      <GridItem xs={6}>
        <InputLabel>
          2.9. Ethical Recruitment Network (ERN) Collaboration
        </InputLabel>
      </GridItem>
      <GridItem xs={5}>
        <CustomToggleButtonGroup
          value={data.ern_collaboration || "not yet"}
          id="ern_collaboration"
          name="ern_collaboration"
          onChange={(value) => handleActivityChange("ern_collaboration", value)}
          complete_color_teal={true}
        />
      </GridItem>
      <GridItem xs={1} />
      <GridItem xs={6}>
        <InputLabel>2.10. Golden Dreams</InputLabel>
      </GridItem>
      <GridItem xs={5}>
        <CustomToggleButtonGroup
          value={data.golden_dreams || "not yet"}
          id="golden_dreams"
          name="golden_dreams"
          onChange={(value) => handleActivityChange("golden_dreams", value)}
          complete_color_teal={true}
        />
      </GridItem>

      <GridItem xs={12}>
        <h4 style={{ marginLeft: "5px" }}>
          {`ACTIVITIES FOR AREA 3 - WORKING CONDITIONS`}
        </h4>
      </GridItem>
      <GridItem xs={12}>
        <InputLabel style={{ marginLeft: "50px" }}>
          {
            ActivitiesSummarySubCategory.policies_operational_systems_and_procedures
          }
        </InputLabel>
      </GridItem>
      <Grid container spacing={3} style={{ marginBottom: "30px" }}>
        <GridItem xs={1} />
        <GridItem xs={6}>
          <InputLabel>3.1. Ethical Sourcing Policy / Roadmap Plan</InputLabel>
        </GridItem>
        <GridItem xs={5}>
          <CustomToggleButtonGroup
            value={data.ethical_sourcing_policy_roadmap || "not yet"}
            id="ethical_sourcing_policy_roadmap"
            name="ethical_sourcing_policy_roadmap"
            onChange={(value) =>
              handleActivityChange("ethical_sourcing_policy_roadmap", value)
            }
            complete_color_teal={true}
          />
        </GridItem>
      </Grid>
      <Grid container spacing={3} style={{ marginBottom: "30px" }}>
        <GridItem xs={1} />
        <GridItem xs={6}>
          <InputLabel>3.5. Worker Satisfaction Survey</InputLabel>
        </GridItem>
        <GridItem xs={5}>
          <div style={{ marginTop: "-30px" }}>
            <KeyboardDatePicker
              autoOk
              disableToolbar
              variant="inline"
              format="yyyy-MM-dd"
              label="Date of most recent survey"
              value={data.worker_satisfaction_survey}
              KeyboardButtonProps={{ "aria-label": "change date" }}
              onChange={(date) =>
                handleActivityChange(
                  "worker_satisfaction_survey",
                  date.toISOString().split("T")[0]
                )
              }
            />
          </div>
        </GridItem>
      </Grid>
      <GridItem xs={12}>
        <InputLabel style={{ marginLeft: "50px" }}>
          {ActivitiesSummarySubCategory.effective_communications}
        </InputLabel>
      </GridItem>
      <GridItem xs={1} />
      <GridItem xs={6}>
        <InputLabel>
          3.4. Strengthening Communications & Relations Responding to Workers
          Voice
        </InputLabel>
      </GridItem>
      <GridItem xs={5}>
        <CustomToggleButtonGroup
          value={data.strengthening_worker_voice_communications || "not yet"}
          id="strengthening_worker_voice_communications"
          name="strengthening_worker_voice_communications"
          onChange={(value) =>
            handleActivityChange(
              "strengthening_worker_voice_communications",
              value
            )
          }
          complete_color_teal={true}
        />
      </GridItem>

      <GridItem xs={12}>
        <InputLabel style={{ marginLeft: "50px" }}>
          {ActivitiesSummarySubCategory.system_strengthening}
        </InputLabel>
      </GridItem>
      <Grid container spacing={3} style={{ marginBottom: "30px" }}>
        <GridItem xs={1} />
        <GridItem xs={6}>
          <InputLabel>3.2. Ongoing Worker Voice-Driven Remediation</InputLabel>
        </GridItem>
        <GridItem xs={5}>
          <CustomToggleButtonGroup
            value={data.ongoing_worker_voice_remediation || "not yet"}
            id="ongoing_worker_voice_remediation"
            name="ongoing_worker_voice_remediation"
            onChange={(value) =>
              handleActivityChange("ongoing_worker_voice_remediation", value)
            }
            complete_color_teal={true}
          />
        </GridItem>
      </Grid>
      <Grid container spacing={3} style={{ marginBottom: "30px" }}>
        <GridItem xs={1} />
        <GridItem xs={6}>
          <InputLabel>
            3.6. Capacity Building on Business & Human Rights/HRDD
          </InputLabel>
        </GridItem>
        <GridItem xs={5}>
          <div style={{ marginTop: "-30px" }}>
            <KeyboardDatePicker
              autoOk
              disableToolbar
              variant="inline"
              format="yyyy-MM-dd"
              label="Date of most recent training joined"
              value={data.capacity_building_business_human_rights}
              KeyboardButtonProps={{ "aria-label": "change date" }}
              onChange={(date) =>
                handleActivityChange(
                  "capacity_building_business_human_rights",
                  date.toISOString().split("T")[0]
                )
              }
            />
          </div>
        </GridItem>
      </Grid>
      <Grid container spacing={3} style={{ marginBottom: "30px" }}>
        <GridItem xs={1} />
        <GridItem xs={6}>
          <InputLabel>
            3.7. Capacity Building: Interpreters & Line Supervisors
          </InputLabel>
        </GridItem>
        <GridItem xs={5}>
          <div style={{ marginTop: "-30px" }}>
            <KeyboardDatePicker
              autoOk
              disableToolbar
              variant="inline"
              format="yyyy-MM-dd"
              label="Date of most recent training joined"
              value={data.capacity_building_interpreters_line_supervisors}
              KeyboardButtonProps={{ "aria-label": "change date" }}
              onChange={(date) =>
                handleActivityChange(
                  "capacity_building_interpreters_line_supervisors",
                  date.toISOString().split("T")[0]
                )
              }
            />
          </div>
        </GridItem>
      </Grid>
      <Grid container spacing={3} style={{ marginBottom: "30px" }}>
        <GridItem xs={1} />
        <GridItem xs={6}>
          <InputLabel>
            3.8. Capacity Building: Management & Leadership
          </InputLabel>
        </GridItem>
        <GridItem xs={5}>
          <div style={{ marginTop: "-30px" }}>
            <KeyboardDatePicker
              autoOk
              disableToolbar
              variant="inline"
              format="yyyy-MM-dd"
              label="Date of most recent training joined"
              value={data.capacity_building_management_leadership}
              KeyboardButtonProps={{ "aria-label": "change date" }}
              onChange={(date) =>
                handleActivityChange(
                  "capacity_building_management_leadership",
                  date.toISOString().split("T")[0]
                )
              }
            />
          </div>
        </GridItem>
      </Grid>
      <Grid container spacing={3} style={{ marginBottom: "30px" }}>
        <GridItem xs={1} />
        <GridItem xs={6}>
          <InputLabel>
            3.9. Targeted, Responsive Systems Strengthening
          </InputLabel>
        </GridItem>
        <GridItem xs={5}>
          <CustomToggleButtonGroup
            value={data.targeted_responsive_systems_strengthening || "not yet"}
            id="targeted_responsive_systems_strengthening"
            name="targeted_responsive_systems_strengthening"
            onChange={(value) =>
              handleActivityChange(
                "targeted_responsive_systems_strengthening",
                value
              )
            }
            complete_color_teal={true}
          />
        </GridItem>
      </Grid>
    </GridContainer>
  );
};

export default SupplierActivitySummary;
