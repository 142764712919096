import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";

import ReactTable from "react-table-6";

import Pagination from "components/Pagination/Pagination2.js";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardText from "components/Card/CardText.js";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

import Button from "components/CustomButtons/Button.js";
import Edit from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";

// import SuppliersDropdown from "components/ilmdb/SuppliersDropdown.js"
import { withStyles, makeStyles } from "@material-ui/core/styles";

import SupplierModal from "./SupplierModal";
import SupplierForm from "./SupplierForm";

import {
  batchSupplierBatchUpdate,
  fetchSuppliers,
  mergeSupplierDuplicates,
} from "../../redux/actions/SupplierActions";

import { fetchSupplyChains } from "../../redux/actions/StrategicPartnerActions";

import { fetchCountries } from "../../redux/actions/LocaleActions";

import {
  fetchIndustries,
  fetchSubIndustries,
} from "../../redux/actions/LocaleActions.js";
import { fetchProvinces } from "../../redux/actions/LocaleActions";
import { fetchDistricts } from "../../redux/actions/LocaleActions";

import Utils from "services/utils.js";

import loginStore from "../../redux/stores/LoginStore";
import LinearProgress from "@material-ui/core/LinearProgress";
import { CSVLink } from "react-csv";
import StrategicPartnersDropdown from "components/ilmdb/StrategicPartnersDropdown.js";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import HttpService from "../../services/HttpService";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import { MenuProps, options, CountryName } from "./utils";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";

let _user = loginStore.getLoginUser();
if (_user) {
  var _group = _user && _user.groups.includes("CSO");
}

const customStyle = {
  ...customCheckboxRadioSwitch,
  ...sweetAlertStyles,
  customButton: {
    padding: 0,
    margin: 0,
  },
  checkRoot: {
    padding: 0,
  },
  labelRoot: {
    margin: 0,
  },
};

const useStyles = makeStyles(customStyle);

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

export default function SupplierList(props, context) {
  const dispatch = useDispatch();

  const classes = useStyles();
  const industries = useSelector((state) => state.industriesReducer.items);
  const fetchingIndustries = useSelector(
    (state) => state.industriesReducer.fetchingIndustries
  );

  const subindustries = useSelector(
    (state) => state.subIndustriesReducer.items
  );
  const fetchingSubIndustries = useSelector(
    (state) => state.subIndustriesReducer.fetchingSubIndustries
  );

  const countries = useSelector((state) => state.countriesReducer.items);
  const fetchingCountries = useSelector(
    (state) => state.countriesReducer.fetchingCountries
  );

  const provinces = useSelector((state) => state.provincesReducer.items);
  const fetchingProvinces = useSelector(
    (state) => state.provincesReducer.fetchingProvinces
  );

  const districts = useSelector((state) => state.districtsReducer.items);
  const fetchingDistricts = useSelector(
    (state) => state.districtsReducer.fetchingDistricts
  );

  const all_suppliers = useSelector((state) => state.suppliersReducer.items);
  const suppliersMap = useSelector(
    (state) => state.suppliersReducer.suppliers_items_map
  );
  const fetchingSuppliers = useSelector(
    (state) => state.suppliersReducer.fetchingSuppliers
  );

  const supplyChains = useSelector((state) => state.supplyChainReducer.items);

  const [suppliersArray, setSuppliersArray] = useState([]);

  const [checked, setChecked] = React.useState(false);

  const [checkedCountry, setCheckedCountry] = React.useState(false);
  const [suppliers, setSuppliers] = useState([]);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const handleChangeCountry = (event) => {
    setCheckedCountry(event.target.checked);
  };
  // -------------------------------
  //    DATA CLEANING MODE START
  // -------------------------------

  // useEffect(() => {
  //   // if (all_suppliers && all_suppliers.length !== 0) {
  //     setSuppliers(useSelector(state => state.suppliersReducer.items))
  //   // }
  // },[useSelector(state => state.suppliersReducer.items)])

  const currentUser = loginStore.getLoginUser();

  const [dataCleaningMode, setDataCleaningMode] = useState(false);
  const [duplicatesMarked, setDuplicatesMarked] = useState([]);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [mergeAlert, setMergeAlert] = useState(null);

  const mergeSuccess = () => {
    setDuplicatesMarked([]);
  };

  const mergeError = (error) => {
    setMergeAlert(
      <SweetAlert
        danger
        onConfirm={() => setMergeAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        title={context.t("Error")}
      >
        {error.message}
      </SweetAlert>
    );
  };

  const handleMergeDuplicates = () => {
    console.log("Merging ,", duplicatesMarked);
    setConfirmationModal(false);
    dispatch(
      mergeSupplierDuplicates(duplicatesMarked, mergeSuccess, mergeError)
    );
  };

  const handleSelectDuplicate = (supplier_id) => {
    const newSelectedDuplicates = [...duplicatesMarked];
    const currentIndex = duplicatesMarked.indexOf(supplier_id);

    if (currentIndex === -1) {
      newSelectedDuplicates.push(supplier_id);
    } else {
      newSelectedDuplicates.splice(currentIndex, 1);
    }
    setDuplicatesMarked(newSelectedDuplicates);
  };

  const checkbox = (supplier_id) => {
    return (
      <div>
        <FormControlLabel
          control={
            <Checkbox
              onClick={() => handleSelectDuplicate(supplier_id)}
              checked={duplicatesMarked.includes(supplier_id)}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{ checked: classes.checked, root: classes.checkRoot }}
            />
          }
          label={duplicatesMarked[0] === supplier_id ? <h4>*</h4> : ""}
          classes={{ root: classes.labelRoot }}
        />
      </div>
    );
  };

  const handleAnonymousClick = () => {
    let suppliersUpdated = [];

    if (duplicatesMarked && duplicatesMarked.length > 0) {
      duplicatesMarked.forEach((supplier) => {
        let supplierUpdate = { ...suppliersMap[supplier] };
        supplierUpdate.anonymous = !supplierUpdate.anonymous;
        suppliersUpdated.push(supplierUpdate);
      });
    }

    dispatch(
      batchSupplierBatchUpdate(
        suppliersUpdated,
        () => {
          setMergeAlert(
            <SweetAlert
              success
              title={context.t("Supplier Updates")}
              onConfirm={() => {
                setMergeAlert(null);
                dispatch(fetchSuppliers());
              }}
              confirmBtnCssClass={classes.button + " " + classes.info}
            >
              {context.t(
                "You have successfully updated suppliers anonymous flags"
              )}
            </SweetAlert>
          );
        },
        (error) => {
          setMergeAlert(
            <SweetAlert
              error
              title={context.t("Supplier Updates")}
              onConfirm={() => {
                setMergeAlert(null);
              }}
              confirmBtnCssClass={classes.button + " " + classes.info}
            >
              {context.t("Error Occured:") + JSON.stringify(error)}
            </SweetAlert>
          );
        }
      )
    );
  };

  // -------------------------------
  //     DATA CLEANING MODE END
  // -------------------------------

  const [showFormInline, setShowFormInline] = useState({
    open: false,
    id: null,
  });
  const [modal, setModal] = useState({
    open: false,
    id: null,
  });

  // FILTERS
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [selectedStrategicPartners, setSelectedStrategicPartners] = useState(
    []
  );
  const [selectedCountry, setSelectedCountry] = useState("");

  const [selectedSupplierName, setSelectedSupplierName] = useState("");
  const [selectedSupplier, setSelectedSupplier] = useState(null);

  const [selected, setSelected] = useState([]);

  useEffect(() => {
    const nameParam = selectedSupplierName
      ? `&name=${selectedSupplierName}`
      : "";
    const countryParam = selectedCountry
      ? `&country_worker=${CountryName(selectedCountry)}`
      : "";

    HttpService.get(
      `${process.env.REACT_APP_API_URL}/all_suppliers/?${nameParam}${countryParam}`,
      (response) => {
        setSuppliers(response.results);
      },
      (err) => {
        console.log(err.message);
      }
    );
  }, [
    selectedCountry,
    // selectedSupplierName,
    selectedStrategicPartners == null,
  ]);

  useEffect(() => {
    // Fetch countries
    dispatch(fetchCountries());
    dispatch(fetchProvinces());
    dispatch(fetchDistricts());
    dispatch(fetchSupplyChains());
    dispatch(fetchIndustries());
    dispatch(fetchSubIndustries());
    dispatch(fetchSuppliers());
  }, []);

  useEffect(() => {
    if (suppliers.length > 0) {
      onSearchSuppliers();
    }
  }, [
    suppliers,
    selectedSuppliers,
    industries,
    subindustries,
    countries,
    supplyChains,
  ]);

  useEffect(() => {
    let selectedSuppliers = [];
    if (
      Array.isArray(selectedStrategicPartners) &&
      Array.isArray(supplyChains) &&
      supplyChains.length > 0
    ) {
      supplyChains.map((sc) => {
        if (
          selectedStrategicPartners.includes(parseInt(sc.strategic_partner))
        ) {
          if (Array.isArray(sc.suppliers) && sc.suppliers.length > 0) {
            sc.suppliers.map((supplier) => {
              selectedSuppliers.push(supplier.supplier);
            });
          }
        }
      });
      const uniqueSuppliers = Array.from(new Set(selectedSuppliers));
      setSelectedSuppliers(uniqueSuppliers);
    }

    all_suppliers.map((itemIter) => {
      let newItem = { ...itemIter };

      // Filter by strategic partner
      if (selectedSuppliers.length > 0) {
        if (!selectedSuppliers.includes(newItem.id)) {
          return; // skip
        }
      }
    });
    setSuppliers(all_suppliers);
  }, [selectedStrategicPartners]);

  const redirectToEditSupplier = (id) => {
    if (props.inlinePanel === true) {
      setShowFormInline({ open: true, id: id });
    } else {
      props.history.push(`/admin/edit-supplier?id=${id}`);
    }
  };

  const handleViewButtonClick = (id) => {
    setModal({
      open: true,
      id: id,
    });
  };

  const editButton = (id) => {
    return (
      <Button
        title={context.t("Edit:") + id}
        simple
        color="success"
        value={id}
        className={classes.customButton}
        onClick={(e) => redirectToEditSupplier(e.currentTarget.value)}
      >
        <Edit />
      </Button>
    );
  };

  const viewButton = (id) => {
    return (
      <Button
        title={context.t("View:") + id}
        simple
        color="info"
        value={id}
        className={classes.customButton}
        onClick={(e) => handleViewButtonClick(e.currentTarget.value)}
      >
        <VisibilityIcon />
      </Button>
    );
  };

  const onSearchSuppliers = () => {
    const filteredSuppliersArray = [];

    suppliers.map((itemIter) => {
      let newItem = { ...itemIter };

      // Filter by strategic partner
      if (selectedSuppliers.length > 0) {
        if (!selectedSuppliers.includes(newItem.id)) {
          return; // skip
        }
      }

      newItem["industry_name"] = industries
        ? ((props) => {
            const industry = industries.find((item) => {
              return item.id === props;
            });
            return industry ? industry.name : "-";
          })(
            newItem.industries && newItem.industries.length > 0
              ? newItem.industries[0]
              : -1
          )
        : "-";

      newItem["subindustry_name"] = subindustries
        ? ((props) => {
            const subindustry = subindustries.find((item) => {
              return item.id === props;
            });
            return subindustry ? subindustry.name : "-";
          })(
            newItem.subindustries && newItem.subindustries.length > 0
              ? newItem.subindustries[0]
              : -1
          )
        : "-";

      newItem["country_name"] = countries
        ? ((props) => {
            const country = countries.find((item) => {
              return item.id === props;
            });
            return country ? country.name : "-";
          })(newItem.country)
        : "-";

      newItem["province_name"] = provinces
        ? ((props) => {
            if (provinces && Array.isArray(provinces) && provinces.length > 0) {
              const province = provinces.find((item) => {
                return item.id === props;
              });
              return province ? province.name : "-";
            } else {
              return "-";
            }
          })(newItem.province)
        : "-";

      newItem["district_name"] = districts
        ? ((props) => {
            const district = districts.find((item) => {
              return item.id === props;
            });
            return district ? district.name : "-";
          })(newItem.district)
        : "-";

      newItem["supply_chains"] =
        Utils.isEmpty(supplyChains) === false
          ? (() => {
              let supplyChainNames = [];
              supplyChains.forEach((supplyChain) => {
                supplyChainNames.includes(supplyChain.name) === false &&
                  supplyChain.suppliers &&
                  supplyChain.suppliers.forEach((supplier, index) => {
                    if (newItem.id === supplier.supplier) {
                      if (supplyChain.suppliers[index].status == "active")
                        supplyChainNames.push(supplyChain.name);
                    }
                  });
              });

              return "" + supplyChainNames;
            })()
          : "-";

      newItem["edit"] = editButton(newItem.id);
      newItem["view"] = viewButton(newItem.id);

      filteredSuppliersArray.push(newItem);
    });
    setSuppliersArray(filteredSuppliersArray);
  };

  let csvData = suppliersArray;

  const header = [
    { label: context.t("ID"), key: "id" },
    { label: context.t("Supplier Name"), key: "name" },
    { label: context.t("Anonymous"), key: "anonymous" },
    { label: context.t("website"), key: "website" },
    { label: context.t("Company Description"), key: "description" },
    { label: context.t("Created"), key: "created" },
    { label: context.t("Country"), key: "country_name" },
    { label: context.t("Address"), key: "address" },
    { label: context.t("Province"), key: "province_name" },
    { label: context.t("District"), key: "district_name" },
    { label: context.t("Zipcode"), key: "zipcode" },
    { label: context.t("Industry"), key: "industry_name" },
    { label: context.t("Sub Industry"), key: "subindustry_name" },
    { label: context.t("Contact Name"), key: "contact_name" },
    { label: context.t("Contact Phone"), key: "contact_phone" },
    { label: context.t("Contact Email"), key: "contact_email" },
    { label: context.t("Vessel Number"), key: "vessel_number" },
    // { label: context.t("Tier ID"), key: 'tier_id' },
    {
      label: context.t("Cambodian Workers"),
      key: "total_num_cambodian_workers",
    },
    { label: context.t("Lao Workers"), key: "total_num_lao_workers" },
    { label: context.t("Myanmar Workers"), key: "total_num_myanmar_workers" },
    { label: context.t("Thai Workers"), key: "total_num_thai_workers" },
    {
      label: context.t("Vietnamese Workers"),
      key: "total_num_vietnamese_workers",
    },
    { label: context.t("Total Workers"), key: "total_num_workers" },
    { label: context.t("Vessel Number"), key: "vessel_number" },
    { label: context.t("Supply Chains Name"), key: "supply_chains" },
    { label: context.t("Lat"), key: "lat" },
    { label: context.t("Lng"), key: "lng" },
  ];

  const excelFile = {
    filename: context.t("supply chain data"),
    headers: header,
    data: csvData,
  };

  if (
    Utils.isEmpty(suppliers) === true ||
    Utils.isEmpty(industries) === true ||
    Utils.isEmpty(subindustries) === true ||
    Utils.isEmpty(countries) === true ||
    Utils.isEmpty(supplyChains) === true
  ) {
    return <LinearProgress />;
  }

  const handleTypeSupplier = (event) => {
    setSelectedSupplierName(event.target.value);
  };

  // const selectCountry = (event) => {
  //   setSelectedCountry(event.target.value)
  // }

  const handleSelectSupplier = (event, value) => {
    if (value !== null) {
      setSuppliers([value]);
      setSelectedSupplier(value.id);
    }
  };

  // const workerCountry = (country) => {
  //   let name = ""
  //   if (country && country !== undefined && country !== null) {
  //     country.split("_")
  //     for (let index = 2; index < country.split("_").length; index++) {
  //       name = name + country.split("_")[index].charAt(0).toUpperCase() + country.split("_")[index].slice(1) + " "
  //     }
  //   }
  //   return name
  // }

  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state).slice(0, 100);
  };

  const selectMultipleCountry = (event) => {
    setSelected(event.target.value);
    setSelectedCountry(event.target.value[0]);
  };

  return props.inlinePanel === true && showFormInline.open === true ? (
    <SupplierForm
      supplierId={showFormInline.id}
      handleConfirmSuccessAlert={() => {
        dispatch(fetchSuppliers());
        setShowFormInline({ open: false, id: null });
      }}
    />
  ) : (
    <GridContainer>
      <SupplierModal
        open={modal.open}
        value={modal.id}
        onClose={() => setModal({ ...modal, open: false })}
      />
      <GridItem xs={12}>
        <FormGroup row>
          {_group !== true ? (
            <FormControlLabel
              style={{ color: "black", marginTop: "-20px" }}
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleChange}
                  name="checkedB"
                  color="primary"
                />
              }
              label={context.t("Search by Strategic Partners")}
            />
          ) : null}

          <FormControlLabel
            style={{ color: "black", marginTop: "-20px" }}
            control={
              <Checkbox
                checked={checkedCountry}
                onChange={handleChangeCountry}
                name="checkedB"
                color="primary"
              />
            }
            label={context.t("Search by worker Nationality")}
          />
        </FormGroup>
      </GridItem>

      {_group !== true ? (
        checked ? (
          <GridItem xs={12}>
            <Card style={{ marginTop: 0 }}>
              <CardText style={{ color: "white" }}>
                {context.t("Filter by Strategic Partners")}
              </CardText>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12}>
                    <StrategicPartnersDropdown
                      value={selectedStrategicPartners}
                      onSelect={(strategicPartners) =>
                        setSelectedStrategicPartners(strategicPartners)
                      }
                      multipleselect={true}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        ) : (
          <GridItem xs={12}>
            <Card style={{ marginTop: 0 }}>
              <CardText style={{ color: "white" }}>
                {context.t("Filter by Suppliers")}
              </CardText>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12}>
                    {/* <SuppliersDropdown
                      value={selectedSuppliers}
                      onSelect={suppliers => setSelectedSuppliers(suppliers)}
                      supplierFilterParams={[setSuppliersArray, selectedStrategicPartners, suppliers, suppliersArray2]}
                      multipleselect={true}
                    /> */}

                    <Autocomplete
                      id="combo-box-demo"
                      options={suppliers ? suppliers : null}
                      getOptionLabel={(option) => option.name}
                      style={{ width: "100%", backgroundColor: "#fff" }}
                      onChange={handleSelectSupplier}
                      filterOptions={filterOptions}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            label="Suppliers"
                            variant="outlined"
                            limit={100}
                          />
                        );
                      }}
                      onKeyDown={handleTypeSupplier}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        )
      ) : null}

      {checkedCountry ? (
        <GridItem xs={12}>
          <Card style={{ marginTop: 0 }}>
            <CardText style={{ color: "white" }}>
              {context.t("Search by worker Nationality")}
            </CardText>
            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    style={{ width: "100%", paddingRight: "12px" }}
                  >
                    <InputLabel id="mutiple-select-label">
                      {context.t("Worker Nationality")}
                    </InputLabel>
                    <Select
                      labelId="mutiple-select-label"
                      multiple
                      value={selected}
                      onChange={selectMultipleCountry}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      {options.map((option) => (
                        <MenuItem
                          key={option.ContryName}
                          value={option.Value}
                          id=""
                        >
                          <ListItemText primary={option.ContryName} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      ) : null}
      {_user !== null && _user.groups.includes("directors") ? (
        <GridItem xs={12}>
          <CSVLink {...excelFile} style={{ color: "white" }}>
            <Button>{context.t("Download")}</Button>
          </CSVLink>
        </GridItem>
      ) : null}

      <GridItem xs={12}>
        <CardHeader style={{ padding: 0 }}>
          <GridContainer justifyContent="space-between">
            <GridItem>
              <h4>
                {context.t("Search Results")}({context.t("Found:")}{" "}
                {suppliersArray !== undefined &&
                suppliersArray.length !== undefined
                  ? suppliersArray.length
                  : 0}
                )
              </h4>
            </GridItem>
            {currentUser &&
              currentUser.groups &&
              currentUser.groups.includes("Data Cleaning") && (
                <GridItem>
                  <Button
                    simple
                    color="rose"
                    onClick={() => setDataCleaningMode(!dataCleaningMode)}
                  >
                    {`${
                      dataCleaningMode
                        ? context.t("Disable")
                        : context.t("Enable")
                    } ${context.t("cleaning mode")}`}
                  </Button>
                </GridItem>
              )}
          </GridContainer>
        </CardHeader>
        <Card style={{ marginTop: 0 }}>
          <CardBody>
            <ReactTable
              PaginationComponent={Pagination}
              defaultFilterMethod={(filter, row) =>
                Utils.findStringInObjectFields([row._original], filter.value, [
                  filter.id,
                ]).length > 0
              }
              filterable={true}
              data={suppliersArray}
              // defaultSorted={[{
              //   id: 'name',
              // }]}
              columns={[
                {
                  Header: "",
                  show: dataCleaningMode,
                  sortable: false,
                  accessor: "id",
                  width: 40,
                  Cell: (props) => checkbox(props.value),
                },
                {
                  Header: context.t("Name"),
                  accessor: "name",
                  filterable: true,
                  width: 200,
                  Cell: (props) => {
                    return (
                      <HtmlTooltip title={props.value} interactive>
                        <div className="cell-overflow">
                          {Utils.shortenString(props.value, 120)}
                        </div>
                      </HtmlTooltip>
                    );
                  },
                },
                {
                  Header: context.t("Anonymous"),
                  accessor: "anonymous",
                  show: _group === true ? false : true,
                  filterable: true,
                  sortable: true,
                  width: 120,
                  Cell: (props) => {
                    return props.value === false ? "False" : "True";
                  },
                },
                {
                  Header: context.t("Industry"),
                  accessor: "industry_name",
                  width: 200,
                  filterable: true,
                  Cell: (props) => {
                    return (
                      <HtmlTooltip title={props.value} interactive>
                        <div className="cell-overflow">
                          {Utils.shortenString(props.value, 120)}
                        </div>
                      </HtmlTooltip>
                    );
                  },
                },
                {
                  Header: context.t("Sub Industry"),
                  accessor: "subindustry_name",
                  width: 200,
                  filterable: true,
                  Cell: (props) => {
                    return (
                      <HtmlTooltip title={props.value} interactive>
                        <div className="cell-overflow">
                          {Utils.shortenString(props.value, 120)}
                        </div>
                      </HtmlTooltip>
                    );
                  },
                },
                {
                  Header: context.t("Country"),
                  accessor: "country_name",
                  width: 200,
                  filterable: true,
                  Cell: (props) => {
                    return (
                      <HtmlTooltip title={props.value} interactive>
                        <div className="cell-overflow">
                          {Utils.shortenString(props.value, 80)}
                        </div>
                      </HtmlTooltip>
                    );
                  },
                },
                {
                  Header: context.t("Province"),
                  accessor: "province_name",
                  width: 200,
                  filterable: true,
                  Cell: (props) => {
                    return (
                      <HtmlTooltip title={props.value} interactive>
                        <div className="cell-overflow">
                          {Utils.shortenString(props.value, 80)}
                        </div>
                      </HtmlTooltip>
                    );
                  },
                },
                {
                  Header: context.t("District"),
                  accessor: "district_name",
                  width: 200,
                  filterable: true,
                  Cell: (props) => {
                    return (
                      <HtmlTooltip title={props.value} interactive>
                        <div className="cell-overflow">
                          {Utils.shortenString(props.value, 80)}
                        </div>
                      </HtmlTooltip>
                    );
                  },
                },

                {
                  Header: context.t("Active Supply Chains"),
                  accessor: "supply_chains",
                  show: _group === true ? false : true,
                  width: 300,
                  filterable: true,
                  Cell: (props) => {
                    return (
                      <HtmlTooltip title={props.value} interactive>
                        <div className="cell-overflow">{props.value}</div>
                      </HtmlTooltip>
                    );
                  },
                },
                {
                  Header: selected.includes("Thailand")
                    ? context.t("Thailand")
                    : "",
                  accessor: selected.includes("Thailand")
                    ? context.t("total_num_thai_workers")
                    : null,
                  show: selected.includes("Thailand")
                    ? context.t("total_num_thai_workers")
                    : dataCleaningMode,
                  width: 100,
                  filterable: true,
                  Cell: (props) => {
                    return (
                      <HtmlTooltip title={props.value} interactive>
                        <div className="cell-overflow">{props.value}</div>
                      </HtmlTooltip>
                    );
                  },
                },

                {
                  Header: selected.includes("Cambodia")
                    ? context.t("Cambodia")
                    : "",
                  accessor: selected.includes("Cambodia")
                    ? context.t("total_num_cambodian_workers")
                    : null,
                  show: selected.includes("Cambodia")
                    ? context.t("total_num_cambodian_workers")
                    : dataCleaningMode,
                  width: 100,
                  filterable: true,
                  Cell: (props) => {
                    return (
                      <HtmlTooltip title={props.value} interactive>
                        <div className="cell-overflow">{props.value}</div>
                      </HtmlTooltip>
                    );
                  },
                },

                {
                  Header: selected.includes("Myanmar")
                    ? context.t("Myanmar")
                    : "",
                  accessor: selected.includes("Myanmar")
                    ? context.t("total_num_thai_workers")
                    : null,
                  show: selected.includes("Myanmar")
                    ? context.t("total_num_thai_workers")
                    : dataCleaningMode,
                  width: 100,
                  filterable: true,
                  Cell: (props) => {
                    return (
                      <HtmlTooltip title={props.value} interactive>
                        <div className="cell-overflow">{props.value}</div>
                      </HtmlTooltip>
                    );
                  },
                },

                {
                  Header: selected.includes("Laos") ? context.t("Laos") : "",
                  accessor: selected.includes("Laos")
                    ? context.t("total_num_lao_workers")
                    : null,
                  show: selected.includes("Laos")
                    ? context.t("total_num_lao_workers")
                    : dataCleaningMode,
                  width: 100,
                  filterable: true,
                  Cell: (props) => {
                    return (
                      <HtmlTooltip title={props.value} interactive>
                        <div className="cell-overflow">{props.value}</div>
                      </HtmlTooltip>
                    );
                  },
                },

                {
                  Header: selected.includes("Vietnam")
                    ? context.t("Vietnam")
                    : "",
                  accessor: selected.includes("Vietnam")
                    ? context.t("total_num_vietnamese_workers")
                    : null,
                  show: selected.includes("Vietnam")
                    ? context.t("total_num_vietnamese_workers")
                    : dataCleaningMode,
                  width: 100,
                  filterable: true,
                  Cell: (props) => {
                    return (
                      <HtmlTooltip title={props.value} interactive>
                        <div className="cell-overflow">{props.value}</div>
                      </HtmlTooltip>
                    );
                  },
                },

                {
                  Header: selected.includes("Nepal") ? context.t("Nepal") : "",
                  accessor: selected.includes("Nepal")
                    ? context.t("total_num_nepalese_workers")
                    : null,
                  show: selected.includes("Nepal")
                    ? context.t("total_num_nepalese_workers")
                    : dataCleaningMode,
                  width: 100,
                  filterable: true,
                  Cell: (props) => {
                    return (
                      <HtmlTooltip title={props.value} interactive>
                        <div className="cell-overflow">{props.value}</div>
                      </HtmlTooltip>
                    );
                  },
                },

                {
                  Header: selected.includes("Bangladesh")
                    ? context.t("Bangladesh")
                    : "",
                  accessor: selected.includes("Bangladesh")
                    ? context.t("total_num_bangladeshi_workers")
                    : null,
                  show: selected.includes("Bangladesh")
                    ? context.t("total_num_bangladeshi_workers")
                    : dataCleaningMode,
                  width: 100,
                  filterable: true,
                  Cell: (props) => {
                    return (
                      <HtmlTooltip title={props.value} interactive>
                        <div className="cell-overflow">{props.value}</div>
                      </HtmlTooltip>
                    );
                  },
                },

                {
                  Header: selected.includes("Indonesia")
                    ? context.t("Indonesia")
                    : "",
                  accessor: selected.includes("Indonesia")
                    ? context.t("total_num_indonesian_workers")
                    : null,
                  show: selected.includes("Indonesia")
                    ? context.t("total_num_indonesian_workers")
                    : dataCleaningMode,
                  width: 100,
                  filterable: true,
                  Cell: (props) => {
                    return (
                      <HtmlTooltip title={props.value} interactive>
                        <div className="cell-overflow">{props.value}</div>
                      </HtmlTooltip>
                    );
                  },
                },
                {
                  Header: selected.includes("India") ? context.t("India") : "",
                  accessor: selected.includes("India")
                    ? context.t("total_num_indian_workers")
                    : null,
                  show: selected.includes("India")
                    ? context.t("total_num_indian_workers")
                    : dataCleaningMode,
                  width: 100,
                  filterable: true,
                  Cell: (props) => {
                    return (
                      <HtmlTooltip title={props.value} interactive>
                        <div className="cell-overflow">{props.value}</div>
                      </HtmlTooltip>
                    );
                  },
                },
                {
                  Header: selected.includes("Pakistan")
                    ? context.t("Pakistan")
                    : "",
                  accessor: selected.includes("Pakistan")
                    ? context.t("total_num_pakistani_workers")
                    : null,
                  show: selected.includes("Pakistan")
                    ? context.t("total_num_pakistani_workers")
                    : dataCleaningMode,
                  width: 100,
                  filterable: true,
                  Cell: (props) => {
                    return (
                      <HtmlTooltip title={props.value} interactive>
                        <div className="cell-overflow">{props.value}</div>
                      </HtmlTooltip>
                    );
                  },
                },
                {
                  Header: selected.includes("Sri Lanka")
                    ? context.t("Sri Lanka")
                    : "",
                  accessor: selected.includes("Sri Lanka")
                    ? context.t("total_num_sri_lankan_workers")
                    : null,
                  show: selected.includes("Sri Lanka")
                    ? context.t("total_num_sri_lankan_workers")
                    : dataCleaningMode,
                  width: 100,
                  filterable: true,
                  Cell: (props) => {
                    return (
                      <HtmlTooltip title={props.value} interactive>
                        <div className="cell-overflow">{props.value}</div>
                      </HtmlTooltip>
                    );
                  },
                },

                {
                  Header: context.t("Lat"),
                  accessor: "lat",
                  width: 80,
                  filterable: true,
                  Cell: (props) => {
                    return (
                      <HtmlTooltip title={props.value} interactive>
                        <div className="cell-overflow">{props.value}</div>
                      </HtmlTooltip>
                    );
                  },
                },
                {
                  Header: context.t("Lng"),
                  accessor: "lng",
                  width: 80,
                  filterable: true,
                  Cell: (props) => {
                    return (
                      <HtmlTooltip title={props.value} interactive>
                        <div className="cell-overflow">{props.value}</div>
                      </HtmlTooltip>
                    );
                  },
                },
                {
                  Header: "",
                  sortable: false,
                  filterable: false,
                  accessor: "view",
                  width: 40,
                },
                {
                  Header: "",
                  show: _group === true ? false : true,
                  sortable: false,
                  filterable: false,
                  accessor: "edit",
                  width: 40,
                },
                {
                  Header: "",
                  width: 20,
                  sortable: false,
                  filterable: false,
                },
              ]}
              defaultPageSize={5}
              showPaginationTop={false}
              showPaginationBottom={true}
              loading={
                fetchingSuppliers ||
                fetchingCountries ||
                fetchingIndustries ||
                fetchingSubIndustries ||
                fetchingProvinces ||
                fetchingDistricts
              }
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
      {dataCleaningMode && duplicatesMarked.length > 1 && (
        <GridItem>
          <Button color="rose" onClick={() => setConfirmationModal(true)}>
            {" "}
            {context.t("Merge duplicates")}{" "}
          </Button>
        </GridItem>
      )}

      {dataCleaningMode && duplicatesMarked.length > 0 && (
        <GridItem>
          <Button
            color="rose"
            onClick={() => {
              handleAnonymousClick();
            }}
          >
            {context.t("Flip Anonymous Flag")}
          </Button>
        </GridItem>
      )}
      {confirmationModal && (
        <SweetAlert
          info
          title=""
          showCancel
          showCloseButton
          confirmBtnCssClass={classes.button + " " + classes.info}
          cancelBtnCssClass={classes.button + " " + classes.default}
          onConfirm={() => handleMergeDuplicates()}
          confirmBtnText={context.t("Confirm")}
          onCancel={() => setConfirmationModal(false)}
        >
          <h5> {context.t("Following suppliers will be merged:")} </h5>
          <ul style={{ textAlign: "left" }}>
            {/* this is original code  */}
            {/* {duplicatesMarked.map((id, key) =>
              <li key={key}> {(id === duplicatesMarked[0]) ? (<h4>(*New Master*) - {suppliers.find(x => x.id === id).name}<br /></h4>) : ("Merge/Removal: " + suppliers.find(x => x.id === id).name)} </li>
            )} */}
            {/* this is modified */}
            {duplicatesMarked.map((id, key) => (
              <li key={key}>
                {id === duplicatesMarked[0] ? (
                  <h4>
                    {context.t("New Master")} -{" "}
                    {suppliers.find((x) => x.id === id).name}
                    <br />
                  </h4>
                ) : (
                  `${context.t("Merge/Removal")}: ${
                    suppliers.find((x) => x.id === id).name
                  }`
                )}
              </li>
            ))}
          </ul>
        </SweetAlert>
      )}
      {mergeAlert}
    </GridContainer>
  );
}

SupplierList.contextTypes = {
  t: PropTypes.func.isRequired,
};
