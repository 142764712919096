import React from "react";
import PropTypes from "prop-types";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons//Save";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import { makeStyles } from "@material-ui/core/styles";
import "./previewStyles.css";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "1.5px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(11, 11, 11),
    height: "80%",
    width: "70%",
    overflowY: "auto",
  },
}));

const PreviewGrievance = ({
  context,
  data,
  open,
  handleSubmit,
  handleModalClose,
  isPreview,
}) => {
  const classes = useStyles();
  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <h2 className="preview-title" id="transition-modal-title">
            {context.t("Supplier Grievance Preview")}
          </h2>

          {/* Tab 2 */}
          <section>
            <h3 className="header">{context.t("Information Collection")}</h3>
            <hr className="main-divider" />
            <GridContainer>
              <GridItem md={9}>
                <span className="preview-question">
                  {context.t(
                    "Please review the information shared in ILM. Do you want to talk to Issara to get more context on the worker-reported issue?"
                  )}
                </span>
              </GridItem>
              <GridItem md={3}>
                <span className="answer">
                  {data.issara_context === "no_need"
                    ? "No Need"
                    : data.issara_context === "wantMoreInfo"
                    ? "Yes, I want more information from my Issara focal"
                    : "Yes, I already reached out to my Issara focal"}
                </span>
              </GridItem>

              <GridItem md={9}>
                <span className="preview-question">
                  {context.t(
                    "Have you checked with the HR team to see if similar worker-reported issues have been filed and/or solved recently?"
                  )}
                </span>
              </GridItem>
              <GridItem md={3}>
                <span className="answer">
                  {data.checked_with_hr === true ? "Yes" : "No"}
                </span>
              </GridItem>

              <GridItem md={9}>
                <span className="preview-question">
                  {context.t(
                    "Is this worker-reported issue a repeat issue, meaning that there might be a root cause that needs to be identified and addressed?"
                  )}
                </span>
              </GridItem>
              <GridItem md={3}>
                <span className="answer">
                  {data.repeat_issue === true ? "Yes" : "No"}
                </span>
              </GridItem>

              <GridItem md={6}>
                <span className="preview-question">
                  {context.t("Additional Notes")}
                </span>
              </GridItem>

              <GridItem md={6}>
                <span className="answer-paragraph">
                  {data.information_collection_notes?.value
                    ? data.information_collection_notes.value
                    : "-"}
                </span>
              </GridItem>
            </GridContainer>
          </section>

          {/* Tab 3 */}
          <section>
            <h3 className="header">
              {context.t("Safeguarding Affected Workers")}
            </h3>
            <hr className="main-divider" />
            <GridContainer>
              <GridItem md={9}>
                <span className="preview-question">
                  {context.t(
                    "Is zero tolerance of retaliation being followed by everyone involved in this case?"
                  )}
                </span>
              </GridItem>
              <GridItem md={3}>
                <span className="answer">
                  {data.zero_tolerance === true ? "Yes" : "No"}
                </span>
              </GridItem>

              <GridItem md={9}>
                <span className="preview-question">
                  {context.t(
                    "Have you reviewed all the possible individuals identified in the worker-reported issue and conducted a risk assessment to ensure that workers remain safe during the duration of the grievance investigation and remediation?"
                  )}
                </span>
              </GridItem>
              <GridItem md={3}>
                <span className="answer">
                  {data.reviewed_individual === true ? "Yes" : "No"}
                </span>
              </GridItem>

              <GridItem md={9}>
                <span className="preview-question">
                  {context.t(
                    "Have you reviewed all the possible individuals that would be involved in the grievance investigation and remediation process, including interpreters, and ensured that there are no conflicts of interest?"
                  )}
                </span>
              </GridItem>
              <GridItem md={3}>
                <span className="answer">
                  {data.involved_in_grievance === true ? "Yes" : "No"}
                </span>
              </GridItem>

              <GridItem md={9}>
                <span className="preview-question">
                  {context.t(
                    "All individuals have received the training ensuring they understand how to prioritize safety, confidentiality, privacy and company protocols for efficient response to worker-reported issues?"
                  )}
                </span>
              </GridItem>
              <GridItem md={3}>
                <span className="answer">
                  {data.received_the_training === true ? "Yes" : "No"}
                </span>
              </GridItem>

              <GridItem md={9}>
                <span className="preview-question">
                  {context.t(
                    "Has a trained and impartial individual reached out to the worker(s) to introduce themselves as the case focal point, inform them that the company is about to launch an investigation into their grievance, specify the start date, and confirm that there are no conflicts of interest within the investigation team?"
                  )}
                </span>
              </GridItem>
              <GridItem md={3}>
                <span className="answer">
                  {data.has_trained_impartial.value === "not_yet"
                    ? "No, not yet, but I will do so"
                    : data.has_trained_impartial.value === "not_necessary"
                    ? "No, I do not think that is necessary"
                    : "Yes"}
                </span>
              </GridItem>

              <GridItem md={6}>
                <span className="preview-question">
                  {context.t("Additional Notes")}
                </span>
              </GridItem>

              <GridItem md={6}>
                <span className="answer-paragraph">
                  {data.safeguarding_notes?.value
                    ? data.safeguarding_notes?.value
                    : "-"}
                </span>
              </GridItem>
            </GridContainer>
          </section>

          {/* Tab 4 */}
          <section>
            <h3 className="header">
              {context.t("Ensuring an Ethical Investigation")}
            </h3>
            <hr className="main-divider" />
            <GridContainer>
              <GridItem md={9}>
                <span className="preview-question">
                  {context.t(
                    "Ethical. Have you reviewed all possible individuals to be involved in the investigation, including interpreters, and ensured that all are fully committed to 'do no harm' and maintaining worker safeguards at all times?"
                  )}
                </span>
              </GridItem>
              <GridItem md={3}>
                <span className="answer">
                  {data.ethical_reviewed.value === "yes"
                    ? "Yes"
                    : data.ethical_reviewed.value === "not_yet"
                    ? "No, not yet, but I will do so"
                    : data.ethical_reviewed.value === "not_necessary"
                    ? "No, I do not think that is necessary"
                    : "-"}
                </span>
              </GridItem>

              <GridItem md={9}>
                <span className="preview-question">
                  {context.t(
                    "Professional. Have you ensured that responsible persons in the grievance process are knowledgeable, qualified, and trained to serve impartially in the grievance process?"
                  )}
                </span>
              </GridItem>
              <GridItem md={3}>
                <span className="answer">
                  {data.professional_ensured.value === "yes"
                    ? "Yes"
                    : data.professional_ensured.value === "not_yet"
                    ? "No, not yet, but I will do so"
                    : data.professional_ensured.value === "not_necessary"
                    ? "No, I do not think that is necessary"
                    : "-"}
                </span>
              </GridItem>

              <GridItem md={9}>
                <span className="preview-question">
                  {context.t(
                    "Impartial. Have you ensured that responsible persons in the grievance process have no biases and can refrain from making snap judgments when hearing the perspectives of workers versus other worksite staff?"
                  )}
                </span>
              </GridItem>
              <GridItem md={3}>
                <span className="answer">
                  {data.impartial_ensured.value === "yes"
                    ? "Yes"
                    : data.impartial_ensured.value === "not_yet"
                    ? "No, not yet, but I will do so"
                    : data.impartial_ensured.value === "not_necessary"
                    ? "No, I do not think that is necessary"
                    : "-"}
                </span>
              </GridItem>

              <GridItem md={9}>
                <span className="preview-question">
                  {context.t(
                    "Inclusive and Accessible. Have you identified all the language, gender, and literacy considerations for effective communication with the worker, and have you communicated with the worker the range of ways they can get in touch with you?"
                  )}
                </span>
              </GridItem>
              <GridItem md={3}>
                <span className="answer">
                  {data.inclusive_and_accessible.value === "yes"
                    ? "Yes"
                    : data.inclusive_and_accessible.value === "not_yet"
                    ? "No, not yet, but I will do so"
                    : data.inclusive_and_accessible.value === "not_necessary"
                    ? "No, I do not think that is necessary"
                    : "-"}
                </span>
              </GridItem>

              <GridItem md={9}>
                <span className="preview-question">
                  {context.t(
                    "Timely and Effective. Have you established responsible timelines and goals for investigation and remediation that take into account both the interests of the business and the worker?"
                  )}
                </span>
              </GridItem>
              <GridItem md={3}>
                <span className="answer">
                  {data.timely_and_effective.value === "yes"
                    ? "Yes"
                    : data.timely_and_effective.value === "not_yet"
                    ? "No, not yet, but I will do so"
                    : data.timely_and_effective.value === "not_necessary"
                    ? "No, I do not think that is necessary"
                    : "-"}
                </span>
              </GridItem>

              <GridItem md={6}>
                <span className="preview-question">
                  {context.t("Additional Notes")}
                </span>
              </GridItem>

              <GridItem md={6}>
                <span className="answer-paragraph">
                  {data.ethical_investigation_notes?.value
                    ? data.ethical_investigation_notes?.value
                    : "-"}
                </span>
              </GridItem>
            </GridContainer>
          </section>

          {/* Tab 5 */}
          <section>
            <h3 className="header">
              {context.t("Communicating with the workers")}
            </h3>
            <hr className="main-divider" />

            <GridContainer>
              <GridItem md={9}>
                <span className="preview-question">
                  {context.t(
                    "Throughout the investigation and upon its conclusion, have you stayed in touch with the worker to check on their well-being and whether the investigation has had any positive or negative impacts on them?"
                  )}
                </span>
              </GridItem>
              <GridItem md={3}>
                <span className="answer">
                  {data.investigation_their_wellbeing.value === "yes"
                    ? "Yes"
                    : data.investigation_their_wellbeing.value === "not_yet"
                    ? "No, not yet, but I will do so"
                    : data.investigation_their_wellbeing.value ===
                      "not_necessary"
                    ? "No, I do not think that is necessary"
                    : "-"}
                </span>
              </GridItem>

              <GridItem md={9}>
                <span className="preview-question">
                  {context.t(
                    "Do you need help with any more complex consequences arising from the worker-reported issue, such as psychosocial support for the worker, the need to quickly repatriate a worker, etc.?"
                  )}
                </span>
              </GridItem>
              <GridItem md={3}>
                <span className="answer">
                  {data.need_help_with_complex_consequences === true
                    ? "Yes"
                    : data.need_help_with_complex_consequences === false
                    ? "No"
                    : "-"}
                </span>
              </GridItem>

              <GridItem md={6}>
                <span className="preview-question">
                  {context.t("Additional Notes")}
                </span>
              </GridItem>

              <GridItem md={6}>
                <span className="answer-paragraph">
                  {data.communication_notes?.value
                    ? data.communication_notes?.value
                    : "-"}
                </span>
              </GridItem>
            </GridContainer>
          </section>

          {/* Tab 6 */}
          <section>
            <h3 className="header">
              {context.t("Monitoring and Communication")}
            </h3>
            <hr className="main-divider" />

            <GridContainer>
              <GridItem md={6}>
                <span className="preview-question">
                  {context.t("Remediation")}
                </span>
              </GridItem>
              <GridItem md={6}>
                <span className="answer-paragraph">
                  {data.remediation ? data.remediation.description : "-"}
                </span>
              </GridItem>

              <GridItem md={6}>
                <span className="preview-question">
                  {context.t(
                    "Systems Strengthening and Continuous Improvement"
                  )}
                </span>
              </GridItem>
              <GridItem md={6}>
                <span className="answer-paragraph">
                  {data.system_strengthening
                    ? data.system_strengthening.description
                    : "-"}
                </span>
              </GridItem>
            </GridContainer>
          </section>

          {/* Trainings */}
          {data.list_of_text_fields?.remediations.length === 0 ? (
            <section>
              <h3 className="header">{context.t("Trainings")}</h3>
              <hr className="main-divider" />
              <span className="preview-question">
                <ul>
                  {data.trainings.map((training, index) => (
                    <li key={index}>{training}</li>
                  ))}
                </ul>
              </span>
            </section>
          ) : (
            <section></section>
          )}

          <GridContainer justifyContent="flex-end">
            <GridItem>
              <Button onClick={handleModalClose}>
                {isPreview ? (
                  <CloseIcon className="edit-icon" />
                ) : (
                  <EditIcon className="edit-icon" />
                )}
                {context.t(isPreview ? "Close" : "Go back to edit")}
              </Button>
              {isPreview ? null : (
                <Button color="rose" onClick={() => handleSubmit(false)}>
                  <SaveIcon className="save-icon" />
                  {context.t("Submit")}
                </Button>
              )}
            </GridItem>
          </GridContainer>
        </div>
      </Fade>
    </Modal>
  );
};

PreviewGrievance.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default PreviewGrievance;
