import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "../../CustomInput/CustomInput";
import PrevTextDataComponent from "./PrevTextDataComponent";
import "./tabStyles.css";

const SafeguardingAffectedWorkers = ({
  context,
  data,
  onDataChange,
  handleOnClick,
}) => {
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "has_trained_impartial" || name === "describe_hasTrained") {
      onDataChange({
        ["has_trained_impartial"]: {
          value:
            name === "has_trained_impartial"
              ? value
              : data.has_trained_impartial.value,
          description:
            name === "describe_hasTrained"
              ? value
              : data.has_trained_impartial.description,
        },
      });
      return;
    } else if (name === "safeguarding_notes") {
      onDataChange({
        [name]: {
          value: value,
        },
      });
      return;
    } else {
      onDataChange({
        [name]: value === "true",
      });
    }
  };

  return (
    <GridContainer>
      <GridItem lg={12}>
        {/* Question 1 */}
        <FormControl component="fieldset">
          <FormLabel component="legend">
            <span className="question">
              {context.t(
                "Is zero tolerance of retaliation being followed by everyone involved in this case?"
              )}
            </span>
          </FormLabel>
          <GridContainer>
            <GridItem xs={3} md={1}>
              <RadioGroup
                aria-labelledby="radio-buttons-group"
                name="zero_tolerance"
                value={String(data.zero_tolerance)}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label={
                    <span className="answer_checkbox">{context.t("Yes")}</span>
                  }
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label={
                    <span className="answer_checkbox">{context.t("No")}</span>
                  }
                />
              </RadioGroup>
            </GridItem>
            <GridItem xs={1} md={1}></GridItem>
            <GridItem xs={8} md={10}>
              {data.zero_tolerance === false ? (
                <Alert
                  variant="outlined"
                  severity="info"
                  style={{ height: "90%" }}
                >
                  <span className="tooltip">
                    {context.t(
                      "The remediation case is at risk. The Issara team will be following up with your company's focal point to discuss how to safeguard all affected workers from retaliation, and build better systems for zero tolerance against retaliation, and build better systems for zero tolerance against retaliation."
                    )}
                  </span>
                </Alert>
              ) : null}
            </GridItem>
          </GridContainer>

          {/* Question 2 */}
          <FormLabel component="legend" className="next_question">
            <span className="question">
              {context.t(
                "Have you reviewed all the possible individuals identified in the worker-reported issue and conducted a risk assessment to ensure that workers remain safe during the duration of the grievance investigation and remediation?"
              )}
            </span>
          </FormLabel>
          <GridContainer>
            <GridItem xs={3} md={1}>
              <RadioGroup
                aria-labelledby="radio-buttons-group"
                name="reviewed_individual"
                value={String(data.reviewed_individual)}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label={
                    <span className="answer_checkbox">{context.t("Yes")}</span>
                  }
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label={
                    <span className="answer_checkbox">{context.t("No")}</span>
                  }
                />
              </RadioGroup>
            </GridItem>
            <GridItem xs={1} md={1}></GridItem>
            <GridItem xs={8} md={10}>
              {data.reviewed_individual === false ? (
                <Alert
                  variant="outlined"
                  severity="info"
                  style={{ height: "90%" }}
                >
                  <span className="tooltip">
                    {context.t(
                      "The remediation case is at risk. The Issara team will be following up with your company's focal point to discuss how to safeguard all affected workers from retaliation, and build better systems for zero tolerance against retaliation."
                    )}
                  </span>
                </Alert>
              ) : null}
            </GridItem>
          </GridContainer>

          {/* Question 3 */}
          <FormLabel component="legend" className="next_question">
            <span className="question">
              {context.t(
                "Have you reviewed all the possible individuals that would be involved in the grievance investigation and remediation process, including interpreters, and ensured that:"
              )}
            </span>
          </FormLabel>
          <span className="sub_text">
            {context.t(
              "Is there any conflict of interest? Please do not proceed until it is certain that there are no relationships or conflicts of interest between grievance response personnel and the individual(s) who are the subjects of the complaint."
            )}
          </span>
          <GridContainer>
            <GridItem xs={3} md={1}>
              <RadioGroup
                aria-labelledby="radio-buttons-group"
                name="involved_in_grievance"
                value={String(data.involved_in_grievance)}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label={
                    <span className="answer_checkbox">{context.t("Yes")}</span>
                  }
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label={
                    <span className="answer_checkbox">{context.t("No")}</span>
                  }
                />
              </RadioGroup>
            </GridItem>
            <GridItem xs={1} md={1}></GridItem>
            <GridItem xs={8} md={10}>
              {data.involved_in_grievance === true ? (
                <Alert
                  variant="outlined"
                  severity="info"
                  style={{
                    height: "75%",
                    wordWrap: "break-word",
                    padding: "10px",
                  }}
                >
                  <span className="tooltip">
                    {context.t(
                      "Your Issara focal has been notified; they will reach out to you soon."
                    )}
                  </span>
                </Alert>
              ) : null}
            </GridItem>
          </GridContainer>

          {/* Question 4 */}
          <FormLabel component="legend" className="next_question">
            <span className="question">
              {context.t(
                "All individuals have received the training ensuring they understand how to prioritize safety, confidentiality, privacy and company protocols for efficient response to worker-reported issues?"
              )}
            </span>
          </FormLabel>
          <RadioGroup
            aria-labelledby="radio-buttons-group"
            name="received_the_training"
            value={String(data.received_the_training)}
            onChange={handleChange}
          >
            <FormControlLabel
              value="true"
              control={<Radio />}
              label={
                <span className="answer_checkbox">{context.t("Yes")}</span>
              }
            />
            <FormControlLabel
              value="false"
              control={<Radio />}
              label={<span className="answer_checkbox">{context.t("No")}</span>}
            />
          </RadioGroup>

          {/* Question 5 */}
          <FormLabel component="legend" className="next_question">
            <span className="question">
              {context.t(
                "Has a trained and impartial individual reached out to the worker(s) to introduce themselves as the case focal point, inform them that the company is about to launch an investigation into their grievance, specify the start date, and confirm that there are no conflicts of interest within the investigation team?"
              )}
            </span>
          </FormLabel>
          <RadioGroup
            aria-labelledby="radio-buttons-group"
            name="has_trained_impartial"
            value={data.has_trained_impartial.value}
            onChange={handleChange}
          >
            <FormControlLabel
              value="yes"
              control={<Radio />}
              label={
                <span className="answer_checkbox">{context.t("Yes")}</span>
              }
            />
            <FormControlLabel
              value="not_yet"
              control={<Radio />}
              label={
                <span className="answer_checkbox">
                  {context.t("No, not yet, but I will do so")}
                </span>
              }
            />
            <FormControlLabel
              value="not_necessary"
              control={<Radio />}
              label={
                <span className="answer_checkbox">
                  {context.t("No, I do not think that is necessary")}
                </span>
              }
            />
            {data.has_trained_impartial.value === "not_necessary" && (
              <CustomInput
                isTextArea={true}
                formControlProps={{ fullWidth: true }}
                labelText={context.t("Please describe why?")}
                name="describe_hasTrained"
                inputProps={{
                  onChange: (e) => handleChange(e),
                }}
                value={data.has_trained_impartial.description}
              />
            )}
          </RadioGroup>

          <FormGroup className="next_question">
            <CustomInput
              isTextArea={true}
              formControlProps={{ fullWidth: true }}
              labelText={context.t("Additional Notes")}
              name="safeguarding_notes"
              inputProps={{
                onChange: (e) => handleChange(e),
              }}
              value={
                data.safeguarding_notes ? data.safeguarding_notes.value : ""
              }
            />

            {/* Prev Data */}
            <PrevTextDataComponent
              context={context}
              data={
                data.list_of_text_fields
                  ? data.list_of_text_fields.safeguarding_notes
                  : []
              }
              text_header_1={"Note: "}
              text_header_2={""}
              is_notes={true}
            />
          </FormGroup>
        </FormControl>
      </GridItem>
      <GridContainer justifyContent="flex-end">
        <GridItem>
          <Button color="rose" onClick={handleOnClick}>
            {context.t("Save as Draft")}
          </Button>
        </GridItem>
      </GridContainer>
    </GridContainer>
  );
};

SafeguardingAffectedWorkers.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default SafeguardingAffectedWorkers;
