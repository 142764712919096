import React from "react";
import Utils from "../../services/utils";
import PropTypes from "prop-types";
import { Box, useTheme, useMediaQuery } from "@material-ui/core";

function IndicatorInfo(props, context) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      display="flex"
      justifyContent="space-around"
      alignItems="center"
      flexDirection={isSmallScreen ? "column" : "row"}
      width="100%"
      padding={2}
      border={1}
      borderColor="grey.30"
      borderRadius={10}
    >
      <Box
        display="flex"
        alignItems="center"
        marginRight={isSmallScreen ? 0 : 2}
        marginBottom={isSmallScreen ? 2 : 0}
        padding={1}
        width={isSmallScreen ? "100%" : "auto"}
        border={1}
        borderColor="grey.500"
        borderRadius={4}
      >
        <Box
          width={20}
          height={20}
          bgcolor={Utils.colorScheme.NOT_YET}
          marginRight={1}
          borderRadius="50%"
          border={"1px solid black"}
        />
        {context.t("Grey = Not yet initiated")}
      </Box>
      <Box
        display="flex"
        alignItems="center"
        marginRight={isSmallScreen ? 0 : 2}
        marginBottom={isSmallScreen ? 2 : 0}
        padding={1}
        width={isSmallScreen ? "100%" : "auto"}
        border={1}
        borderColor="grey.500"
        borderRadius={4}
      >
        <Box
          width={20}
          height={20}
          bgcolor={Utils.colorScheme.PROGRESS}
          marginRight={1}
          borderRadius="50%"
          border={"1px solid black"}
        />
        {context.t("Yellow = Partial progress")}
      </Box>
      <Box
        display="flex"
        alignItems="center"
        padding={1}
        width={isSmallScreen ? "100%" : "auto"}
        border={1}
        borderColor="grey.500"
        borderRadius={4}
      >
        <Box
          width={20}
          height={20}
          bgcolor={Utils.colorScheme.COMPLETE}
          marginRight={1}
          borderRadius="50%"
          border={"1px solid black"}
        />
        {context.t("Green = Completed/ongoing")}
      </Box>
    </Box>
  );
}

export default IndicatorInfo;

IndicatorInfo.contextTypes = {
  t: PropTypes.func.isRequired,
};
