export const ACTIVITY_SECTION_ARRAY = [
  {
    area: "ACTIVITIES FOR AREA 1 - WORKER VOICE",
    activities: [
      {
        section: "Policies, Operational Systems, & Procedures",
        activities: [
          {
            sectionKey: "1.1",
            label: "Worker voice / worker validation policy",
            items: [
              "Establish an ethical grievance mechanisms/worker voice policy",
              "If a policy is already in place, conduct a periodic review to assess and/or strengthening SP's policy on ethical grievance mechanisms",
            ],
          },
          {
            sectionKey: "1.2",
            label: "Operational plans & rollout",
            items: [
              "Facilitate supplier introductions & kick-off meetings",
              "Establish internal escalation protocols around issues raised in ILM, aligning internal Partner compliance/response between worker voice and audit data",
            ],
          },
        ],
      },
      {
        section: "Effective Communications",
        activities: [
          {
            sectionKey: "1.3",
            label:
              "Communications to workers (for Partner manufacturer/processor)",
            items: [
              "Communicate to workers about worker voice channels, grievance mechanisms, and worker surveys",
              "Support Issara-facilitated in-person or online worker outreach (canteen, worker housing, etc.)",
              "Support dissemination of Issara materials (posters, keychains, etc.) in workers’ native languages",
            ],
          },
          {
            sectionKey: "1.4",
            label: "Communications between Global Buyers & Suppliers",
            items: [
              "Communicate directly to Partner’s suppliers regarding worker voice requirements and expectations. Also receive feedback regarding suppliers' understanding of Partner's communications",
              "Discuss with suppliers incentives and disincentives for implementation and cooperation with independent worker voice channels",
            ],
          },
        ],
      },
      {
        section: "System Strengthening",
        activities: [
          {
            sectionKey: "1.5",
            label: "Capacity building: Ethical Grievance Mechanisms",
            items: [
              "Encourage and support supplier and recruitment agency participation in Issara Academy trainings on ethical grievance mechanisms",
              "Encourage and support supplier and/or Partner representation at ILM Action Network-facilitated multi-stakeholder events",
            ],
          },
        ],
      },
    ],
  },
  {
    area: "ACTIVITIES FOR AREA 2 - LABOR RECRUITMENT",
    activities: [
      {
        section: "Policies, Operational Systems, & Procedures",
        activities: [
          {
            sectionKey: "2.1",
            label: "Ethical recruitment policy / roadmap plan",
            items: [
              "Establish ethical recruitment policy and roadmap for implementation",
              "If policy is already in place, conduct a periodic review to review, assess and/or strengthen Partner’s policy on ethical recruitment",
            ],
          },
          {
            sectionKey: "2.2",
            label: "System and Procedures for Ethical Recruitment",
            items: [
              "Support employers in Partner’s supply chain to have comprehensive hiring criteria and advertisements, clear recruitment agency vetting, ethical Management Service Agreements (MSA), and ethical employment contracts with workers",
            ],
          },
        ],
      },
      {
        section: "Effective Communications",
        activities: [
          {
            sectionKey: "2.3",
            label: "Transparency in labor recruitment channels",
            items: [
              "Disclose or support disclosure of suppliers’ recruitment agencies and MSAs, helping to ensure compliance with Partner’s supplier codes of conduct and/or policies",
            ],
          },
          {
            sectionKey: "2.4",
            label: "Communication to suppliers",
            items: [
              "Communicate directly to Partner’s suppliers regarding ethical recruitment policies and requirements, including all key dates and timelines and repayment processes for identified worker-paid fees",
            ],
          },
          {
            sectionKey: "2.5",
            label:
              "Communication of Recruitment Policies to Job Candidates & Workers",
            items: [
              "Encourage and support suppliers in using Golden Dreams for transparent job postings in line with ethical recruitment policies (see 2.10)",
              "Support suppliers working with Issara on pre-departure orientations (PDOs) for migrant workers",
            ],
          },
        ],
      },
      {
        section: "System Strengthening",
        activities: [
          {
            sectionKey: "2.6",
            label: "Recruitment surveys",
            items: [
              "Carry out periodic recruitment surveys at suppliers/worksites to ensure compliance with ethical recruitment policies, recognize good practices, and identify areas for systems strengthening (often in conjunction with worker satisfaction surveys; see 3.5)",
            ],
          },
          {
            sectionKey: "2.7",
            label: "Recruitment fee repayment",
            items: [
              "Support the timely repayment of worker-paid recruitment fees to eligible current and former workers in Partner’s supply chain",
            ],
          },
          {
            sectionKey: "2.8",
            label: "Capacity building: ethical recruitment trainings",
            items: [
              "Encourage and support supplier and recruitment agency participation in Issara Academy trainings on ethical recruitment",
            ],
          },
          {
            sectionKey: "2.10",
            label: "Golden Dreams",
            items: [
              "Support and facilitate the use of Golden Dreams in the labor recruitment processes of Partner’s supply chain, encouraging suppliers and affiliated recruitment agencies to post transparent, ethical jobs on Golden Dreams (see 2.5)",
            ],
          },
        ],
      },
    ],
  },
  {
    area: "ACTIVITIES FOR AREA 3 - WORKING CONDITIONS",
    activities: [
      {
        section: "Policies, Operational Systems, & Procedures",
        activities: [
          {
            sectionKey: "3.1",
            label: "Establish sourcing policy / roadmap plan",
            items: [
              "Establish ethical sourcing policies in conformance with international standards and definitions, and requirments fo rcredible worker voice at all supplier sites - including working and living conditions",
              "If policies already in place, conduct periodic review to assess and/or strengthen Partners policies on ethical sourcing",
            ],
          },
          {
            sectionKey: "3.5",
            label: "Independent worker satisfaction surveys",
            items: [
              "Prepare and roll out periodic independent worker satisfaction surveys ensure compliance with ethical sourcing policies, recognize good practices, and identify areas for systems strengthening (often in conjunction with ethical recruitment surveys; see 2.6)",
            ],
          },
        ],
      },
      {
        section: "Effective Communications",
        activities: [
          {
            sectionKey: "3.3",
            label: "Communication to suppliers",
            items: [
              "Communicate directly to Partner’s suppliers regarding on ethical sourcing policies, including expectations for supplier compliance and transparency, and communication of incentives and disincentives",
            ],
          },
          {
            sectionKey: "3.4",
            label: "Communication to workers",
            items: [
              "Strengthen supplier language and appropriate communication to workers about continuous improvement being made in response to worker feedback, with the goal of transparency",
            ],
          },
        ],
      },
      {
        section: "System Strengthening",
        activities: [
          {
            sectionKey: "3.2",
            label: "Ongoing worker-driven remediation",
            items: [
              "Support the remediation of worker-reported working conditions on an ongoing basis",
            ],
          },
          {
            sectionKey: "3.6",
            label: "Capacity building: HRDD",
            items: [
              "Encourage and support supplier and Partner participation in Issra Academy trainings on HRDD and business & human rights",
            ],
          },
          {
            sectionKey: "3.7",
            label: "Capacity building: interpreters & line supervisors",
            items: [
              "Encourage and support suppliers in having interpreters and line supervisors participate in Issara Academy trainings and understand their role in ethical grievance mechanisms and remediation",
            ],
          },
          {
            sectionKey: "3.8-a",
            label: "Capacity building: management & leadership",
            items: [
              "Encourage and support suppliers in having management and leadership participate in Issara Academy trainings and understand their role in ethical grievance mechanisms and remediation, as well as ongoing coaching and capacity building",
            ],
          },
          {
            sectionKey: "3.8-b",
            label: "Capacity building: management & leadership",
            items: [
              "Conduct iterative analyses and targeted improvements of systems related to management of living/working conditions, including all human resources processes, grievance mechanisms, bonus and incentive systems within production, and dorm and canteen conditions",
            ],
          },
        ],
      },
    ],
  },
];
