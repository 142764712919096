import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import moment from "moment";

const SummaryComponent = ({ data, context, title, header }) => {
  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <span className="old-notes-text">
          <strong>{title}</strong>
        </span>
      </AccordionSummary>
      {data.map((item, index) => {
        return (
          <AccordionDetails key={item.id}>
            <Box
              sx={{
                width: "100%",
                padding: 10,
                border: "1px solid grey",
                borderRadius: "15px",
                backgroundColor: "aliceblue",
              }}
            >
              <Typography
                variant="overline"
                gutterBottom
                style={{ fontStyle: "italic" }}
              >
                {context.t("Updated At ")}
                {moment(item.created_at).format("DD MMM, YYYY HH:mm")}
              </Typography>
              <Typography variant="body2" gutterBottom>
                <span className="text_headers">{header}</span>
                {item.description}
              </Typography>
            </Box>
          </AccordionDetails>
        );
      })}
    </Accordion>
  );
};

SummaryComponent.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default SummaryComponent;
