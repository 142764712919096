import React, { useMemo } from "react";
import Chart from "react-apexcharts";

const DonutChart = (props) => {
  const defaultSize = "60%";
  const defaultWidth = "380";
  const defaultPosition = "bottom";

  const {
    sections,
    type,
    size = defaultSize,
    width = defaultWidth,
    position = defaultPosition,
    colors = ["#a3199c", "#fdc161", "#a6dfdd", "#148696"],
  } = props;

  const total = useMemo(
    () => sections.reduce((acc, section) => acc + section.amount, 0),
    [sections]
  );

  const series = useMemo(
    () => sections.map((section) => (section.amount / total) * 100),
    [sections, total]
  );

  const labels = useMemo(
    () =>
      sections.map(
        (section) => `${section.label}: $${section.amount.toLocaleString()}`
      ),
    [sections]
  );

  const options = useMemo(
    () => ({
      chart: {
        type,
      },
      colors: colors,
      labels,
      plotOptions: {
        pie: {
          donut: {
            size,
          },
        },
      },
      legend: {
        position,
      },
      tooltip: {
        y: {
          formatter: (val) => `${val.toLocaleString()}%`,
        },
      },
    }),
    [type, labels, size, position]
  );

  return (
    <div>
      <Chart options={options} series={series} type={type} width={width} />
    </div>
  );
};

export default DonutChart;
