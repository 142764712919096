// Step 3 of 5. SP Progress Indicators Form
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import "./css/style1.css";
import swal from "@sweetalert/with-react";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import IndicatorInfo from "components/Indicator/IndicatorInfo";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useDispatch } from "react-redux";
import CustomToggleButtonGroup from "./CustomToggleButtonGroup";
import axios from "axios";
import {
  fetchWorkPlanData,
  refreshWorkPlanData,
} from "redux/actions/SpWorkplanFormAction";
import { Divider } from "@material-ui/core";
import Utils from "../../../services/utils.js";

const BottomBtn = {
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: "20px",
};

export default function SPProgressIndicatorsForm(props, context) {
  const dispatch = useDispatch();
  const classes = props.useStyles();
  const [workPlanData, setWorkPlanData] = useState({});
  const [clonedData, setClonedData] = useState([]);
  const [noteAndRemarkData, setNoteAndRemarkData] = useState({
    area: "progress indicators",
  });

  const [clonedNoteAndRemarkData, setClonedNoteAndRemarkData] = useState({
    area: "progress indicators",
  });

  const workPlanItem = useSelector((state) => state.workplanReducer.item);

  const [data, setData] = useState([
    {
      area: "worker_voice",
      str_value:
        "percentage_tier1_suppliers_with_issara_worker_voice_channels_note",
      txt_value:
        "Percentage of tier-1 suppliers (export level) in your supply chain with Issara worker voice channels available to workers",
    },
    {
      area: "worker_voice",
      str_value:
        "percentage_upstream_suppliers_with_issara_worker_voice_channels_note",
      txt_value:
        "Percentage of upstream (tier-2 and beyond) suppliers in your supply chain with Issara worker voice channels available to workers, based on supply chain information provided",
    },
    {
      area: "worker_voice",
      str_value: "covers_requirements_mechanisms_managing_grievances",
      txt_value: "Covers requirements and mechanisms for managing grievances",
      progress_status: "not yet",
    },
    {
      area: "worker_voice",
      str_value: "clear_non_retaliation_policy",
      txt_value: "Includes a clear non-retaliation policy",
      progress_status: "not yet",
    },
    {
      area: "worker_voice",
      str_value: "periodic_review_continuous_improvement",
      txt_value:
        "Undergoes periodic review to identify means for continuous improvement",
      progress_status: "not yet",
    },
    {
      area: "labour_recruitment",
      str_value: "international_standards_definitions",
      txt_value: "In line with international standards and definitions",
      progress_status: "not yet",
    },
    {
      area: "labour_recruitment",
      str_value: "communicated_to_suppliers",
      txt_value: "Clearly communicated to suppliers",
      progress_status: "not yet",
    },
    {
      area: "labour_recruitment",
      str_value: "integrated_commercial_processes",
      txt_value:
        "Integrated into commercial processes such as supplier scorecards and contracts",
      progress_status: "not yet",
    },
    {
      area: "labour_recruitment",
      str_value: "review_msas_employer_supplier_recruitment",
      txt_value:
        "Review of MSAs between employer/suppliers and recruitment agencies",
      progress_status: "not yet",
    },
    {
      area: "labour_recruitment",
      str_value: "review_msas_employer_supplier_recruitment_note",
      txt_value: "review_msas_employer_supplier_recruitment_note",
    },
    {
      area: "labour_recruitment",
      str_value: "communication_disclosure_recruitment_agencies",
      txt_value: "Communications: disclosure of recruitment agencies used",
      progress_status: "not yet",
    },
    {
      area: "labour_recruitment",
      str_value: "communication_disclosure_recruitment_agencies_note",
      txt_value: "communication_disclosure_recruitment_agencies_note",
    },
    {
      area: "labour_recruitment",
      str_value: "recruitment_survey_fees",
      txt_value: "Recruitment System Assessment",
      progress_status: "not yet",
    },
    {
      area: "labour_recruitment",
      str_value: "recruitment_survey_fees_note",
      txt_value: "recruitment_survey_fees_note",
    },
    {
      area: "labour_recruitment",
      str_value: "institutional_capacity_building_training_staff",
      txt_value:
        "Institutional capacity building: ethical recruitment trainings",
      progress_status: "not yet",
    },
    {
      area: "labour_recruitment",
      str_value:
        "institutional_capacity_building_ethical_recruitment_trainings_note",
      txt_value:
        "institutional_capacity_building_ethical_recruitment_trainings_note",
    },
    {
      area: "working_conditions",
      str_value: "worker_satisfaction_surveys",
      txt_value: "Worker satisfaction surveys",
      progress_status: "not yet",
    },
    {
      area: "working_conditions",
      str_value: "worker_satisfaction_surveys_note",
      txt_value: "worker_satisfaction_surveys_note",
    },
    {
      area: "working_conditions",
      str_value:
        "institutional_capacity_building_training_working_technical_staff",
      txt_value:
        "Institutional capacity building: training of working level/technical staff",
      progress_status: "not yet",
    },
    {
      area: "working_conditions",
      str_value:
        "institutional_capacity_building_training_working_technical_staff_note",
      txt_value:
        "institutional_capacity_building_training_working_technical_staff_note",
    },
    {
      area: "working_conditions",
      str_value:
        "institutional_capacity_building_training_management_leadership",
      txt_value:
        "Institutional capacity building: ethical recruitment trainings",
      progress_status: "not yet",
    },
    {
      area: "working_conditions",
      str_value:
        "institutional_capacity_building_training_management_leadership_note",
      txt_value:
        "institutional_capacity_building_training_management_leadership_note",
    },
    {
      area: "working_conditions",
      str_value: "quality_remediation",
      txt_value: "Quality of remediation",
      numeric_value: 1,
      sub_area: "number",
    },
    {
      area: "working_conditions",
      str_value: "timeliness_remediation",
      txt_value: "Timeliness of remediation",
      numeric_value: 1,
      sub_area: "number",
    },
    {
      area: "working_conditions",
      str_value: "openness_to_reform",
      txt_value: "Openness to reform",
      numeric_value: 1,
      sub_area: "number",
    },
    {
      area: "working_conditions",
      str_value: "impact_indicator_2_issue_resolution_60days_percent_note",
      txt_value:
        "IMPACT INDICATOR 2. % of worker-reported issues in the supply chain resolved in under 60 days",
    },
    {
      area: "working_conditions",
      str_value:
        "impact_indicator_3_remediation_efforts_resolution_percent_note",
      txt_value:
        "IMPACT INDICATOR 3. % of remediation efforts of specific issues resolved at a level of 'fair' or better (Fair, Good, or Excellent)",
    },
    {
      area: "working_conditions",
      str_value:
        "impact_indicator_4_eligible_workers_reimbursement_percent_note",
      txt_value:
        "IMPACT INDICATOR 4. % of eligible workers reimbursed for recruitment fees they have paid",
    },
    {
      area: "working_conditions",
      str_value: "impact_indicator_5_reimbursement_percent_note",
      txt_value:
        "IMPACT INDICATOR 5. % Amount of money owed versus amount of money reimbursed to workers for recruitment fees that they have paid",
    },
  ]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      !workPlanItem.loading &&
        !workPlanItem.fetched &&
        dispatch(fetchWorkPlanData());
    }

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (workPlanData && workPlanData.id) {
      const updatedData = data.map((item) => ({
        ...item,
        workplan: workPlanData?.id,
      }));
      setData(Utils.removeDuplicates([...data, ...updatedData]));
      setNoteAndRemarkData({
        ...noteAndRemarkData,
        workplan: workPlanData.id,
      });
    }
  }, [workPlanData]);

  useEffect(() => {
    if (workPlanItem.fetched) {
      let workPlanData = workPlanItem.data;

      if (workPlanData && workPlanData?.progress_indicator?.length > 0) {
        setData(workPlanData.progress_indicator);
        setClonedData(workPlanData.progress_indicator);
      }

      if (workPlanData && workPlanData?.id) {
        setNoteAndRemarkData({
          ...noteAndRemarkData,
          ...workPlanData.notes_remark[0],
        });
        setClonedNoteAndRemarkData({
          ...noteAndRemarkData,
          ...workPlanData.notes_remark[0],
        });
      }
      setWorkPlanData(workPlanData);
    }
  }, [workPlanItem]);

  const handleSave = () => {
    if (
      data &&
      Array.isArray(data) &&
      data.length > 0 &&
      workPlanData &&
      workPlanData.id
    ) {
      const requests = data.map((item) =>
        axios.post(
          `${process.env.REACT_APP_API_URL}/sp-workplan-progress-indicators/`,
          item
        )
      );

      Promise.all(requests)
        .then(() => {
          if (dispatch(refreshWorkPlanData())) {
            swal({
              text: context.t("SP Progress Indicators Data saved successfully"),
              buttons: false,
              timer: 2000,
            });
          }
        })
        .catch((error) => {
          swal({
            text: context.t(
              "An error occurred while saving SP Progress Indicators data"
            ),
            buttons: false,
            timer: 2000,
          });
        });

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/sp-workplan-notes_and_remarks/`,
          noteAndRemarkData
        )
        .then((response) => {
          setNoteAndRemarkData({
            ...noteAndRemarkData,
            txt_value: response?.data?.txt_value,
          });

          setClonedNoteAndRemarkData({
            ...clonedNoteAndRemarkData,
            txt_value: response?.data?.txt_value,
          });

          swal({
            text: context.t("SP Note and Remark Data saved successfully."),
            buttons: false,
            timer: 2000,
          });
        })
        .catch((error) => {
          swal({
            text: context.t(
              "An error occurred while saving SP Notes and Remarks data"
            ),
            buttons: false,
            timer: 2000,
          });
        });
    } else {
      swal({
        text: context.t("Please create a work plan in Step 1."),
        buttons: false,
        timer: 2000,
      });
    }
  };

  const handleNext = () => {
    let dataString = JSON.stringify(data);
    let clonedDataString = JSON.stringify(clonedData);

    let noteAndRemarDataString = JSON.stringify(noteAndRemarkData);
    let clonedNoteAndRemarkDataString = JSON.stringify(clonedNoteAndRemarkData);

    if (
      dataString == clonedDataString &&
      noteAndRemarDataString == clonedNoteAndRemarkDataString
    ) {
      props.next();
    } else {
      swal({
        text: context.t("Changes have not been saved."),
        icon: "warning",
        buttons: true,
        dangerMode: true,
        buttons: {
          close: context.t("Cancel"),
          Proceed: context.t("Proceed Anyway"),
          save: context.t("Save"),
        },
        closeOnClickOutside: false,
      }).then((willSave) => {
        switch (willSave) {
          case "Proceed":
            props.next();
            break;
          case "save":
            handleSave();
            break;
        }
      });
    }
  };

  return (
    <Container>
      {workPlanItem.loading ? (
        <LinearProgress />
      ) : (
        <div className="workplan-box">
          <h3>{context.t("3 of 5. Progress Indicators")}</h3>
          <p>
            {context.t(
              "The target progress indicators for your company that you indicate here will display on your SP Progress Metrics Dashboard. At any time, you can visit the SP Progress Dashboard to see your targets as well as the progress made to date against each one."
            )}
          </p>
          <br />
          <br />
          <Grid container spacing={3}>
            <Grid item xs={7}>
              <h5>
                {context.t(
                  "INDICATORS FOR AREA 1. WORKER VOICE: ANNUAL TARGETS"
                )}
              </h5>
            </Grid>
            <Grid item xs={5}>
              <h5>
                <strong>{context.t("Company Targets / Notes")}</strong>
              </h5>
            </Grid>
            {/* row */}
            <Grid item xs={2}>
              <strong>{context.t("Worker Voice Indicator 1")}</strong>
            </Grid>
            <Grid item xs={10}>
              <strong>
                {context.t("Worker voice roll-out and ongoing engagement")}
              </strong>
            </Grid>
            {/* row */}
            <Grid item xs={2} md={2}></Grid>
            <Grid item xs={5} md={4}>
              {context.t(
                "Percentage of tier-1 suppliers (export level) in your supply chain with Issara worker voice channels available to workers"
              )}
            </Grid>
            <Grid item xs={5}>
              <TextareaAutosize
                key="percentage_tier1_suppliers_with_issara_worker_voice_channels_note"
                name="percentage_tier1_suppliers_with_issara_worker_voice_channels_note"
                value={
                  data?.find(
                    (item) =>
                      item.str_value ===
                      "percentage_tier1_suppliers_with_issara_worker_voice_channels_note"
                  )?.notes || ""
                }
                aria-label={context.t("add note")}
                placeholder={context.t("Add note...")}
                minRows={3}
                style={{ width: "100%", padding: "10px" }}
                onChange={(e) => {
                  const value = e.target.value;
                  const workplan = workPlanData?.id;

                  const updatedData = (() => {
                    const index = data.findIndex(
                      (item) =>
                        item.str_value ===
                        "percentage_tier1_suppliers_with_issara_worker_voice_channels_note"
                    );

                    if (index !== -1) {
                      return data.map((item, i) =>
                        i === index
                          ? {
                              ...item,
                              notes: value,
                              ...(workplan && { workplan }),
                            }
                          : item
                      );
                    } else {
                      return [
                        ...data,
                        {
                          area: "worker_voice",
                          str_value:
                            "percentage_tier1_suppliers_with_issara_worker_voice_channels_note",
                          txt_value:
                            "Percentage of tier-1 suppliers (export level) in your supply chain with Issara worker voice channels available to workers",
                          notes: value,
                          ...(workplan && { workplan }),
                        },
                      ];
                    }
                  })();

                  setData(updatedData);
                }}
              />
            </Grid>
            {/* row */}
            <Grid item xs={2} md={2}></Grid>
            <Grid item xs={5} md={4}>
              {context.t(
                "Percentage of upstream (tier-2 and beyond) suppliers in your supply chain with Issara worker voice channels available to workers, based on supply chain information provided"
              )}
            </Grid>
            <Grid item xs={5}>
              <TextareaAutosize
                key="percentage_upstream_suppliers_with_issara_worker_voice_channels_note"
                name="percentage_upstream_suppliers_with_issara_worker_voice_channels_note"
                value={
                  data?.find(
                    (item) =>
                      item.str_value ===
                      "percentage_upstream_suppliers_with_issara_worker_voice_channels_note"
                  )?.notes || ""
                }
                aria-label={context.t("add note")}
                placeholder={context.t("Add note...")}
                minRows={3}
                style={{ width: "100%", padding: "10px" }}
                onChange={(e) => {
                  const value = e.target.value;
                  const workplan = workPlanData?.id;

                  const updatedData = (() => {
                    const index = data.findIndex(
                      (item) =>
                        item.str_value ===
                        "percentage_upstream_suppliers_with_issara_worker_voice_channels_note"
                    );

                    if (index !== -1) {
                      return data.map((item, i) =>
                        i === index
                          ? {
                              ...item,
                              area: "worker_voice",
                              notes: value,
                              ...(workplan && { workplan }),
                            }
                          : item
                      );
                    } else {
                      return [
                        ...data,
                        {
                          area: "worker_voice",
                          str_value:
                            "percentage_upstream_suppliers_with_issara_worker_voice_channels_note",
                          txt_value:
                            "Percentage of upstream (tier-2 and beyond) suppliers in your supply chain with Issara worker voice channels available to workers, based on supply chain information provided",
                          notes: value,
                          ...(workplan && { workplan }),
                        },
                      ];
                    }
                  })();

                  setData(updatedData);
                }}
              />
            </Grid>
            {/* row */}
            <Grid item xs={12}>
              <IndicatorInfo />
            </Grid>
            {/* row */}
            <Grid item xs={2}>
              <strong>{context.t("Worker Voice Indicator 2")}</strong>
            </Grid>
            <Grid item xs={10}>
              <strong>
                {context.t(
                  "Policy & procedures review: Ethical grievance mechanisms policy is in force that:"
                )}
              </strong>
            </Grid>
            <Grid item xs={2} md={2}></Grid>
            <Grid item xs={5} md={4}>
              {context.t(
                "Covers requirements and mechanisms for managing grievances"
              )}
            </Grid>
            <Grid item xs={5} md={6}>
              <CustomToggleButtonGroup
                id="covers_requirements_mechanisms_managing_grievances"
                name="covers_requirements_mechanisms_managing_grievances"
                value={
                  data?.find(
                    (item) =>
                      item.str_value ===
                      "covers_requirements_mechanisms_managing_grievances"
                  )?.progress_status || "not yet"
                }
                onChange={(updatedValue) => {
                  const value = updatedValue;
                  const workplan = workPlanData?.id;

                  const targetItemIndex = data?.findIndex(
                    (item) =>
                      item.str_value ===
                      "covers_requirements_mechanisms_managing_grievances"
                  );

                  let updatedData;

                  if (targetItemIndex > -1) {
                    updatedData = data.map((item, index) =>
                      index === targetItemIndex
                        ? {
                            ...item,
                            area: "worker_voice",
                            progress_status: value,
                            ...(workplan && { workplan: workplan }),
                          }
                        : item
                    );
                  } else {
                    updatedData = [
                      ...data,
                      {
                        area: "worker_voice",
                        str_value:
                          "covers_requirements_mechanisms_managing_grievances",
                        progress_status: value,
                        ...(workplan && { workplan: workplan }),
                      },
                    ];
                  }

                  setData(updatedData);
                }}
                complete_color_teal={true}
              />
            </Grid>
            {/* row */}
            <Grid item xs={2} md={2}></Grid>
            <Grid item xs={5} md={4}>
              {context.t("Includes a clear non-retaliation policy")}
            </Grid>
            <Grid item xs={5} md={6}>
              <CustomToggleButtonGroup
                id="clear_non_retaliation_policy"
                name="clear_non_retaliation_policy"
                value={
                  data?.find(
                    (item) => item.str_value === "clear_non_retaliation_policy"
                  )?.progress_status || "not yet"
                }
                onChange={(updatedValue) => {
                  const value = updatedValue;
                  const workplan = workPlanData?.id;

                  const targetItemIndex = data?.findIndex(
                    (item) => item.str_value === "clear_non_retaliation_policy"
                  );

                  let updatedData;

                  if (targetItemIndex > -1) {
                    updatedData = data.map((item, index) =>
                      index === targetItemIndex
                        ? {
                            ...item,
                            area: "worker_voice",
                            progress_status: value,
                            ...(workplan && { workplan: workplan }),
                          }
                        : item
                    );
                  } else {
                    updatedData = [
                      ...data,
                      {
                        area: "worker_voice",
                        str_value: "clear_non_retaliation_policy",
                        progress_status: value,
                        ...(workplan && { workplan: workplan }),
                      },
                    ];
                  }

                  setData(updatedData);
                }}
                complete_color_teal={true}
              />
            </Grid>
            {/* row */}
            <Grid item xs={2} md={2}></Grid>
            <Grid item xs={5} md={4}>
              {context.t(
                "Undergoes periodic review to identify means for continuous improvement"
              )}
            </Grid>
            <Grid item xs={5} md={6}>
              <CustomToggleButtonGroup
                id="periodic_review_continuous_improvement"
                name="periodic_review_continuous_improvement"
                value={
                  data?.find(
                    (item) =>
                      item.str_value ===
                      "periodic_review_continuous_improvement"
                  )?.progress_status || "not yet"
                }
                onChange={(updatedValue) => {
                  const value = updatedValue;
                  const workplan = workPlanData?.id;

                  const targetItemIndex = data?.findIndex(
                    (item) =>
                      item.str_value ===
                      "periodic_review_continuous_improvement"
                  );

                  let updatedData;

                  if (targetItemIndex > -1) {
                    updatedData = data.map((item, index) =>
                      index === targetItemIndex
                        ? {
                            ...item,
                            area: "worker_voice",
                            progress_status: value,
                            ...(workplan && { workplan: workplan }),
                          }
                        : item
                    );
                  } else {
                    updatedData = [
                      ...data,
                      {
                        area: "worker_voice",
                        str_value: "periodic_review_continuous_improvement",
                        progress_status: value,
                        ...(workplan && { workplan: workplan }),
                      },
                    ];
                  }

                  setData(updatedData);
                }}
                complete_color_teal={true}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {/* indicator for area 2 */}
            <Grid item xs={12}>
              <h5>
                {context.t(
                  "INDICATORS FOR AREA 2. LABOR RECRUITMENT: ANNUAL TARGETS"
                )}
              </h5>
            </Grid>
            {/* row */}
            <Grid item xs={2}>
              <strong>{context.t("ER Indicator 1")}</strong>
            </Grid>
            <Grid item xs={10}>
              <strong>
                {context.t(
                  "Policy & procedures review: Ethical recruitment / migrant recruitment laws and policies is in force that is:"
                )}
              </strong>
            </Grid>
            {/* row */}
            <Grid item xs={2} md={2}></Grid>
            <Grid item xs={5} md={4}>
              {context.t(
                "In line with international standards and definitions"
              )}
            </Grid>
            <Grid item xs={5} md={6}>
              <CustomToggleButtonGroup
                id="international_standards_definitions"
                name="international_standards_definitions"
                value={
                  data?.find(
                    (item) =>
                      item.str_value === "international_standards_definitions"
                  )?.progress_status || "not yet"
                }
                onChange={(updatedValue) => {
                  const value = updatedValue;
                  const workplan = workPlanData?.id;

                  const targetItemIndex = data?.findIndex(
                    (item) =>
                      item.str_value === "international_standards_definitions"
                  );

                  let updatedData;

                  if (targetItemIndex > -1) {
                    updatedData = data.map((item, index) =>
                      index === targetItemIndex
                        ? {
                            ...item,
                            area: "labour_recruitment",
                            progress_status: value,
                            ...(workplan && { workplan: workplan }),
                          }
                        : item
                    );
                  } else {
                    updatedData = [
                      ...data,
                      {
                        area: "labour_recruitment",
                        str_value: "international_standards_definitions",
                        progress_status: value,
                        ...(workplan && { workplan: workplan }),
                      },
                    ];
                  }

                  setData(updatedData);
                }}
                complete_color_teal={true}
              />
            </Grid>
            {/* row */}
            <Grid item xs={2} md={2}></Grid>
            <Grid item xs={5} md={4}>
              {context.t("Clearly communicated to suppliers")}
            </Grid>
            <Grid item xs={5} md={6}>
              <CustomToggleButtonGroup
                id="communicated_to_suppliers"
                name="communicated_to_suppliers"
                value={
                  data?.find(
                    (item) => item.str_value === "communicated_to_suppliers"
                  )?.progress_status || "not yet"
                }
                onChange={(updatedValue) => {
                  const value = updatedValue;
                  const workplan = workPlanData?.id;

                  const targetItemIndex = data?.findIndex(
                    (item) => item.str_value === "communicated_to_suppliers"
                  );

                  let updatedData;

                  if (targetItemIndex > -1) {
                    updatedData = data.map((item, index) =>
                      index === targetItemIndex
                        ? {
                            ...item,
                            area: "labour_recruitment",
                            progress_status: value,
                            ...(workplan && { workplan: workplan }),
                          }
                        : item
                    );
                  } else {
                    updatedData = [
                      ...data,
                      {
                        area: "labour_recruitment",
                        str_value: "communicated_to_suppliers",
                        progress_status: value,
                        ...(workplan && { workplan: workplan }),
                      },
                    ];
                  }

                  setData(updatedData);
                }}
                complete_color_teal={true}
              />
            </Grid>
            {/* row */}
            <Grid item xs={2} md={2}></Grid>
            <Grid item xs={5} md={4}>
              {context.t(
                "Integrated into commercial processes such as supplier scorecards and contracts"
              )}
            </Grid>
            <Grid item xs={5} md={6}>
              <CustomToggleButtonGroup
                id="integrated_commercial_processes"
                name="integrated_commercial_processes"
                value={
                  data?.find(
                    (item) =>
                      item.str_value === "integrated_commercial_processes"
                  )?.progress_status || "not yet"
                }
                onChange={(updatedValue) => {
                  const value = updatedValue;
                  const workplan = workPlanData?.id;

                  const targetItemIndex = data?.findIndex(
                    (item) =>
                      item.str_value === "integrated_commercial_processes"
                  );

                  let updatedData;

                  if (targetItemIndex > -1) {
                    updatedData = data.map((item, index) =>
                      index === targetItemIndex
                        ? {
                            ...item,
                            area: "labour_recruitment",
                            progress_status: value,
                            ...(workplan && { workplan: workplan }),
                          }
                        : item
                    );
                  } else {
                    updatedData = [
                      ...data,
                      {
                        area: "labour_recruitment",
                        str_value: "integrated_commercial_processes",
                        progress_status: value,
                        ...(workplan && { workplan: workplan }),
                      },
                    ];
                  }

                  setData(updatedData);
                }}
                complete_color_teal={true}
              />
            </Grid>
            {/* row */}
            <Grid item xs={2}>
              <strong>{context.t("ER Indicator 3")}</strong>
            </Grid>
            <Grid item xs={5} md={4}>
              {context.t(
                "Review of MSAs between employer/suppliers and recruitment agencies"
              )}
            </Grid>
            <Grid item xs={5} md={6}>
              <CustomToggleButtonGroup
                id="review_msas_employer_supplier_recruitment"
                name="review_msas_employer_supplier_recruitment"
                value={
                  data?.find(
                    (item) =>
                      item.str_value ===
                      "review_msas_employer_supplier_recruitment"
                  )?.progress_status || "not yet"
                }
                onChange={(updatedValue) => {
                  const value = updatedValue;
                  const workplan = workPlanData?.id;

                  const targetItemIndex = data?.findIndex(
                    (item) =>
                      item.str_value ===
                      "review_msas_employer_supplier_recruitment"
                  );

                  let updatedData;

                  if (targetItemIndex > -1) {
                    updatedData = data.map((item, index) =>
                      index === targetItemIndex
                        ? {
                            ...item,
                            area: "labour_recruitment",
                            progress_status: value,
                            ...(workplan && { workplan: workplan }),
                          }
                        : item
                    );
                  } else {
                    updatedData = [
                      ...data,
                      {
                        area: "labour_recruitment",
                        str_value: "review_msas_employer_supplier_recruitment",
                        progress_status: value,
                        ...(workplan && { workplan: workplan }),
                      },
                    ];
                  }

                  setData(updatedData);
                }}
                complete_color_teal={true}
              />
              <br />
              <br />
              <TextareaAutosize
                key="review_msas_employer_supplier_recruitment_note"
                name="review_msas_employer_supplier_recruitment_note"
                value={
                  data?.find(
                    (item) =>
                      item.str_value ===
                      "review_msas_employer_supplier_recruitment_note"
                  )?.notes || ""
                }
                aria-label={context.t("add note")}
                placeholder={context.t("Add note...")}
                minRows={3}
                style={{ width: "100%", padding: "10px" }}
                onChange={(e) => {
                  const value = e.target.value;
                  const workplan = workPlanData?.id;

                  const updatedData = (() => {
                    const index = data.findIndex(
                      (item) =>
                        item.str_value ===
                        "review_msas_employer_supplier_recruitment_note"
                    );

                    if (index !== -1) {
                      return data.map((item, i) =>
                        i === index
                          ? {
                              ...item,
                              area: "labour_recruitment",
                              notes: value,
                              ...(workplan && { workplan }),
                            }
                          : item
                      );
                    } else {
                      return [
                        ...data,
                        {
                          area: "labour_recruitment",
                          str_value:
                            "review_msas_employer_supplier_recruitment_note",
                          notes: value,
                          ...(workplan && { workplan }),
                        },
                      ];
                    }
                  })();

                  setData(updatedData);
                }}
              />
            </Grid>
            {/* row */}
            <Grid item xs={2}>
              <strong>{context.t("ER Indicator 4")}</strong>
            </Grid>
            <Grid item xs={5} md={4}>
              {context.t(
                "Communications: disclosure of recruitment agencies used"
              )}
            </Grid>
            <Grid item xs={5} md={6}>
              <CustomToggleButtonGroup
                id="communication_disclosure_recruitment_agencies"
                name="communication_disclosure_recruitment_agencies"
                value={
                  data?.find(
                    (item) =>
                      item.str_value ===
                      "communication_disclosure_recruitment_agencies"
                  )?.progress_status || "not yet"
                }
                onChange={(updatedValue) => {
                  const value = updatedValue;
                  const workplan = workPlanData?.id;

                  const targetItemIndex = data?.findIndex(
                    (item) =>
                      item.str_value ===
                      "communication_disclosure_recruitment_agencies"
                  );

                  let updatedData;

                  if (targetItemIndex > -1) {
                    updatedData = data.map((item, index) =>
                      index === targetItemIndex
                        ? {
                            ...item,
                            area: "labour_recruitment",
                            progress_status: value,
                            ...(workplan && { workplan: workplan }),
                          }
                        : item
                    );
                  } else {
                    updatedData = [
                      ...data,
                      {
                        area: "labour_recruitment",
                        str_value:
                          "communication_disclosure_recruitment_agencies",
                        progress_status: value,
                        ...(workplan && { workplan: workplan }),
                      },
                    ];
                  }

                  setData(updatedData);
                }}
                complete_color_teal={true}
              />
              <br />
              <br />
              <TextareaAutosize
                key="communication_disclosure_recruitment_agencies_note"
                name="communication_disclosure_recruitment_agencies_note"
                value={
                  data?.find(
                    (item) =>
                      item.str_value ===
                      "communication_disclosure_recruitment_agencies_note"
                  )?.notes || ""
                }
                aria-label={context.t("add note")}
                placeholder={context.t("Add note...")}
                minRows={3}
                style={{ width: "100%", padding: "10px" }}
                onChange={(e) => {
                  const value = e.target.value;
                  const workplan = workPlanData?.id;

                  const updatedData = (() => {
                    const index = data.findIndex(
                      (item) =>
                        item.str_value ===
                        "communication_disclosure_recruitment_agencies_note"
                    );

                    if (index !== -1) {
                      return data.map((item, i) =>
                        i === index
                          ? {
                              ...item,
                              area: "labour_recruitment",
                              notes: value,
                              ...(workplan && { workplan }),
                            }
                          : item
                      );
                    } else {
                      return [
                        ...data,
                        {
                          area: "labour_recruitment",
                          str_value:
                            "communication_disclosure_recruitment_agencies_note",
                          txt_value:
                            "communication_disclosure_recruitment_agencies_note",
                          notes: value,
                          ...(workplan && { workplan }),
                        },
                      ];
                    }
                  })();

                  setData(updatedData);
                }}
              />
            </Grid>
            {/* row */}
            <Grid item xs={2}>
              <strong>{context.t("ER Indicator 6")}</strong>
            </Grid>
            <Grid item xs={5} md={4}>
              {context.t("Recruitment System Assessment")}
            </Grid>
            <Grid item xs={5} md={6}>
              <CustomToggleButtonGroup
                id="recruitment_survey_fees"
                name="recruitment_survey_fees"
                value={
                  data?.find(
                    (item) => item.str_value === "recruitment_survey_fees"
                  )?.progress_status || "not yet"
                }
                onChange={(updatedValue) => {
                  const value = updatedValue;
                  const workplan = workPlanData?.id;

                  const targetItemIndex = data?.findIndex(
                    (item) => item.str_value === "recruitment_survey_fees"
                  );

                  let updatedData;

                  if (targetItemIndex > -1) {
                    updatedData = data.map((item, index) =>
                      index === targetItemIndex
                        ? {
                            ...item,
                            area: "labour_recruitment",
                            progress_status: value,
                            ...(workplan && { workplan: workplan }),
                          }
                        : item
                    );
                  } else {
                    updatedData = [
                      ...data,
                      {
                        area: "labour_recruitment",
                        str_value: "recruitment_survey_fees",
                        progress_status: value,
                        ...(workplan && { workplan: workplan }),
                      },
                    ];
                  }

                  setData(updatedData);
                }}
                complete_color_teal={true}
              />
              <br />
              <br />
              <TextareaAutosize
                key="recruitment_survey_fees_note"
                name="recruitment_survey_fees_note"
                value={
                  data?.find(
                    (item) => item.str_value === "recruitment_survey_fees_note"
                  )?.notes || ""
                }
                aria-label={context.t("add note")}
                placeholder={context.t("Add note...")}
                minRows={3}
                style={{ width: "100%", padding: "10px" }}
                onChange={(e) => {
                  const value = e.target.value;
                  const workplan = workPlanData?.id;

                  const updatedData = (() => {
                    const index = data.findIndex(
                      (item) =>
                        item.str_value === "recruitment_survey_fees_note"
                    );

                    if (index !== -1) {
                      return data.map((item, i) =>
                        i === index
                          ? {
                              ...item,
                              area: "labour_recruitment",
                              notes: value,
                              ...(workplan && { workplan }),
                            }
                          : item
                      );
                    } else {
                      return [
                        ...data,
                        {
                          area: "labour_recruitment",
                          str_value: "recruitment_survey_fees_note",
                          txt_value: "recruitment_survey_fees_note",
                          notes: value,
                          ...(workplan && { workplan }),
                        },
                      ];
                    }
                  })();

                  setData(updatedData);
                }}
              />
            </Grid>
            {/* row */}
            <Grid item xs={2}>
              <strong>{context.t("ER Indicator 7")}</strong>
            </Grid>
            <Grid item xs={5} md={4}>
              {context.t(
                "Institutional capacity building: ethical recruitment trainings"
              )}
            </Grid>
            <Grid item xs={5} md={6}>
              <CustomToggleButtonGroup
                id="institutional_capacity_building_training_staff"
                name="institutional_capacity_building_training_staff"
                value={
                  data?.find(
                    (item) =>
                      item.str_value ===
                      "institutional_capacity_building_training_staff"
                  )?.progress_status || "not yet"
                }
                onChange={(updatedValue) => {
                  const value = updatedValue;
                  const workplan = workPlanData?.id;

                  const targetItemIndex = data?.findIndex(
                    (item) =>
                      item.str_value ===
                      "institutional_capacity_building_training_staff"
                  );

                  let updatedData;

                  if (targetItemIndex > -1) {
                    updatedData = data.map((item, index) =>
                      index === targetItemIndex
                        ? {
                            ...item,
                            area: "labour_recruitment",
                            progress_status: value,
                            ...(workplan && { workplan: workplan }),
                          }
                        : item
                    );
                  } else {
                    updatedData = [
                      ...data,
                      {
                        area: "labour_recruitment",
                        str_value:
                          "institutional_capacity_building_training_staff",
                        progress_status: value,
                        ...(workplan && { workplan: workplan }),
                      },
                    ];
                  }

                  setData(updatedData);
                }}
                complete_color_teal={true}
              />
              <br />
              <br />
              <TextareaAutosize
                key="institutional_capacity_building_ethical_recruitment_trainings_note"
                name="institutional_capacity_building_ethical_recruitment_trainings_note"
                value={
                  data?.find(
                    (item) =>
                      item.str_value ===
                      "institutional_capacity_building_ethical_recruitment_trainings_note"
                  )?.notes || ""
                }
                aria-label={context.t("add note")}
                placeholder={context.t("Add note...")}
                minRows={3}
                style={{ width: "100%", padding: "10px" }}
                onChange={(e) => {
                  const value = e.target.value;
                  const workplan = workPlanData?.id;

                  const updatedData = (() => {
                    const index = data.findIndex(
                      (item) =>
                        item.str_value ===
                        "institutional_capacity_building_ethical_recruitment_trainings_note"
                    );

                    if (index !== -1) {
                      return data.map((item, i) =>
                        i === index
                          ? {
                              ...item,
                              area: "labour_recruitment",
                              notes: value,
                              ...(workplan && { workplan }),
                            }
                          : item
                      );
                    } else {
                      return [
                        ...data,
                        {
                          area: "labour_recruitment",
                          str_value:
                            "institutional_capacity_building_ethical_recruitment_trainings_note",
                          txt_value:
                            "institutional_capacity_building_ethical_recruitment_trainings_note",
                          notes: value,
                          ...(workplan && { workplan }),
                        },
                      ];
                    }
                  })();

                  setData(updatedData);
                }}
              />
            </Grid>
            {/* row */}
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <h5>
                {context.t(
                  "INDICATORS FOR AREA 3. WORKING CONDITIONS: ANNUAL TARGETS"
                )}
              </h5>
            </Grid>
            <br />
            {/* row */}
            <Grid item xs={2}>
              <strong>{context.t("WORK Indicator 1")}</strong>
            </Grid>
            <Grid item xs={5} md={4}>
              {context.t("Worker satisfaction surveys")}
            </Grid>
            <Grid item xs={5} md={6}>
              <CustomToggleButtonGroup
                id="worker_satisfaction_surveys"
                name="worker_satisfaction_surveys"
                value={
                  data?.find(
                    (item) => item.str_value === "worker_satisfaction_surveys"
                  )?.progress_status || "not yet"
                }
                onChange={(updatedValue) => {
                  const value = updatedValue;
                  const workplan = workPlanData?.id;

                  const targetItemIndex = data?.findIndex(
                    (item) => item.str_value === "worker_satisfaction_surveys"
                  );

                  let updatedData;

                  if (targetItemIndex > -1) {
                    updatedData = data.map((item, index) =>
                      index === targetItemIndex
                        ? {
                            ...item,
                            area: "working_conditions",
                            progress_status: value,
                            ...(workplan && { workplan: workplan }),
                          }
                        : item
                    );
                  } else {
                    updatedData = [
                      ...data,
                      {
                        area: "working_conditions",
                        str_value: "worker_satisfaction_surveys",
                        progress_status: value,
                        ...(workplan && { workplan: workplan }),
                      },
                    ];
                  }

                  setData(updatedData);
                }}
                complete_color_teal={true}
              />
              <br />
              <br />
              <TextareaAutosize
                key="worker_satisfaction_surveys_note"
                name="worker_satisfaction_surveys_note"
                value={
                  data?.find(
                    (item) =>
                      item.str_value === "worker_satisfaction_surveys_note"
                  )?.notes || ""
                }
                aria-label={context.t("add note")}
                placeholder={context.t("Add note...")}
                minRows={3}
                style={{ width: "100%", padding: "10px" }}
                onChange={(e) => {
                  const value = e.target.value;
                  const workplan = workPlanData?.id;

                  const updatedData = (() => {
                    const index = data.findIndex(
                      (item) =>
                        item.str_value === "worker_satisfaction_surveys_note"
                    );

                    if (index !== -1) {
                      return data.map((item, i) =>
                        i === index
                          ? {
                              ...item,
                              area: "working_conditions",
                              notes: value,
                              ...(workplan && { workplan }),
                            }
                          : item
                      );
                    } else {
                      return [
                        ...data,
                        {
                          area: "working_conditions",
                          str_value: "worker_satisfaction_surveys_note",
                          txt_value: "worker_satisfaction_surveys_note",
                          notes: value,
                          ...(workplan && { workplan }),
                        },
                      ];
                    }
                  })();

                  setData(updatedData);
                }}
              />
            </Grid>
            {/* row */}
            <Grid item xs={2}>
              <strong>{context.t("WORK Indicator 2")}</strong>
            </Grid>
            <Grid item xs={5} md={4}>
              {context.t(
                "Institutional capacity building: training of working level/technical staff"
              )}
            </Grid>
            <Grid item xs={5} md={6}>
              <CustomToggleButtonGroup
                id="institutional_capacity_building_training_working_technical_staff"
                name="institutional_capacity_building_training_working_technical_staff"
                value={
                  data?.find(
                    (item) =>
                      item.str_value ===
                      "institutional_capacity_building_training_working_technical_staff"
                  )?.progress_status || "not yet"
                }
                onChange={(updatedValue) => {
                  const value = updatedValue;
                  const workplan = workPlanData?.id;

                  const targetItemIndex = data?.findIndex(
                    (item) =>
                      item.str_value ===
                      "institutional_capacity_building_training_working_technical_staff"
                  );

                  let updatedData;

                  if (targetItemIndex > -1) {
                    updatedData = data.map((item, index) =>
                      index === targetItemIndex
                        ? {
                            ...item,
                            area: "working_conditions",
                            progress_status: value,
                            ...(workplan && { workplan: workplan }),
                          }
                        : item
                    );
                  } else {
                    updatedData = [
                      ...data,
                      {
                        area: "working_conditions",
                        str_value:
                          "institutional_capacity_building_training_working_technical_staff",
                        progress_status: value,
                        ...(workplan && { workplan: workplan }),
                      },
                    ];
                  }

                  setData(updatedData);
                }}
                complete_color_teal={true}
              />
              <br />
              <br />
              <TextareaAutosize
                key="institutional_capacity_building_training_working_technical_staff_note"
                name="institutional_capacity_building_training_working_technical_staff_note"
                value={
                  data?.find(
                    (item) =>
                      item.str_value ===
                      "institutional_capacity_building_training_working_technical_staff_note"
                  )?.notes || ""
                }
                aria-label={context.t("add note")}
                placeholder={context.t("Add note...")}
                minRows={3}
                style={{ width: "100%", padding: "10px" }}
                onChange={(e) => {
                  const value = e.target.value;
                  const workplan = workPlanData?.id;

                  const updatedData = (() => {
                    const index = data.findIndex(
                      (item) =>
                        item.str_value ===
                        "institutional_capacity_building_training_working_technical_staff_note"
                    );

                    if (index !== -1) {
                      return data.map((item, i) =>
                        i === index
                          ? {
                              ...item,
                              area: "working_conditions",
                              notes: value,
                              ...(workplan && { workplan }),
                            }
                          : item
                      );
                    } else {
                      return [
                        ...data,
                        {
                          area: "working_conditions",
                          str_value:
                            "institutional_capacity_building_training_working_technical_staff_note",
                          notes: value,
                          ...(workplan && { workplan }),
                        },
                      ];
                    }
                  })();

                  setData(updatedData);
                }}
              />
            </Grid>
            {/* row */}
            <Grid item xs={2}>
              <strong>{context.t("WORK Indicator 3")}</strong>
            </Grid>
            <Grid item xs={5} md={4}>
              {context.t(
                "Institutional capacity building: training of management/leadership"
              )}
            </Grid>
            <Grid item xs={5} md={6}>
              <CustomToggleButtonGroup
                id="institutional_capacity_building_training_management_leadership"
                name="institutional_capacity_building_training_management_leadership"
                value={
                  data?.find(
                    (item) =>
                      item.str_value ===
                      "institutional_capacity_building_training_management_leadership"
                  )?.progress_status || "not yet"
                }
                onChange={(updatedValue) => {
                  const value = updatedValue;
                  const workplan = workPlanData?.id;

                  const targetItemIndex = data?.findIndex(
                    (item) =>
                      item.str_value ===
                      "institutional_capacity_building_training_management_leadership"
                  );

                  let updatedData;

                  if (targetItemIndex > -1) {
                    updatedData = data.map((item, index) =>
                      index === targetItemIndex
                        ? {
                            ...item,
                            area: "working_conditions",
                            progress_status: value,
                            ...(workplan && { workplan: workplan }),
                          }
                        : item
                    );
                  } else {
                    updatedData = [
                      ...data,
                      {
                        area: "working_conditions",
                        str_value:
                          "institutional_capacity_building_training_management_leadership",
                        progress_status: value,
                        ...(workplan && { workplan: workplan }),
                      },
                    ];
                  }

                  setData(updatedData);
                }}
                complete_color_teal={true}
              />
              <br />
              <br />
              <TextareaAutosize
                key="institutional_capacity_building_training_management_leadership_note"
                name="institutional_capacity_building_training_management_leadership_note"
                value={
                  data?.find(
                    (item) =>
                      item.str_value ===
                      "institutional_capacity_building_training_management_leadership_note"
                  )?.notes || ""
                }
                aria-label={context.t("add note")}
                placeholder={context.t("Add note...")}
                minRows={3}
                style={{ width: "100%", padding: "10px" }}
                onChange={(e) => {
                  const value = e.target.value;
                  const workplan = workPlanData?.id;

                  const updatedData = (() => {
                    const index = data.findIndex(
                      (item) =>
                        item.str_value ===
                        "institutional_capacity_building_training_management_leadership_note"
                    );

                    if (index !== -1) {
                      return data.map((item, i) =>
                        i === index
                          ? {
                              ...item,
                              area: "working_conditions",
                              notes: value,
                              ...(workplan && { workplan }),
                            }
                          : item
                      );
                    } else {
                      return [
                        ...data,
                        {
                          area: "working_conditions",
                          str_value:
                            "institutional_capacity_building_training_management_leadership_note",
                          notes: value,
                          ...(workplan && { workplan }),
                        },
                      ];
                    }
                  })();

                  setData(updatedData);
                }}
              />
            </Grid>
            {/* row */}
            <Grid item xs={12}>
              <br />
            </Grid>
            <Grid item xs={12}>
              <h5>
                {context.t(
                  "IMPACT INDICATOR 1. Average performance scores or supply chain businesses (Scale of 1-5, where 1 is poor and 5 is excellent):"
                )}
              </h5>
            </Grid>
            {/* row */}
            <Grid item xs={3}>
              {context.t("• Quality of remediation")}
            </Grid>
            <Grid item xs={9}>
              <ToggleButtonGroup
                color="primary"
                exclusive
                value={
                  data
                    ?.find((item) => item.str_value === "quality_remediation")
                    ?.numeric_value?.toString() || "1"
                }
                onChange={(event, updatedValue) => {
                  if (updatedValue) {
                    const workplan = workPlanData?.id;

                    const targetItemIndex = data?.findIndex(
                      (item) => item.str_value === "quality_remediation"
                    );

                    let updatedData;

                    if (targetItemIndex > -1) {
                      updatedData = data.map((item, index) =>
                        index === targetItemIndex
                          ? {
                              ...item,
                              area: "working_conditions",
                              numeric_value: parseInt(updatedValue, 10),
                              ...(workplan && { workplan }),
                            }
                          : item
                      );
                    } else {
                      updatedData = [
                        ...data,
                        {
                          area: "working_conditions",
                          str_value: "quality_remediation",
                          numeric_value: parseInt(updatedValue, 10),
                          ...(workplan && { workplan }),
                        },
                      ];
                    }

                    setData(updatedData);
                  }
                }}
                style={{ height: "25px" }}
              >
                {[1, 2, 3, 4, 5].map((num) => (
                  <ToggleButton key={num} value={num.toString()}>
                    {context.t(num.toString())}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={3}>
              {context.t("• Timeliness of remediation")}
            </Grid>
            <Grid item xs={9}>
              <ToggleButtonGroup
                color="primary"
                exclusive
                value={
                  data
                    ?.find(
                      (item) => item.str_value === "timeliness_remediation"
                    )
                    ?.numeric_value?.toString() || "1"
                }
                onChange={(event, updatedValue) => {
                  if (updatedValue) {
                    const workplan = workPlanData?.id;

                    const targetItemIndex = data?.findIndex(
                      (item) => item.str_value === "timeliness_remediation"
                    );

                    let updatedData;

                    if (targetItemIndex > -1) {
                      updatedData = data.map((item, index) =>
                        index === targetItemIndex
                          ? {
                              ...item,
                              area: "working_conditions",
                              numeric_value: parseInt(updatedValue, 10),
                              ...(workplan && { workplan }),
                            }
                          : item
                      );
                    } else {
                      updatedData = [
                        ...data,
                        {
                          area: "working_conditions",
                          str_value: "timeliness_remediation",
                          numeric_value: parseInt(updatedValue, 10),
                          ...(workplan && { workplan }),
                        },
                      ];
                    }

                    setData(updatedData);
                  }
                }}
                style={{ height: "25px" }}
              >
                {[1, 2, 3, 4, 5].map((num) => (
                  <ToggleButton key={num} value={num.toString()}>
                    {context.t(num.toString())}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={3}>
              {context.t("• Openness to reform")}
            </Grid>
            <Grid item xs={9}>
              <ToggleButtonGroup
                color="primary"
                exclusive
                value={
                  data
                    ?.find((item) => item.str_value === "openness_to_reform")
                    ?.numeric_value?.toString() || "1"
                }
                onChange={(event, updatedValue) => {
                  if (updatedValue) {
                    const workplan = workPlanData?.id;

                    const targetItemIndex = data?.findIndex(
                      (item) => item.str_value === "openness_to_reform"
                    );

                    let updatedData;

                    if (targetItemIndex > -1) {
                      updatedData = data.map((item, index) =>
                        index === targetItemIndex
                          ? {
                              ...item,
                              area: "working_conditions",
                              numeric_value: parseInt(updatedValue, 10),
                              ...(workplan && { workplan }),
                            }
                          : item
                      );
                    } else {
                      updatedData = [
                        ...data,
                        {
                          area: "working_conditions",
                          str_value: "openness_to_reform",
                          numeric_value: parseInt(updatedValue, 10),
                          ...(workplan && { workplan }),
                        },
                      ];
                    }

                    setData(updatedData);
                  }
                }}
                style={{ height: "25px" }}
              >
                {[1, 2, 3, 4, 5].map((num) => (
                  <ToggleButton key={num} value={num.toString()}>
                    {context.t(num.toString())}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12}>
              <h5>
                {context.t(
                  "IMPACT INDICATOR 2. % of worker-reported issues in the supply chain resolved in under 60 days"
                )}
              </h5>
            </Grid>
            <Grid item xs={12}>
              <TextareaAutosize
                key="impact_indicator_2_issue_resolution_60days_percent_note"
                name="impact_indicator_2_issue_resolution_60days_percent_note"
                value={
                  data?.find(
                    (item) =>
                      item.str_value ===
                      "impact_indicator_2_issue_resolution_60days_percent_note"
                  )?.notes || ""
                }
                aria-label={context.t("add note")}
                placeholder={context.t("Add note...")}
                minRows={3}
                style={{ width: "100%", padding: "10px" }}
                onChange={(e) => {
                  const value = e.target.value;
                  const workplan = workPlanData?.id;

                  const updatedData = (() => {
                    const index = data.findIndex(
                      (item) =>
                        item.str_value ===
                        "impact_indicator_2_issue_resolution_60days_percent_note"
                    );

                    if (index !== -1) {
                      return data.map((item, i) =>
                        i === index
                          ? {
                              ...item,
                              notes: value,
                              ...(workplan && { workplan }),
                            }
                          : item
                      );
                    } else {
                      return [
                        ...data,
                        {
                          area: "working_conditions",
                          str_value:
                            "impact_indicator_2_issue_resolution_60days_percent_note",
                          notes: value,
                          ...(workplan && { workplan }),
                        },
                      ];
                    }
                  })();

                  setData(updatedData);
                }}
              />
            </Grid>

            {/* row */}
            <Grid item xs={12}>
              <h5>
                {context.t(
                  "IMPACT INDICATOR 3. % of remediation efforts of specific issues resolved at a level of 'fair' or better (Fair, Good, or Excellent)"
                )}
              </h5>
            </Grid>
            <Grid item xs={12}>
              <TextareaAutosize
                key="impact_indicator_3_remediation_efforts_resolution_percent_note"
                name="impact_indicator_3_remediation_efforts_resolution_percent_note"
                value={
                  data?.find(
                    (item) =>
                      item.str_value ===
                      "impact_indicator_3_remediation_efforts_resolution_percent_note"
                  )?.notes || ""
                }
                aria-label={context.t("add note")}
                placeholder={context.t("Add note...")}
                minRows={3}
                style={{ width: "100%", padding: "10px" }}
                onChange={(e) => {
                  const value = e.target.value;
                  const workplan = workPlanData?.id;

                  const updatedData = (() => {
                    const index = data.findIndex(
                      (item) =>
                        item.str_value ===
                        "impact_indicator_3_remediation_efforts_resolution_percent_note"
                    );

                    if (index !== -1) {
                      return data.map((item, i) =>
                        i === index
                          ? {
                              ...item,
                              notes: value,
                              ...(workplan && { workplan }),
                            }
                          : item
                      );
                    } else {
                      return [
                        ...data,
                        {
                          area: "working_conditions",
                          str_value:
                            "impact_indicator_3_remediation_efforts_resolution_percent_note",
                          notes: value,
                          ...(workplan && { workplan }),
                        },
                      ];
                    }
                  })();

                  setData(updatedData);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <h5>
                {context.t(
                  "IMPACT INDICATOR 4. % of eligible workers reimbursed for recruitment fees they have paid"
                )}
              </h5>
            </Grid>
            <Grid item xs={12}>
              <TextareaAutosize
                key="impact_indicator_4_eligible_workers_reimbursement_percent_note"
                name="impact_indicator_4_eligible_workers_reimbursement_percent_note"
                value={
                  data?.find(
                    (item) =>
                      item.str_value ===
                      "impact_indicator_4_eligible_workers_reimbursement_percent_note"
                  )?.notes || ""
                }
                aria-label={context.t("add note")}
                placeholder={context.t("Add note...")}
                minRows={3}
                style={{ width: "100%", padding: "10px" }}
                onChange={(e) => {
                  const value = e.target.value;
                  const workplan = workPlanData?.id;

                  const updatedData = (() => {
                    const index = data.findIndex(
                      (item) =>
                        item.str_value ===
                        "impact_indicator_4_eligible_workers_reimbursement_percent_note"
                    );

                    if (index !== -1) {
                      return data.map((item, i) =>
                        i === index
                          ? {
                              ...item,
                              notes: value,
                              ...(workplan && { workplan }),
                            }
                          : item
                      );
                    } else {
                      return [
                        ...data,
                        {
                          area: "working_conditions",
                          str_value:
                            "impact_indicator_4_eligible_workers_reimbursement_percent_note",
                          notes: value,
                          ...(workplan && { workplan }),
                        },
                      ];
                    }
                  })();

                  setData(updatedData);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <h5>
                {context.t(
                  "IMPACT INDICATOR 5. % Amount of money owed versus amount of money reimbursed to workers for recruitment fees that they have paid"
                )}
              </h5>
            </Grid>
            <Grid item xs={12}>
              <TextareaAutosize
                key="impact_indicator_5_reimbursement_percent_note"
                name="impact_indicator_5_reimbursement_percent_note"
                value={
                  data?.find(
                    (item) =>
                      item.str_value ===
                      "impact_indicator_5_reimbursement_percent_note"
                  )?.notes || ""
                }
                aria-label={context.t("add note")}
                placeholder={context.t("Add note...")}
                minRows={3}
                style={{ width: "100%", padding: "10px" }}
                onChange={(e) => {
                  const value = e.target.value;
                  const workplan = workPlanData?.id;

                  const updatedData = (() => {
                    const index = data.findIndex(
                      (item) =>
                        item.str_value ===
                        "impact_indicator_5_reimbursement_percent_note"
                    );

                    if (index !== -1) {
                      return data.map((item, i) =>
                        i === index
                          ? {
                              ...item,
                              notes: value,
                              ...(workplan && { workplan }),
                            }
                          : item
                      );
                    } else {
                      return [
                        ...data,
                        {
                          area: "working_conditions",
                          str_value:
                            "impact_indicator_5_reimbursement_percent_note",
                          notes: value,
                          ...(workplan && { workplan }),
                        },
                      ];
                    }
                  })();

                  setData(updatedData);
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <h5>
                {context.t(
                  "NOTES & REMARKS ON THE PROGRESS INDICATORS FOR THE ANNUAL WORKPLAN"
                )}
              </h5>
              <p>
                {context.t(
                  "Please feel free to add any note in this space, either for yourself or to note for the Issara team."
                )}
              </p>
              <TextareaAutosize
                key="free-note"
                aria-label="minimum height"
                className={classes.textArea}
                minRows={10}
                value={noteAndRemarkData && noteAndRemarkData.txt_value}
                onChange={(e) => {
                  let workplan = workPlanData?.id;
                  setNoteAndRemarkData({
                    ...noteAndRemarkData,
                    txt_value: e.target.value,
                    ...(workplan && { workplan: workplan }),
                  });
                }}
              />
            </Grid>

            <br />
            <br />
            <Grid item xs={6} sm={6} md={8} lg={8}>
              <Button
                onClick={props.back}
                variant="contained"
                style={{ marginLeft: "5px" }}
              >
                {context.t("Back")}
              </Button>
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4} style={BottomBtn}>
              {workPlanData && workPlanData.id && (
                <Button
                  onClick={handleSave}
                  variant="contained"
                  width="200px"
                  style={{ backgroundColor: "#03A9F4", marginRight: "15px" }}
                >
                  {context.t("Save")}
                </Button>
              )}
              <Button onClick={handleNext} variant="contained" width="200px">
                {context.t("Next")}
              </Button>
            </Grid>
          </Grid>
        </div>
      )}
    </Container>
  );
}

SPProgressIndicatorsForm.contextTypes = {
  t: PropTypes.func.isRequired,
};
