import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  Hidden,
  makeStyles,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  tealText: {
    color: "teal",
  },
  boldText: {
    fontWeight: "bold",
  },
  dialogBorderBottom: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  dialogBorderSides: {
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  dialogPadding: {
    padding: theme.spacing(2),
  },
  dialogBottom: {
    marginBottom: "-1px",
  },
  emptyMessage: {
    textAlign: "center",
    color: theme.palette.text.secondary,
    fontStyle: "italic",
  },
}));

const HorizontalBarModal = ({ open, onClose, title, suppliers }) => {
  const classes = useStyles();

  const dataItems = [
    {
      heading: "Not Yet Initiated",
      values: suppliers?.not_yet_initiated || [],
    },
    {
      heading: "Partial Progress",
      values: suppliers?.partial_progress || [],
    },
    {
      heading: "Completed",
      values: suppliers?.completed || [],
    },
  ];

  return (
    <Dialog open={open} maxWidth="lg" fullWidth onClose={onClose}>
      <DialogTitle className={classes.tealText}>{title}</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          {dataItems.every((item) => item.values.length === 0) ? (
            <Grid item xs={12}>
              <Typography variant="body1" className={classes.emptyMessage}>
                No suppliers data
              </Typography>
            </Grid>
          ) : (
            <>
              <Hidden xsDown>
                <Grid container item xs={12} spacing={2}>
                  {dataItems.map((item, index) => (
                    <Grid
                      key={item.heading}
                      item
                      xs={4}
                      className={`${classes.boldText} ${
                        classes.dialogBorderBottom
                      } ${index === 1 ? classes.dialogBorderSides : ""}`}
                    >
                      {item.heading}
                    </Grid>
                  ))}
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  spacing={2}
                  className={`${classes.dialogBorderBottom} ${
                    classes.dialogBottom
                  }`}
                >
                  {dataItems.map((item, index) => (
                    <Grid
                      key={`${item.heading}-values`}
                      container
                      item
                      xs={4}
                      direction="column"
                      className={`${classes.dialogPadding} ${
                        index === 1 ? classes.dialogBorderSides : ""
                      }`}
                    >
                      {item.values.map((name, idx) => (
                        <Grid key={`${name}-${idx}`} item>
                          {name}
                        </Grid>
                      ))}
                    </Grid>
                  ))}
                </Grid>
              </Hidden>

              <Hidden smUp>
                <Grid container item xs={12} direction="column">
                  {dataItems.map((item) => (
                    <React.Fragment key={item.heading}>
                      <Grid
                        item
                        className={`${classes.boldText} ${classes.dialogBorderBottom} ${classes.dialogPadding}`}
                      >
                        {item.heading}
                      </Grid>
                      <Grid
                        item
                        className={`${classes.dialogPadding} ${classes.dialogBorderBottom}`}
                      >
                        {item.values.map((name, idx) => (
                          <Grid key={`${name}-${idx}`} item>
                            {name}
                          </Grid>
                        ))}
                      </Grid>
                    </React.Fragment>
                  ))}
                </Grid>
              </Hidden>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="text" className={classes.tealText} onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

HorizontalBarModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  suppliers: PropTypes.shape({
    not_yet_initiated: PropTypes.arrayOf(PropTypes.string),
    partial_progress: PropTypes.arrayOf(PropTypes.string),
    completed: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default HorizontalBarModal;
