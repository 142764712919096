import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { useDispatch, useSelector } from "react-redux";

import { fetchSubIndustries } from "../../redux/actions/LocaleActions.js";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import utils from "../../services/utils.js";

const customStyles = {
  ...styles,
};

const useStyles = makeStyles(customStyles);

let fetch = false;

export default function SubIndustriesDropdown(props) {
  let filteredIndustries = new Array();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [multiple, setMultiple] = useState(false);
  const [industryId, setIndustryId] = useState(null);

  const industries = useSelector((state) => state.subIndustriesReducer.items);

  useEffect(() => {
    fetchSubIndustries();
    if (fetch === false) {
      fetch = true;
      dispatch(fetchSubIndustries());
    }
  }, []);

  useEffect(() => {
    if (props.industryId) {
      setIndustryId(props.industryId);
    }
  }, [props.industryId]);

  useEffect(() => {
    setMultiple(props.multiple);
  }, [props.multiple]);

  const handleChangeIndustryId = (e) => {
    props.onSelect({
      ...(e.target.value === "" ? { target: { value: null } } : e),
      list: industries,
    });
  };

  if (industries) {
    industries.forEach((industry) => {
      if (
        industryId === undefined ||
        industryId === null ||
        industry.industry === industryId
      ) {
        filteredIndustries.push(industry);
      } else if (
        Array.isArray(industryId) &&
        industryId.length > 0 &&
        industryId.includes(industry.industry) &&
        multiple == true
      ) {
        filteredIndustries.push(industry);
      }
    });
  }

  return (
    <FormControl fullWidth={true}>
      <InputLabel htmlFor="country-select" className={classes.selectLabel}>
        Sub-industry
      </InputLabel>
      <Select
        key={utils.giveMeGuid()}
        multiple={multiple}
        value={props.value ? props.value : ""}
        onChange={handleChangeIndustryId}
        inputProps={{
          name: "industrySelect",
          id: "industry-select",
        }}
      >
        {filteredIndustries &&
          filteredIndustries.map((item, i) => {
            return (
              <MenuItem value={item.id} key={i}>
                {item.name}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
}

// Default value for props:
SubIndustriesDropdown.defaultProps = {
  onSelect: (selectedIndustry) =>
    console.log("Selected Industry: ", selectedIndustry),
};
