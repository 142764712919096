import React from "react";
import Grid from "@material-ui/core/Grid";
import SupplierPerformanceBox from "./SupplierPerformanceBox";

const SupplierPerformanceGrid = ({
  performance,
  openTooltips,
  handleTooltipOpen,
  context,
}) => (
  <Grid container item xs={12} spacing={2}>
    <SupplierPerformanceBox
      score={performance?._quality_score}
      title="Quality"
      tooltipText="This is evaluated through worker satisfaction with the responses for recruitment and management, technical assessments of HR, production, and senior management."
      tooltipKey="quality"
      openTooltips={openTooltips}
      handleTooltipOpen={handleTooltipOpen}
      context={context}
    />
    <SupplierPerformanceBox
      score={performance?._timeliness_score}
      title="Timeliness"
      tooltipText="This is measured by the duration of time taken to respond, revert on action plan, and resolve worker grievances."
      tooltipKey="timeliness"
      openTooltips={openTooltips}
      handleTooltipOpen={handleTooltipOpen}
      context={context}
    />
    <SupplierPerformanceBox
      score={performance?._openness_score}
      title="Openness to reform"
      tooltipText="This is assessed by the level of cooperation on worker voice channels and surveys, the business's attitude toward discussing risks and remediation, developing capacity and systems for identified risks, promoting worker voice in the industry and supply chain, and the treatment of workers."
      tooltipKey="openness"
      openTooltips={openTooltips}
      handleTooltipOpen={handleTooltipOpen}
      context={context}
    />
  </Grid>
);

export default SupplierPerformanceGrid;
