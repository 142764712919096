import React, { useState, useEffect } from "react";
import HttpService from "../../../services/HttpService";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import { Switch } from "@material-ui/core";
import swal from "@sweetalert/with-react";
import SPProgressDashboard from "./SPProgressDashboard";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: 180,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

let theme = createTheme();
theme = responsiveFontSizes(theme);

let styles = {
  upperbox: {
    backgroundColor: "#fff",
    padding: "10px 30px",
    borderRadius: "0 0 6px 6px",
  },
  activity_list: {
    display: "flex",
    justifyContent: "space-around",
    margin: "5px 0",
  },
  BottomBtn: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "20px",
    borderRadius: "0px",
  },
  Btn: {
    backgroundColor: "#5dc719",
    color: "#fff",
    cursor: "pointer",
  },
  selectHeight: {
    padding: "2px 14px",
  },
  topHeading: {
    borderRadius: "6px 6px 0 0",
    backgroundColor: "#999999",
    color: "#fff",
    fontWeight: "500",
    padding: "18px",
  },
};

const SPProgressMetrics = (props) => {
  const classes = useStyles();
  const [allPartners, setAllPartners] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState(0);
  const [partnerHasWP, setPartnerHasWP] = useState(false);

  var wp_id = [];
  const defaultFilterOptions = createFilterOptions();
  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state).slice(0, 20);
  };

  useEffect(() => {
    HttpService.get(
      `${process.env.REACT_APP_API_URL}/strategicpartners/`,
      (response) => {
        setAllPartners(response.results);
      },
      (err) => {
        console.log(err.message);
      }
    );
  }, []);

  const handleSelectPartner = (event, value) => {
    if (value !== null) {
      setSelectedPartner(value.id);
    }
  };

  useEffect(() => {
    HttpService.get(
      `${process.env.REACT_APP_API_URL}/sp_work_plan/?sp=${selectedPartner}`,
      (response) => {
        if (response.Result == "Workplan not found") {
          setPartnerHasWP(false);
        } else {
          setPartnerHasWP(true);
        }
      },
      (err) => {
        console.log(err.message);
      }
    );
  }, [selectedPartner]);

  return (
    <>
      <Container>
        <Grid
          container
          style={{ ...styles.upperbox, padding: "20px", borderRadius: "6px" }}
        >
          <Autocomplete
            filterOptions={filterOptions}
            id="combo-box-demo"
            options={allPartners ? allPartners : null}
            getOptionLabel={(option) => option.name}
            onChange={handleSelectPartner}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Strategic Partner"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <br />
        {selectedPartner ? (
          partnerHasWP ? (
            <SPProgressDashboard partner={selectedPartner} />
          ) : (
            <h3>Workplan not found!</h3>
          )
        ) : null}
      </Container>
    </>
  );
};

export default SPProgressMetrics;
