import React, { useState, useEffect } from "react";
import IndustriesDropdown from "./IndustriesDropdown.js";
import SubIndustriesDropdown from "./SubIndustriesDropdown.js";
import GridItem from "components/Grid/GridItem.js";
import Grid from "@material-ui/core/Grid";

export default function IndustriesSubIndustriesDropdown(props) {
  const propsOnSelect = props.onSelect ? props.onSelect : (e) => {};
  const [multiple, setMultiple] = useState(false);
  const valueEmpty = props.multiple ? [] : null;

  const [industryId, setIndustryId] = useState(
    props.industry_values ? props.industry_values : valueEmpty
  );

  const [subIndustryId, setSubIndustryId] = useState(
    props.subindustry_values ? props.subindustry_values : valueEmpty
  );

  useEffect(() => {
    if (props) {
      setMultiple(props.multiple ? props.multiple : false);
      setIndustryId(props.industry_values ? props.industry_values : valueEmpty);
      setSubIndustryId(
        props.subindustry_values ? props.subindustry_values : valueEmpty
      );
    }
  }, [props]);

  return (
    <Grid container>
      <GridItem xs={6} md={6}>
        <IndustriesDropdown
          multiple={multiple}
          value={industryId}
          onSelect={(e) => {
            setIndustryId(e.target.value);
            setSubIndustryId(valueEmpty);

            propsOnSelect({
              ...e,
              industry_id:
                e.target.value !== valueEmpty ? e.target.value : null,
              subindustry_id: multiple ? props.subindustry_values : null,
            });
          }}
        />
      </GridItem>
      <GridItem xs={6} md={6}>
        <SubIndustriesDropdown
          multiple={multiple}
          industryId={industryId}
          value={subIndustryId}
          onSelect={(e) => {
            setSubIndustryId(e.target.value);
            propsOnSelect({
              ...e,
              industry_id: industryId !== valueEmpty ? industryId : null,
              subindustry_id:
                e.target.value !== valueEmpty ? e.target.value : null,
            });
          }}
        />
      </GridItem>
    </Grid>
  );
}
